import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import PudosModalContainer from '../PudosModal/PudosModalContainer';
import PickupSearch from '../PickupSearch/PickupSearch';

/**
 * The PudosSection (Pick-Up and Drop-Off) component manages the pickup delivery search & modal.
 * It facilitates searching pick-up/drop-off locations through text input or geolocation.
 *
 * @component
 * @returns {React.ReactElement} The rendered component with search interface and modal
 */
function PudosSection({ tmntData }) {
  const [isInputSearching, setIsInputSearching] = useState(false);
  const [isNearMeSearching, setIsNearMeSearching] = useState(false);
  const [isGeolocationBlocked] = useState(false);

  const handleInputSearch = useCallback((value) => {
    setIsInputSearching(true);
    window.dispatchEvent(new CustomEvent('pudos-modal:open', {
      detail: { value },
    }));
    setIsInputSearching(false);
  }, []);

  const handleNearMeSearch = useCallback(() => {
    setIsNearMeSearching(true);
    window.dispatchEvent(new CustomEvent('pudos-modal:open', {
      detail: { nearme: true },
    }));
    setIsNearMeSearching(false);
  }, []);

  return (
    <div data-testid="pudos-modal">
      <PickupSearch
        defaultSearchValue={undefined}
        isInputSearching={isInputSearching}
        isNearMeSearching={isNearMeSearching}
        isGeolocationBlocked={isGeolocationBlocked}
        onInputSearch={handleInputSearch}
        onNearMeSearch={handleNearMeSearch}
        text={tmntData}
      />
      <PudosModalContainer />
    </div>
  );
}

PudosSection.defaultProps = {
  tmntData: {},
};

PudosSection.propTypes = {
  tmntData: PropTypes.instanceOf(Object),
};
export default PudosSection;
