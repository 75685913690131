import { gql } from '@apollo/client';

const NOTIFICATION_DATA_QUERY = gql`
query FetchNotificationsMiniBag {
  userData {
    statusCode
    success
    statusMessages {
      code
      key
      message
    }
    userNotifications {
      key
      value
    }
}
}
`;

export default NOTIFICATION_DATA_QUERY;
