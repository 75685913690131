import fedexSvgData from '../components/Common/fedex-svg-data';

export function formatHours(time) {
  // Check correct time format and split into components
  let tempTime = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (tempTime.length > 1) { // If time format correct
    tempTime = tempTime.slice(1); // Remove full string match value
    tempTime = tempTime.slice(0, 3); // Remove seconds from time
    tempTime[5] = +tempTime[0] < 12 ? 'am' : 'pm'; // Set am/pm
    tempTime[0] = +tempTime[0] % 12 || 12; // Adjust hours
  }
  return tempTime.join(''); // return adjusted time or original string
}

export function formatDayOfWeek(dayString) {
  return dayString.charAt(0) + dayString.substring(1).toLowerCase();
}

export function getPudosLocationImage(location) {
  const providerType = location?.providerType?.toUpperCase();
  let iconType;

  const fedexProviderTypes = ['FEDEX_ONSITE', 'FEDEX_OFFICE'];
  if (fedexProviderTypes.includes(providerType)) {
    return fedexSvgData;
  }

  switch (providerType) {
    case 'UPS':
      iconType = 'UPS';
      break;
    case 'ROYALMAIL':
      iconType = 'ROYALMAIL';
      break;
    case 'SOCOP':
      iconType = 'SOCO';
      break;
    case 'POSTNORD':
      iconType = 'POSTNORD';
      break;
    case 'SOCOO':
      iconType = 'SOCO';
      break;
    default:
      iconType = undefined;
      break;
  }
  if (!iconType) {
    return '';
  }

  return `https://img.abercrombie.com/is/image/anf/${iconType}-pudos-icon.png?wid=36&hei=36`;
}
