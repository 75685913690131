import React, {
  useContext, useState, useMemo,
} from 'react';
import {
  useMutation,
} from '@apollo/client';
import {
  Tabs, TabPanel, ErrorMessage, Icon,
} from 'anf-core-react';
import SwitchTestContext from '../../../context/SwitchTestContext';
import BagContext from '../../../context/BagContext';
import PageHeader from '../PageHeader/PageHeader';
import BagList from '../../Common/BagList/BagList';
import Repudiation from '../../Common/Repudiation/Repudiation';
import SaveForLater from '../SaveForLater/SaveForLater';
import Tmnt from '../../Tmnt/Tmnt';
import useLog from '../../useLog/useLog';
import trackAction from '../../../tools/analytics';
import { REMOVE_BAG_ITEM_MUTATION } from '../../../gql/bagItem.gql';
import { MOVE_TO_SAVEFORLATER_MUTATION, MOVE_TO_BAG_MUTATION, DELETE_FROM_SFL_MUTATION } from '../../../gql/sfl.gql';
import SoldOutRepudiation from '../../Common/Repudiation/SoldOutRepudiation/SoldOutRepudiation';
import $window from '../../../tools/window';
import UserSectionDetails from '../../Common/UserSectionDetails/UserSectionDetails';
import ShoppingBagRecommendations from '../../Recommendations/ShoppingBagRecommendations';
import { useIsS } from '../../../hooks/useBreakPoint';
import { getCartPlacements, getEmptyCartPlacements } from '../../Recommendations/helpers';
import MarketingSpot from '../../Common/MarketingSpot/MarketingSpot';

export default function LeftRail() {
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const {
    bag,
    setBag,
    savedForLater,
    setSavedForLater,
    setShowDiscount,
    showNotification,
    setShowNotification,
  } = useContext(BagContext);
  const [selected, setSelected] = useState('bag');
  const [currentRef, setCurrentRef] = useState('');
  const [isDisabledPointerEvents, setIsDisabledPointerEvents] = useState(false);
  const isSaveForLaterEnabled = digitalData && digitalData['chk-save-for-later'];
  const hasBagMfeV2 = digitalData && digitalData['chk-has-bag-mfe-v2'];
  const INTERVAL_2000 = 2000;
  const bagItemCount = bag?.bagItems?.items && Object.keys(bag?.bagItems?.items).length !== 0 ? bag?.bagItems?.items?.length : '0';
  const itemString = bagItemCount === 1 ? bag?.textFor?.itemTMNTValue?.value
    : bag?.textFor?.itemsTMNTValue?.value;
  const inBagTMNT = bag?.textFor?.inBagTMNTValue?.value || 'In Bag';
  const saveForLaterTMNT = bag?.textFor?.saveForLaterTMNTValue?.value || 'Save For Later';
  const bagHeadline = `${inBagTMNT} (${bagItemCount})`;
  const sflList = savedForLater?.savedForLaterItems?.bagItems?.items;
  const hasSFLLength = sflList && Object.keys(sflList).length !== 0;
  const hasRepudiation = useMemo(
    () => bag?.repudiationData?.repudiationProducts?.items?.length > 0,
    [bag?.repudiationData?.repudiationProducts?.items],
  );
  const hasInventoryRepudiation = (bag?.repudiationData?.repudiationType === 'SOLDOUT_ITEM' || bag?.repudiationData?.repudiationType === 'SFS_SOLDOUT_ITEM')
   && bag?.repudiationData?.errorMessage !== '';

  const sflItemCount = hasSFLLength ? Object.keys(sflList).length : '0';
  const sflHeadline = `${saveForLaterTMNT} (${sflItemCount})`;
  const logger = useLog('shoppingBag.root');

  const updateBagCount = (bagItems) => {
    const updateItemCount = new CustomEvent(
      'miniBag:updateItemCount:done',
      {
        detail: bagItems,
        cancelable: false,
      },
    );
    window.dispatchEvent(updateItemCount);
  };
  const hasItemsInBag = bag?.bagItems?.items && Object.keys(bag?.bagItems?.items).length !== 0;

  const setterFunc = (stateSetter, updatedState) => (previousState) => ({
    ...previousState,
    [stateSetter]: {
      ...previousState[stateSetter],
      bagItems: {
        ...previousState[stateSetter].bagItems,
        items: updatedState,
      },
    },
  });

  const updateItemState = async (index, message, property, itemState, stateSetter, setItem) => {
    try {
      let updatedState = {
        ...itemState?.bagItems?.items,
      };
      updatedState = Object.values(updatedState);
      updatedState[index].item[property] = message;
      setItem(setterFunc(stateSetter, updatedState));
    } catch (err) {
      logger.debug('LeftRail', 'updateItemState', 'stateSetter', err);
    }
  };

  const updateBagError = async (index, message) => {
    updateItemState(index, message, 'displayError', bag, 'bagItems', setBag);
    setTimeout(
      () => updateItemState(index, null, 'displayError', bag, 'bagItems', setBag),
      INTERVAL_2000,
    );
  };

  const updateSflError = async (index, message) => {
    updateItemState(index, message, 'displayError', savedForLater?.savedForLaterItems, 'savedForLaterItems', setSavedForLater);
    setTimeout(
      () => updateItemState(index, null, 'displayError', savedForLater?.savedForLaterItems, 'savedForLaterItems', setSavedForLater),
      INTERVAL_2000,
    );
  };

  const setBagProcessing = (index, state, property = 'isProcessing') => {
    updateItemState(index, state, property, bag, 'bagItems', setBag);
  };

  const setSFLProcessing = (index, state) => {
    updateItemState(index, state, 'isProcessing', savedForLater?.savedForLaterItems, 'savedForLaterItems', setSavedForLater);
  };

  const [removeShoppingBagItemMutation] = useMutation(REMOVE_BAG_ITEM_MUTATION, {
    onCompleted: (removeShoppingBagItem) => {
      logger.debug('removeShoppingBagItemMutation RESULT', removeShoppingBagItem);
      if (removeShoppingBagItem?.removeBagItem?.success) {
        updateBagCount(removeShoppingBagItem?.removeBagItem?.bagItems);
        if (Object.keys(removeShoppingBagItem?.removeBagItem?.bagItems?.items)?.length === 0) {
          window.location.reload();
        } else {
          setBag((previousState) => {
            const newState = ({
              ...previousState,
              orderTotals: removeShoppingBagItem?.removeBagItem?.orderTotals,
              bagItems: removeShoppingBagItem?.removeBagItem?.bagItems,
              freeShippingProgressInfo: removeShoppingBagItem
                ?.removeBagItem?.freeShippingProgressInfo,
              promoInfo: removeShoppingBagItem?.removeBagItem?.promoInfo,
              repudiationData: removeShoppingBagItem?.removeBagItem?.repudiationData,
              userData: removeShoppingBagItem?.removeBagItem?.userData,
            });

            // merge the bag response to digitalData `cart`
            $window.digitalData?.merge('cart', {
              origin: 'bag',
              data: newState,
              action: 'remove_from_bag',
            });

            return newState;
          });
        }
      }
    },
  });
  const addToSaveForLater = (toBeMovedItem, bagId) => {
    /* setting new SFL */
    let updatedSFL = {
      ...sflList,
    };
    updatedSFL = Object.values(updatedSFL);
    updatedSFL.unshift(toBeMovedItem); // Adding the item at the top of SFL list

    setSavedForLater((previousState) => ({
      ...previousState,
      savedForLaterItems: {
        bagId,
        bagItems: {
          items: updatedSFL,
        },
      },
    }));
  };
  const removeFromSavedForLater = (savedForLaterItems, index, bagId) => {
    // deleting the toBeMovedItem from SavedForLater
    savedForLaterItems.splice(index, 1);
    /* setting new SaveForLater */
    let updatedSFL = {
      ...sflList,
      ...savedForLaterItems,
    };
    updatedSFL = Object.values(updatedSFL);
    setSavedForLater((previousState) => ({
      ...previousState,
      savedForLaterItems: {
        bagId,
        bagItems: {
          items: updatedSFL,
        },
      },
    }));
  };
  const addToBag = (bagItems, orderTotals, freeShippingProgressInfo) => {
    // setting the bag directly with bagItems coming from resolver
    setBag((previousState) => {
      const newState = ({
        ...previousState,
        bagItems,
        orderTotals,
        freeShippingProgressInfo,
      });

      // merge the bag response to digitalData `cart`
      $window.digitalData?.merge('cart', {
        origin: 'bag',
        data: newState,
        action: 'add_to_bag',
      });
      return newState;
    });
  };
  const removeFromBag = (bagItems, index, orderTotals, freeShippingProgressInfo) => {
    // deleting the toBeMovedItem from bag
    bagItems.splice(index, 1);
    /* setting new BAG */
    let updatedBag = {
      ...bag?.bagItems?.items,
      ...bagItems,
    };
    updatedBag = Object.values(updatedBag);

    setBag((previousState) => {
      const newState = ({
        ...previousState,
        bagItems: {
          items: updatedBag,
        },
        orderTotals,
        freeShippingProgressInfo,
      });

      // merge the bag response to digitalData `cart`
      $window.digitalData?.merge('cart', {
        origin: 'bag',
        data: newState,
        action: 'remove_from_bag',
      });

      return newState;
    });
  };

  const removeShoppingBagItem = async (data) => {
    setBagProcessing(data?.dataset?.index, true, 'isRemoving');
    try {
      const result = await removeShoppingBagItemMutation({
        variables: {
          orderItemId: data?.dataset?.orderitemid,
        },
      });

      if (result?.data?.removeBagItem?.success) {
        const updatedOrderTotals = result?.data?.removeBagItem?.orderTotals;
        const itemToRemove = {
          name: 'cart item removed',
          collectionId: data?.dataset?.collection,
          longSku: data?.dataset?.longsku,
          shortSku: data?.dataset?.sku,
          inventoryAvailability: data?.dataset?.inventoryavailability,
          kic: data?.dataset?.kicid,
          removeFromCart: {
            event_name: 'remove_from_cart',
            event_type: 'order',
            page_description: 'bag',
            page_view_method: 'page load',
            collection_id: data?.dataset?.collection,
            long_sku: data?.dataset?.longsku,
            brand: data?.dataset?.brand,
            product_name: data?.dataset?.name,
            key_item_color: data?.dataset?.kicid,
            price_flag: data?.dataset?.priceflag,
            price_list_local: data?.dataset?.listprice,
            price_list_usd: data?.dataset?.listpriceusd,
            price_offer_local: data?.dataset?.offerprice,
            price_offer_usd: data?.dataset?.offerpriceusd,
          },
          ...updatedOrderTotals,
          cartBrands: data?.dataset?.brand,
          kicIds: data?.dataset?.kicid,
        };
        // trigger analytics event (tealium & adobe)
        trackAction('checkout.bag.cartItemRemoved', itemToRemove);

        logger.debug(data?.dataset?.orderitemid);
      } else {
        updateBagError(
          data?.dataset?.index,
          result?.data?.removeBagItem?.statusMessages[0]?.message,
        );
      }
    } catch (err) {
      logger.error(`ERR: REMOVE_BAG_ITEM_MUTATION: ${JSON.stringify(err)}`);
    }
    setBagProcessing(data?.dataset?.index, false, 'isRemoving');
  };
  /* Main function for the whole Move to Saved For Later feature */

  const [moveToSaveForLaterMutation] = useMutation(MOVE_TO_SAVEFORLATER_MUTATION, {
    onCompleted: (updateMoveToSFL) => {
      logger.debug('THE MOVE_TO_SAVEFORLATER_MUTATION RESULT', updateMoveToSFL.moveToSavedForLater);
      setTimeout(() => {
        setIsDisabledPointerEvents(false);
      }, 500);
      setBagProcessing(currentRef, false);
      if (updateMoveToSFL?.moveToSavedForLater?.success) {
        // handle success
        const bagList = { ...bag?.bagItems };
        const bagItems = bagList?.items;
        // finding the current item that needs to be moved from bag to sfl
        bagItems[currentRef]
          .item.productContent.listItemId = updateMoveToSFL?.moveToSavedForLater?.listItemId;
        const toBeMovedItem = bagItems[currentRef];
        const currentProductContent = toBeMovedItem?.item?.productContent;
        const addToSaveLater = {
          event_name: 'save_for_later_add',
          event_type: 'product',
          page_description: 'shopping bag',
          collection_id: currentProductContent?.collectionId,
          long_sku: currentProductContent?.longSku,
          short_sku: currentProductContent?.shortSku,
          // ?
          inventory: toBeMovedItem?.item?.headers?.badgeStatusMessage.value,
          product_id: currentProductContent?.productId,
          brand: currentProductContent?.brand,
          product_name: currentProductContent?.name,
          key_item_color: currentProductContent?.kicId,
          price_flag: currentProductContent?.productPrice?.priceFlag,
          price_list_local: currentProductContent?.productPrice?.original,
          price_list_usd: currentProductContent?.productPrice?.originalUSD,
          price_offer_local: currentProductContent?.productPrice?.discount,
          price_offer_usd: currentProductContent?.productPrice?.discountUSD,
        };
        // addded trackAction for tealium
        trackAction('save_for_later_add', addToSaveLater);
        // Adding  to Save For Later Operation
        addToSaveForLater(
          toBeMovedItem,
          updateMoveToSFL?.moveToSavedForLater?.bagId,
        );
        // Remove the current item from Bag Operation
        removeFromBag(
          bagItems,
          currentRef,
          updateMoveToSFL?.moveToSavedForLater?.orderTotals,
          updateMoveToSFL?.moveToSavedForLater?.freeShippingProgressInfo,
        );
        // Updating the bag count in Minibag
        updateBagCount(bag?.bagItems);
        setCurrentRef('');
        updateBagError(currentRef, null);
        trackAction('bag_save_for_later_click', {
          data_text: 'save for later',
          data_action: 'move to save for later successful',
          event_type: 'click',
        });
      } else {
        // handle Error
        updateBagError(currentRef, updateMoveToSFL?.moveToSavedForLater?.errorMessage?.value);
        trackAction('bag_save_for_later_click', {
          data_text: 'save for later',
          data_action: 'move to save for later failed',
          event_type: 'click',
        });
      }
    },
    onError: (err) => {
      logger.error(`ERR: MOVE_TO_SAVEFORLATER_MUTATION: ${JSON.stringify(err)}`);
      updateBagError(currentRef, err?.moveToSavedForLater?.errorMessage?.value);
      setTimeout(() => {
        setIsDisabledPointerEvents(false);
      }, 500);
      setBagProcessing(currentRef, false);
    },
  });
  const moveToSaveForLater = (orderItemId, sku, currentIndex) => {
    moveToSaveForLaterMutation({
      variables: {
        sku,
        orderItemId,
      },
    });
    setIsDisabledPointerEvents(true);
    setBagProcessing(currentIndex, true);
    setCurrentRef(currentIndex);
  };

  /* Main function for the whole Move to ShoppingBag feature */

  const [moveToBagMutation] = useMutation(MOVE_TO_BAG_MUTATION, {
    onCompleted: (updateMoveToBag) => {
      logger.debug('THE MOVE_TO_BAG_MUTATION RESULT', updateMoveToBag.moveToBag);
      setTimeout(() => {
        setIsDisabledPointerEvents(false);
      }, 500);
      setSFLProcessing(currentRef, false);
      if (updateMoveToBag?.moveToBag?.success) {
        // handle success
        const savedForLaterList = { ...savedForLater?.savedForLaterItems };
        const savedForLaterItems = savedForLaterList?.bagItems?.items;

        const data = { ...savedForLaterList?.bagItems };
        const dataItems = data?.items;
        const currentProductContent = dataItems[currentRef]?.item?.productContent;
        const moveToBag = {
          event_name: 'add_save_for_later',
          event_type: 'product',
          page_description: 'shopping bag',
          collection_id: currentProductContent?.collectionId,
          long_sku: currentProductContent?.longSku,
          short_sku: currentProductContent?.shortSku,
          //
          inventory: dataItems[currentRef]?.item?.headers?.badgeStatusMessage.value,
          product_id: currentProductContent?.productId,
          brand: currentProductContent?.brand,
          product_name: currentProductContent?.name,
          key_item_color: currentProductContent?.kicId,
          price_flag: currentProductContent?.productPrice?.priceFlag,
          price_list_local: currentProductContent?.productPrice?.original,
          price_list_usd: currentProductContent?.productPrice?.originalUSD,
          price_offer_local: currentProductContent?.productPrice?.discount,
          price_offer_usd: currentProductContent?.productPrice?.discountUSD,
        };
        // addded trackAction for tealium
        trackAction('add_save_for_later', moveToBag);

        // Remove the current item from Save For Later Operation
        removeFromSavedForLater(
          savedForLaterItems,
          currentRef,
          savedForLater?.savedForLaterItems?.bagId,
        );
        // Adding to Bag Operation
        addToBag(
          updateMoveToBag?.moveToBag?.bagItems,
          updateMoveToBag?.moveToBag?.orderTotals,
          updateMoveToBag?.moveToBag?.freeShippingProgressInfo,
        );
        // Updating the bag count in Minibag
        updateBagCount(updateMoveToBag?.moveToBag?.bagItems);
        setCurrentRef('');
        updateSflError(currentRef, null);
        trackAction('bag_save_for_later_click', {
          data_text: 'save for later',
          data_action: 'move to bag successful',
          event_type: 'click',
        });
      } else {
        // handle Error
        updateSflError(currentRef, updateMoveToBag?.moveToBag?.errorMessage?.value);
        trackAction('bag_save_for_later_click', {
          data_text: 'save for later',
          data_action: 'move to bag failed',
          event_type: 'click',
        });
      }
    },
    onError: (err) => {
      logger.error(`ERR: MOVE_TO_BAG_MUTATION: ${JSON.stringify(err)}`);
      updateSflError(currentRef, err?.moveToBag?.errorMessage?.value);
      setTimeout(() => {
        setIsDisabledPointerEvents(false);
      }, 500);
      setSFLProcessing(currentRef, false);
    },
  });

  const moveToShoppingBagList = (listItemId, shortSku, currentIndex) => {
    const bagId = savedForLater?.savedForLaterItems?.bagId;
    moveToBagMutation({
      variables: {
        shortSku,
        bagId,
        listItemId,
      },
    });
    setIsDisabledPointerEvents(true);
    setSFLProcessing(currentIndex, true);
    setCurrentRef(currentIndex);
  };

  /* Main function for the whole Remove from SavedForLater feature */

  const [deleteFromSFLMutation] = useMutation(DELETE_FROM_SFL_MUTATION, {
    onCompleted: (updateDeleteFromSFL) => {
      logger.debug('THE DELETE_FROM_SFL_MUTATION RESULT', updateDeleteFromSFL.removeSavedForLaterItem);
      if (updateDeleteFromSFL?.removeSavedForLaterItem?.success) {
        // handle success
        const savedForLaterList = { ...savedForLater?.savedForLaterItems };
        const savedForLaterItems = savedForLaterList?.bagItems?.items;
        // Remove the current item from Save For Later Operation
        removeFromSavedForLater(
          savedForLaterItems,
          currentRef,
          savedForLater?.savedForLaterItems?.bagId,
        );
        setCurrentRef('');
        updateSflError(currentRef, null);
        trackAction(
          'bag_save_for_later_click',
          {
            data_text: 'save for later',
            data_action: 'remove successful',
            event_type: 'click',
          },
        );
      } else {
        // handle Error
        updateSflError(
          currentRef,
          updateDeleteFromSFL?.removeSavedForLaterItem?.errorMessage?.value,
        );
        trackAction('bag_save_for_later_click', {
          data_text: 'save for later',
          data_action: 'remove failed',
          event_type: 'click',
        });
      }
    },
    onError: (err) => {
      logger.error(`ERR: PROMOTION_UPDATE_MUTATION: ${JSON.stringify(err)}`);
      updateSflError(currentRef, err?.removeSavedForLaterItem?.errorMessage?.value);
    },
  });

  const deleteFromSavedFromLater = (listItemId, currentIndex) => {
    const bagId = savedForLater?.savedForLaterItems?.bagId;
    deleteFromSFLMutation({
      variables: {
        bagId,
        listItemId,
      },
    });
    setCurrentRef(currentIndex);
  };

  const isMobileView = useIsS();

  const recommendationsProps = {
    placements: hasItemsInBag
      ? getCartPlacements(isMobileView)
      : getEmptyCartPlacements(isMobileView),
    slidesToShow: 3,
    id: 'shopping-bag-1',
  };

  return (
    <div className="left-rail">
      <div className={isSaveForLaterEnabled ? 'left-container sfl' : 'left-container'}>
        {/* checkout-cart-header start */}

        <PageHeader
          value={(
            <>
              <Tmnt tmnt={bag?.textFor?.shoppingBagTMNTValue} isHtml />
              <span className="bag-item-count" data-testid="bag-item-count">{`(${bagItemCount} ${itemString})`}</span>
            </>
          )}
          tmntKey="GLB_BAG_HEADER"
          classes="shopping-bag-header"
        >
          {bag?.userData?.isLoggedIn ? (
            <button data-aui="member-welcome-sign-out" className="button button-as-link ds-override logoff-trigger" aria-label="Sign Out" type="button" data-property="GLB_SIGNOUT">
              <span>{bag?.textFor?.signOutTMNTValue?.value}</span>
            </button>
          ) : ''}
        </PageHeader>
        {hasInventoryRepudiation && isSaveForLaterEnabled
                    && (
                    <div className="soldout-repudiation-error-block" data-testid="soldoutRepudiationError">
                      <ErrorMessage>
                        <Icon
                          icon="clock"
                          labelText="clock"
                          size="s"
                        />
                        {' '}
                        <Tmnt tmnt={bag?.textFor?.soldoutMovedToSfl} />
                      </ErrorMessage>
                    </div>
                    )}
        {/* checkout-cart-header end */}
        {/* promos-loyalty-wrapper start */}
        <UserSectionDetails
          purchaseContext={bag}
          setPurchaseContext={setBag}
          setShowDiscount={setShowDiscount}
          showNotification={showNotification}
          setShowNotification={setShowNotification}
          hasBagMfeV2={hasBagMfeV2}
          page={bag?.page}

        />

        {/* promos-loyalty-wrapper end  */}

        {/* product-rail start */}
        <div className="product-rail" data-state={isSaveForLaterEnabled}>
          <div className="shopping-bagV2">
            {hasRepudiation && (
              <div className="bag-repudiation">
                <Repudiation
                  productList={bag?.repudiationData?.repudiationProducts?.items}
                  errorMessage={bag?.repudiationData?.errorMessage}
                  itemsToRemove={bag?.repudiationData?.itemsToRemove}
                  tmntData={bag?.textFor}
                  continueInCurrency={bag?.repudiationData?.continueInCurrency}
                  hasCheckoutButton={bag?.repudiationData?.hasCheckoutButton}
                  checkoutUrl={bag?.repudiationData?.checkoutUrl}
                  onRemoveItems={removeShoppingBagItemMutation}
                  showRemoveItemsButton={!hasInventoryRepudiation}
                />
              </div>
            )}
            <SoldOutRepudiation
              tmntData={bag?.textFor}
              checkoutUrl={bag?.checkoutButton?.checkoutUrl}
            />
            {hasItemsInBag && (
              <div data-testid="baglist" className={isDisabledPointerEvents && 'disable-baglist-pointer-events'}>
                <BagList
                  variant="leftRail"
                  bagItems={bag?.bagItems?.items}
                  removeBagItem={removeShoppingBagItem}
                  tmntData={bag?.textFor}
                  showShopSimilarItems
                  isSaveForLaterEnabled={isSaveForLaterEnabled}
                  moveToSFL={moveToSaveForLater}
                />
              </div>
            )}
            {
              !bag?.bagItems?.success
              && bag?.bagItems?.statusCode !== '401'
              && (
                <div className="error-section">
                  {bag?.bagItems?.statusMessages?.map(
                    (err) => err?.message && <ErrorMessage key={err}>{err?.message}</ErrorMessage>,
                  )}
                </div>
              )
            }
          </div>
          {isSaveForLaterEnabled
          && (
          <div data-testid="saveforlater" className={isDisabledPointerEvents && 'disable-baglist-pointer-events'}>
            <SaveForLater
              moveToBag={moveToShoppingBagList}
              deleteFromSFL={deleteFromSavedFromLater}
              isLoggedIn={bag?.userData?.isLoggedIn}
              viewType="stacked"
            />
          </div>
          )}
        </div>
        {/* product-rail end */}
        {hasBagMfeV2
        && (
        <div className="urgency-messaging-espot">
          <MarketingSpot
            espotId="bag_freeshipping_belowsubtotal"
            espotData={bag?.eSpots?.cartFreeShippingPromo}
          />
        </div>
        )}
        {/* Tabbed Product Rail starts */}
        {isSaveForLaterEnabled && (
        <div className="product-rail-tabbed-view" data-state={isSaveForLaterEnabled}>
          <div className="tabbed-view" data-testid="tabs">
            <Tabs
              controls={{
                onKeyDown: function noRefCheck() {},
                tabs: [
                  {
                    ariaControls: 'shopping-tab-panel',
                    id: 'shopping-bag-tab',
                    isSelected: selected === 'bag',
                    label: bagHeadline,
                    onClick: () => {
                      trackAction('universal_click', {
                        data_text: 'in bag',
                        data_action: 'click',
                        event_type: 'click',
                      });
                      setSelected('bag');
                    },
                    ref: {
                      current: '[Circular]',
                    },
                    subLabel: bag?.textFor?.inBagTMNTValue?.key,
                  },
                  {
                    ariaControls: 'saveforlater-tab-panel',
                    id: 'save-for-later-tab',
                    isSelected: selected === 'sfl',
                    label: sflHeadline,
                    onClick: () => {
                      trackAction('universal_click', {
                        data_text: 'saved for later',
                        data_action: 'click',
                        event_type: 'click',
                      });
                      setSelected('sfl');
                    },
                    ref: {
                      current: '[Circular]',
                    },
                    subLabel: bag?.textFor?.saveForLaterTMNTValue?.key,
                  },
                ],
              }}
              labelText="Tab"
            >
              <TabPanel
                id="shopping-tab-panel"
                labelledBy="shopping-bag-tab"
                isVisible={selected === 'bag'}
              >
                <div className="shopping-bag">
                  {hasInventoryRepudiation && isSaveForLaterEnabled && (
                    <div className="soldout-repudiation-error-block-tabbed" data-testid="soldoutRepudiationError">
                      <ErrorMessage>
                        <Icon
                          icon="clock"
                          labelText="clock"
                          size="s"
                        />
                        {' '}
                        <Tmnt tmnt={bag?.textFor?.soldoutMovedToSfl} />
                      </ErrorMessage>
                    </div>
                  )}
                  {hasRepudiation && (
                    <div className="bag-repudiation" data-testid="repudiation-tabbed">
                      <Repudiation
                        productList={bag?.repudiationData?.repudiationProducts?.items}
                        errorMessage={bag?.repudiationData?.errorMessage}
                        itemsToRemove={bag?.repudiationData?.itemsToRemove}
                        tmntData={bag?.textFor}
                        continueInCurrency={bag?.repudiationData?.continueInCurrency}
                        hasCheckoutButton={bag?.repudiationData?.hasCheckoutButton}
                        checkoutUrl={bag?.repudiationData?.checkoutUrl}
                        onRemoveItems={removeShoppingBagItemMutation}
                        showRemoveItemsButton={!hasInventoryRepudiation}
                      />
                    </div>
                  )}
                  <SoldOutRepudiation
                    tmntData={bag?.textFor}
                    checkoutUrl={bag?.checkoutButton?.checkoutUrl}
                  />
                  {hasItemsInBag ? (
                    <div data-testid="baglist-tabbed" className={isDisabledPointerEvents && 'disable-baglist-pointer-events'}>
                      <BagList
                        variant="leftRail"
                        bagItems={bag?.bagItems?.items}
                        tmntData={bag?.textFor}
                        showShopSimilarItems
                        isSaveForLaterEnabled={isSaveForLaterEnabled}
                        removeBagItem={removeShoppingBagItem}
                        moveToSFL={moveToSaveForLater}
                      />
                    </div>
                  ) : (bag?.bagItems?.statusCode !== '408'
                    && <p className="empty-bag-text"><Tmnt tmnt={bag?.textFor?.emptyBag} /></p>
                  )}
                  {
                    !bag?.bagItems?.success
                    && bag?.bagItems?.statusCode !== '401'
                    && (
                      <div className="error-section">
                        {bag?.bagItems?.statusMessages?.map(
                          (err) => err?.message
                            && <ErrorMessage key={err}>{err?.message}</ErrorMessage>,
                        )}
                      </div>
                    )
                  }
                </div>
              </TabPanel>
              <TabPanel
                id="saveforlater-tab-panel"
                labelledBy="save-for-later-tab"
                isVisible={selected === 'sfl'}
              >
                {isSaveForLaterEnabled && (
                  <div data-testid="saveforlater-tabbed" className={isDisabledPointerEvents && 'disable-baglist-pointer-events'}>
                    <SaveForLater
                      moveToBag={moveToShoppingBagList}
                      deleteFromSFL={deleteFromSavedFromLater}
                      hasPageHeader={false}
                      isLoggedIn={bag?.userData?.isLoggedIn}
                      viewType="tabbed"
                    />
                  </div>
                )}
              </TabPanel>
            </Tabs>
          </div>
        </div>
        )}
        <ShoppingBagRecommendations
          hasItemsInBag={hasItemsInBag}
          placements={recommendationsProps.placements}
          slidesToShow={recommendationsProps.slidesToShow}
          bagItems={bag?.bagItems?.items}
          id={recommendationsProps.id}
        />
        {/* Tabbed Product Rail ends */}
      </div>
    </div>
  );
}
