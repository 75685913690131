import { gql } from '@apollo/client';

export const popinsModalTextQuery = gql`
query FetchPopinsModalInfo {
  textFor {
    cityStateZip: pair(pairKey: "cityStateZip") {...values}
    estimatedPickup: pair(pairKey: "estimatedPickup") {...values}
    findingStoresNear: pair(pairKey: "findingStoresNear") {...values}
    freeStorePickup: pair(pairKey: "freeStorePickup") {...values}
    loadMore: pair(pairKey: "loadMore") {...values}
    locationsNear: pair(pairKey: "locationsNear") {...values}
    locationServicesBlocked: pair (pairKey: "sddLocationServicesBlocked") {...values}
    mapAndHours: pair(pairKey: "mapAndHours") {...values}
    nearMe: pair(pairKey: "nearMe") {...values}
    noStoresFound: pair(pairKey: "noStoresFound") {...values}
    or: pair(pairKey: "or") {...values}
    pickupUnavailable: pair(pairKey: "pickupUnavailable") {...values}
    pleaseTryDifferentLocation: pair(pairKey: "pleaseTryDifferentLocation") {...values}
    popinsLegal: pair(pairKey: "popinsPudosLegal") {...values}
    popinsResultsError: pair(pairKey: "popinsResultsError") {...values}
    searching: pair(pairKey: "searching") {...values}
    selectStore: pair(pairKey: "selectStore") {...values}
    selectedStore: pair(pairKey: "selectedStore") {...values}
    you: pair(pairKey: "you") {...values}
    closeButton: pair(pairKey: "closeButton") {...values}
    change: pair(pairKey: "change") {...values}
    seeStore: pair(pairKey: "seeStore") {...values}
    countryText: pair(pairKey: "countryText") {...values}
    pickupInStore: pair(pairKey: "pickupInStore") {...values}
  }
}

fragment values on TextPair {
    key
    value
  }
`;

export const getPopinsResultsQuery = gql`
  query FetchPopinsResults($geolocation: GeolocationInput, $bagItems: [BagItemInput], $shippingAddress: ShippingAddressInput) {
    popins {
      searchStores(geolocation: $geolocation, bagItems: $bagItems, shippingAddress: $shippingAddress) {
        popinsLocations {
          ...popinsLocation
        }
        storeLimit
        showAllStores
      }
    }
  }

  fragment popinsLocation on PopinsLocation {
    address {
      ...storeAddress
    }
    geoPosition {
      ...geoPosition
    }
    id
    mapAndHoursUrl
    staticMapUrl
    name
    physicalStoreAttribute {...physicalStoreAttribute}
    operatingHours {
      ...operatingHours
    }
    eligibleForPickup
    estimatedPickupDateFmt
    inventoryAvailability {...inventoryAvailability}
    storeBrand
    storeBrandLogo
    storeMessage
  }
  fragment storeAddress on StoreAddress {
    city
    country
    postalCode
    province
    state
    street
  }
  fragment geoPosition on GeoPosition {
    latitude
    longitude
  }
  fragment operatingHours on StoreOperatingHours {
    dayOfWeek
    closeHours
    openHours
    sortSeq
  }

  fragment inventoryAvailability on InventoryAvailability {
    availabilityDateTime
    availabilityDateTimeFmt
    availableQuantity
    inventoryStatus
    sku
    storeNumber
    unitOfMeasure
  }

  fragment physicalStoreAttribute on PhysicalStoreAttribute {
    displayable
    name
    value
  }
`;
