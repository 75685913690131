import React, { useContext, useEffect, useState } from 'react';
import GiftCard from './GiftCard/GiftCard';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import PaymentHeader from './PaymentHeader';
import PaymentOptionsContainer from './PaymentOptionsContainer';
import { isPageUrlContains } from '../../../tools/pageHelper';
import CartPaymentDetails from './CartPaymentDetails';
import { localStorageGetValue } from '../../../tools/localStorage';
import useLog from '../../useLog/useLog';

export default function PaymentManager() {
  const logger = useLog('checkout.PaymentManager');
  const { checkoutPageState } = useContext(CheckoutPageContext);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) { return null; }
  const isKlarnaOrApmSelected = (
    checkoutPageState?.selectedPaymentType === 'klarna'
    || checkoutPageState?.selectedPaymentType === 'apm'
  );
  const hasPaymentsMfe = checkoutPageState?.switches?.hasPaymentSectionMFE;
  const hasPaypalError = !!localStorageGetValue('hasPaypalError');
  const isPayPalExpressCheckout = isPageUrlContains('/ProcessPayPalReturnAndOrderExpress');
  const cartPayment = checkoutPageState?.cartPayment;
  const orderId = checkoutPageState?.orderTotals?.orderId;
  const grandTotal = checkoutPageState?.orderTotals?.grandTotal;
  const paymentOptions = checkoutPageState?.paymentOptions;

  const renderPaymentOptionsInMfe = () => {
    if (
      hasPaymentsMfe
      && grandTotal > 0
      && (
        !isPayPalExpressCheckout
        || (isPayPalExpressCheckout && hasPaypalError)
      )
      && !paymentOptions?.length
    ) {
      logger.error(`ERR: payment options not available: ${JSON.stringify({
        paymentOptions,
        hasPaymentsMfe,
        orderId,
        isPayPalExpressCheckout,
        hasPaypalError,
        cartPayment,
      })}`);
    }
    return (
      hasPaymentsMfe
      && grandTotal > 0
      && (
        !isPayPalExpressCheckout
        || (isPayPalExpressCheckout && hasPaypalError)
      )
      && paymentOptions?.length > 0
      && <PaymentOptionsContainer />
    );
  };

  const renderCartPaymentDetailsInMfe = () => {
    if (
      hasPaymentsMfe
      && isPayPalExpressCheckout
      && grandTotal > 0
      && !cartPayment
      && !hasPaypalError
    ) {
      logger.error(`ERR: cart payment not available: ${JSON.stringify({
        hasPaymentsMfe,
        orderId,
        isPayPalExpressCheckout,
        hasPaypalError,
        cartPayment,
      })}`);
    }
    return (
      hasPaymentsMfe
      && isPayPalExpressCheckout
      && cartPayment
      && !hasPaypalError
      && <CartPaymentDetails cartPayment={cartPayment} />
    );
  };

  return (
    <div className="payment-manager">
      {
        !isPayPalExpressCheckout
        && (
          <>
            <PaymentHeader />
            {checkoutPageState?.config?.numberOfGiftCardsAllowed > 0
            && !isKlarnaOrApmSelected
            && (
              <GiftCard />
            )}
          </>
        )
      }
      {
        renderPaymentOptionsInMfe()
      }
      {
        renderCartPaymentDetailsInMfe()
      }
    </div>
  );
}
