import { rootLogger } from '../components/useLog/useLog';

// eslint-disable-next-line import/prefer-default-export
export async function getToken(
  encryptedCardNumber,
  cardExpiryMonth,
  cardExpiryYear,
  pxpConfig,
) {
  let pxpToken;
  const {
    pxpTokenUrl,
    timeout = 10000,
    publicApiKey,
    publicEncryptionKey,
    encryptionType = 'rsaTwoFourZeroEight',
    pxpMerchantID,
  } = pxpConfig || {};
  if (!pxpTokenUrl || !publicApiKey || !publicEncryptionKey?.id || !pxpMerchantID) {
    rootLogger.logger('getToken').warn('Config incomplete', JSON.stringify({
      pxpTokenUrl,
      publicApiKey,
      publicEncryptionKey,
      pxpMerchantID,
    })).destroy();
    return pxpToken;
  }
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `PublicApiKey ${publicApiKey}`,
  };
  const signal = AbortSignal.timeout(timeout);
  const body = JSON.stringify({
    details: {
      cardExpiryMonth,
      cardExpiryYear,
      cardNumber: encryptedCardNumber,
    },
    encryptionDetails: {
      encryptionType,
      encryptionId: publicEncryptionKey?.id,
    },
    merchantId: pxpMerchantID,
  });
  try {
    const response = await fetch(
      pxpTokenUrl,
      {
        method: 'POST',
        headers,
        body,
        signal,
      },
    );
    if (response?.ok) {
      const data = await response.json();
      if (data && data?.State?.trim()?.toLowerCase() === 'approved') {
        pxpToken = data?.Id;
      }
    } else {
      rootLogger.logger('getToken').error('ERROR', JSON.stringify(response)).destroy();
    }
  } catch (err) {
    rootLogger.logger('getToken').error('ERROR', err?.message).destroy();
  }
  return pxpToken;
}
