import {
  gql,
} from '@apollo/client';

// QUERY: gql query to fetch charity and shipping speed data
const CHECKOUTPAGE_DATA_QUERY = gql`
  query FetchCheckoutPage {
    klarnaState {
      isEnabled
      orderInfo {
        locale
        merchant_reference1
        merchant_reference2
        order_amount
        order_lines {
          image_url
          product_url
          type
          reference
          quantity
          unit_price
          total_amount
          name
        }
        order_tax_amount
        purchase_country
        purchase_currency
      }
      sessionRequestBody {
        orderId
        purchaseCountry
        currency
        locale
        orderTotal
        tax
        shippingDetails {
          type
          name
          quantity
          unitPrice
          totalAmount
        }
        items {
          type
          shortSku
          orderItemId
          quantity
          price
          offerPrice
          contractPrice
          listPrice
          name
          productUrl
          imageUrl
          adjustments {
            shippingPromo
            type
            name
            quantity
            unitPrice
            totalAmount
          }
        }
        adjustments {
          shippingPromo
          type
          name
          quantity
          unitPrice
          totalAmount
        }
        attributes {
          options {
            color_details
            color_button
            color_button_text
            color_checkbox
            color_checkbox_checkmark
            color_header
            color_link
            color_text
            color_text_secondary
          }
        }
      }
    }
    googleApiKey
    isAppWebView
    rewardsAndPromotions {
      couponInfo {
        couponCode
        associatedPromoKey
        associatedPromoName
        formattedValue
        couponExpiryDate
        couponApplied
        offerHeaderKey
        offerHeader
        promoType
        exclusionsApplyTmntKey
        offerType
        isPromotion
        associatedPoints
        tier
      }
      legalTermsTmntKey
      totalCoupons
    }
    repudiationData {
      errorMessage
      itemsToRemove
      repudiationType
      checkoutUrl
      hasCheckoutButton
      continueInCurrency
      repudiationProducts {
        hasGiftCard
        items {
          item {
            headers {
              badgeStatusMessage {
                key
                value
              }
              headerMessage
              shouldDisplayAsbadge
              attached
              fullWidth
              textAlign
              variant
            }
            footer {
              bogoMessage
            }
            image {
              altText
              imageSrc
              imageId
              classList
            }
            imageOverlay {
              productUrl
              zoomIn
            }
            secondaryContent {
              buttonGroup
            }
            productContent {
              gender
              name
              size
              brand
              shortSku
              longSku
              orderItemId
              productId
              collectionId
              freeGift
              seq
              faceOut
              faceoutImage
              color
              productPrice {
                description
                originalPrice
                discountPrice
                discountText
                variant
                priceFlag
                original
                discount
              }
              promotions {
                shortDesc
                promotionType
              }
              deliveryDate
              toEmail
              editRemoveButtonVariant
              editRemoveButton
              hasRemove
              isFinalSale
            }
          }
          giftBox {
            isWrapped
            giftBoxMessage
          }
        }
      }
    }
    charity {
      name
      id
      brand
      sku
      option {
        id
        name
        checked
        charityAmount
      }
    }
    shippingSpeed {
      amount
      amountDisplay
      addressType
      country
      description
      estimatedDeliveryDate
      estimatedDeliveryDateDisplay
      externalId
      isSelected
      isValid
      isEnabled
      shipModeId
      shipModeType
      state
      availability {
        cartID
        courierEligible
        storeNo
        shipDate
        orderByTime
        timeZone
        reason
        hasOption
        postalCode
        isValid
        shouldBeSelected
        eligibilityConfirmed
        ineligibilityConfirmed
        usingExpressLanePayment
        displayChangeLink
        sddAvailabilityMessage
        geolocation {
          country
          countryAbbr
          latlng
          postalCode
          postalTown
          state
          stateAbbr
        }
      }
    }
    paymentConfig {
      creditCard {
        creditCardTypes {
          type
          maxlength
          code
          regex
        }
        cardNumberLabel { ...values }
        expiryLabel { ...values }
        securityCodeLabel { ...values }
        securityCodeImage
        securityCodeMessage { ...values }
        amountLabel { ...values }
        creditCardMissingErrorMessage { ...values }
        creditCardErrorMessage { ...values }
        expiryErrorMessage { ...values }
        cvvErrorMessage { ...values }
        unableToProcessCardErrorMessage { ...values }
      }
      googlePay {
        mid
        sid
        allowedMethods
        allowedCardNetworks
        amountStatus
        profile
        publicKeyApi
        useGooglePayButton
        merchantNo
        merchantName
        pxpScriptURL
      }
      applePay {
        mid
        sid
        publicKeyApi
        pxpScriptURL
        tokenVaultURL
        applePayRequest {
          countryCode
          currencyCode
          merchantCapabilities
          supportedNetworks
          total {
            label
            amount
            type
          }
          shippingMethods {
            amount
            detail
            identifier
            label
          }
          lineItems {
            label
            amount
          }
        }
      }
      payPal {
        hasPayPal
        payPalEnvironment
        payPalMerchantId
        payPalInContextScriptSrc
      }
      savedPayments {
        iconCode
        maskedCardNumber
        cardExpiryMonth
        cardExpiryYear
        cardType
        requiresCvv
        savedPaymentId
        default
      }
    }
    bagItems {
     hasGiftCard
     hasOnlyGiftCard
     hasOnlyEGiftCard
     items {
      item {
        headers {
          badgeStatusMessage {
            key
            value
          }
          headerMessage
          estimatedShipDate
          shouldDisplayAsbadge
          attached
          fullWidth
          textAlign
          variant
        }
        footer {
          bogoMessage
        }
        image {
          altText
          imageSrc
          imageId
          classList
        }
        secondaryContent {
          buttonGroup
        }
        productContent {
          gender
          name
          size
          brand
          shortSku
          longSku
          orderItemId
          productId
          collectionId
          freeGift
          seq
          faceOut
          faceoutImage
          color
          notWrappable
          deliveryDate
          toEmail
          productPrice {
            description
            originalPrice
            discountPrice
            discountText
            variant
            priceFlag
            original
            discount
            originalUSD
            discountUSD
          }
          promotions {
            shortDesc
            promotionType
          }
          kicId
          isFinalSale
          itemShortDesc
          quantity
        }
      }
      giftBox {
        isWrapped
        giftBoxMessage
      }
     }
    }
    shippingAddress {
      addressId
      firstName
      lastName
      address1
      address2
      city
      state
      country
      postalCode
      addressType
      sameDayStoreId
      province
      district
      pickUpStoreId
      displayType
      isAPOFPO
      isDefaultBilling
      isDefaultShipping
      isShippingAllowed
      isBillingAllowed
      legendAddress
      legendName
      poBox
      subType
      orgName
      title
      collectionPointID
    }
    applePayButton {
    orderConfirmationURL
    hasApplePay
    hasApplePayCheckout
    applePaySSLClientAlias
    applePayMerchantCodes
    applePayMerchantIds
    }
    payPalExpressButton {
    hasPayPal
    hasGiftBoxOnPaypalExpress
    paypalExpressUrl
    payPalEnvironment
    hasPaypalFailoverEnabled
    payPalMerchantId
    payPalRedirectUrl
    PayPalInContextScriptSrc
    hasPayPalExpressOnPhoenixCheckout
    }
    cartState {
      isShippingAddressComplete
      isBillingAddressComplete
      isShippingMethodSet
      isPaymentComplete
    }
    cartPayment {
      paypalPayment {
        paymentCode
        paymentType
        paymentName
        paymentEmail
      }
    }
    textFor {
      sameDayDeliveryChangeZipTMNTValue: pair (pairKey: "sameDayDeliveryChangeZipText") {...values}
      sameDayDeliveryCheckAvailabilityTMNTValue: pair (pairKey: "sameDayDeliveryCheckAvailabilityText") {...values}
      sameDayDeliveryOrderByTMNTValue: pair (pairKey: "sameDayDeliveryOrderByText") {...values}
      sameDayDeliveryUnavailableTMNTValue: pair (pairKey: "sameDayDeliveryUnavailableText") {...values}
      sameDayDeliveryNotAvailableInLocationTMNTValue: pair (pairKey: "sameDayDeliveryNotAvailableInLocationText") {...values}
      inStockItemsOnlyTMNTValue: pair (pairKey: "inStockItemsOnlyText") {...values}
      estDeliveryTMNTValue: pair (pairKey: "estDeliveryText") {...values}
      shippingSpeedLegendTMNTValue: pair (pairKey: "shippingSpeedLegendText") {...values}
      pickupMyOrderTMNTValue: pair (pairKey: "pickupMyOrderText") {...values}
      shippingSpeedPickupLegendTMNTValue: pair (pairKey: "shippingSpeedPickupLegendText") {...values}
      pickupMyOrderTMNTValue: pair (pairKey: "pickupMyOrderText") {...values}
      pickupPointTMNTValue: pair (pairKey: "pickupPointText") {...values}
      pickupInStoreBrandTMNTValue: pair (pairKey: "pickupInStoreBrandText") {...values}
      pickupServiceProviderTMNTValue: pair (pairKey: "pickupServiceProviderText") {...values}
      pickupInStoreTMNTValue: pair (pairKey: "pickupInStoreText") {...values}
      itemsLabel: pair (pairKey: "itemsLabel") {...values}
      giftBoxesLabelTMNTValue: pair (pairKey: "giftBoxesLabel") {...values}
      estimatedTaxLabelTMNTValue: pair (pairKey: "estimatedTaxLabel") {...values}
      charityLabelTMNTValue: pair (pairKey: "charityLabel") {...values}
      shippingHandlingTextTMNTValue: pair (pairKey: "shippingHandlingText") {...values}
      orderTotalIncludesConsumpTaxTMNTValue: pair (pairKey: "orderTotalIncludesConsumpTax") {...values}
      orderTotalIncludesGstTMNTValue: pair (pairKey: "orderTotalIncludesGst") {...values}
      orderTotalIncludesVatTMNTValue: pair (pairKey: "orderTotalIncludesVat") {...values}
      orderTotal: pair (pairKey: "orderTotal") {...values}
      promoDiscountsLabel: pair (pairKey: "promoDiscountsLabel") {...values}
      popinsShippingAndHandling: pair (pairKey: "popinsShippingAndHandling") {...values}
      pickupPointShippingAndHandling: pair (pairKey: "pickupPointShippingAndHandling") {...values}
      freeShippingHandling: pair (pairKey: "freeShippingHandling") {...values}
      giftCardLabelTMNTValue: pair (pairKey: "giftCardLabel") {...values}
      vatTMNTValue: pair (pairKey: "vat") {...values}
      detailsTMNTValue: pair (pairKey: "details") {...values}
      applyButton: pair (pairKey: "applyButton") {...values}
      removeButton: pair (pairKey: "removeButton") {...values}
      appliedButton: pair (pairKey: "appliedButton") {...values}
      returnRefundButton: pair (pairKey: "returnRefundButton") {...values}
      exchangeButton: pair (pairKey: "exchangeButton") {...values}
      yourBag: pair (pairKey: "yourBag") {...values}
      inStockExpectedDeliveryDate: pair (pairKey: "inStockExpectedDeliveryDate") {...values}
      subscribeToEmailLegalConsent: pair (pairKey: "subscribeToEmailLegalConsent") {...values}
      promotions: pair (pairKey: "promotions") {...values}
      promoCode: pair (pairKey: "promoCode") {...values}
      giftReceiptIncluded: pair (pairKey: "giftReceiptIncluded") {...values}
      giftReceipText: pair (pairKey: "giftReceipText") {...values}
      finalSaleTMNTValue: pair (pairKey: "finalSale") {...values}
      sendTo: pair (pairKey: "sendTo") {...values}
      sendOn: pair (pairKey: "sendOn") {...values}
      pickupMyOrder: pair (pairKey: "pickupMyOrder") {...values}
      homeDelivery: pair (pairKey: "homeDelivery") {...values}
      rewardsAndOffersTMNT: pair (pairKey: "rewardsAndOffers") {...values}
      loyaltyRedeemedTMNT: pair (pairKey: "loyaltyRedeemed") {...values}
      loyaltyRedeemTMNT: pair (pairKey: "loyaltyRedeem") {...values}
      loyaltyEstimateDetailsTMNT: pair (pairKey: "loyaltyEstimateDetailes") {...values}
      loyaltyUndoTMNT: pair (pairKey: "loyaltyUndo") {...values}
      loyaltyExclusionApplyTMNT: pair (pairKey: "loyaltyExclusionsApply") {...values}
      heyUser: pair (pairKey: "heyUser") {...values}
      dateText: pair (pairKey: "dateText") {...values}
      sessionText: pair (pairKey: "sessionText") {...values}
      getRewarded: pair (pairKey: "getRewarded") {...values}
      loyaltyProgressBarText: pair (pairKey: "loyaltyProgressBarText") {...values}
      loyaltyCurrency: pair (pairKey: "loyaltyCurrency") {...values}
      loyaltyTrackerContentCopyPreText: pair (pairKey: "loyaltyTrackerContentCopyPreText") {...values}
      loyaltyTrackerContentCopyMidText: pair (pairKey: "loyaltyTrackerContentCopyMidText") {...values}
      loyaltyTierActionText: pair (pairKey: "loyaltyTierActionText") {...values}
      loyaltyVipProgressXXOfXX: pair (pairKey: "loyaltyVipProgressXXOfXX") {...values}
      loyaltyTierTrackerHeading: pair (pairKey: "loyaltyTierTrackerHeading") {...values}
      loyaltyTierRetentionHeading: pair (pairKey: "loyaltyTierRetentionHeading") {...values}
      loyaltyTrackerContentCopyPostTet: pair (pairKey: "loyaltyTrackerContentCopyPostTet") {...values}
      loyaltyTierTrackerHeadingVip: pair (pairKey: "loyaltyTierRetentionHeadingVip") {...values}
      loyaltyTierRetentionHeadingVip: pair (pairKey: "loyaltyTierRetentionHeadingVip") {...values}
      loyaltyTrackerContentCopyPostTextVip: pair (pairKey: "loyaltyTrackerContentCopyPostTextVip") {...values}
      loyaltyVIPUntil: pair (pairKey: "loyaltyVIPUntil") {...values}
      loyaltyTierTrackerHeadingPlatinum: pair (pairKey: "loyaltyTierTrackerHeadingPlatinum") {...values}
      loyaltyTierRetentionHeadingPlatinum: pair (pairKey: "loyaltyTierRetentionHeadingPlatinum") {...values}
      loyaltyPlatinumUntilText: pair (pairKey: "loyaltyPlatinumUntilText") {...values}
      loyaltyLogoLabel: pair (pairKey: "loyaltyLogoLabel") {...values}
      loyaltyExp: pair (pairKey: "loyaltyExp") {...values}
      currencyUntilNextReward: pair (pairKey: "currencyUntilNextReward") {...values}
      loyaltyCrossBorderWarning: pair (pairKey: "loyaltyCrossBorderWarning") {...values}
      signIn: pair (pairKey: "signIn") {...values}
      emptyBagSignInButton: pair (pairKey: "emptyBagSignInButton") {...values}
      joinButton: pair (pairKey: "joinButton") {...values}
      rewardsStatus: pair (pairKey: "rewardsStatus") {...values}
      rewardAvailable: pair (pairKey: "rewardAvailable") {...values}
      signOutTMNTValue: pair (pairKey: "signOut") {...values}
      account: pair (pairKey: "account") {...values}
      member: pair (pairKey: "member") {...values}
      clearance: pair (pairKey: "clearance") {...values}
      change: pair (pairKey: "change") {...values}
      shipTo: pair (pairKey: "shipTo") {...values}
      giftCardRedeem: pair (pairKey: "giftCardRedeem") {...values}
      giftCardRedeemAnother: pair (pairKey: "giftCardRedeemAnother") {...values}
      giftCardApplyMaxMessage: pair (pairKey: "giftCardApplyMaxMessage") {...values}
      giftCardNumber: pair (pairKey: "giftCardNumber") {...values}
      giftCardPin: pair (pairKey: "giftCardPin") {...values}
      giftCardCheckBalance: pair (pairKey: "giftCardCheckBalance") {...values}
      giftCardBalanceRemaining: pair (pairKey: "giftCardBalanceRemaining") {...values}
      giftCardApply: pair (pairKey: "giftCardApply") {...values}
      giftCardRemove: pair (pairKey: "giftCardRemove") {...values}
      giftCardApplied: pair (pairKey: "giftCardApplied") {...values}
      giftCardMaxNumAppliedMessage: pair (pairKey: "giftCardMaxNumAppliedMessage") {...values}
      giftCardTooltipContent: pair (pairKey: "giftCardTooltipContent") {...values}
      giftCardAmountUsed: pair (pairKey: "giftCardAmountUsed") {...values}
      mfeErrorMessage: pair (pairKey: "mfeErrorMessage") {...values}
      removeItemsTMNTValue: pair (pairKey: "removeItems") {...values}
      removeItemTMNTValue: pair (pairKey: "removeItem") {...values}
      securePayment: pair (pairKey: "securePayment") {...values}
      savedPayments: pair (pairKey: "savedPayments") {...values}
      exp: pair (pairKey: "exp") {...values}
      expires: pair (pairKey: "expires") {...values}
      paymentOptions: pair (pairKey: "paymentOptions") {...values}
      provideSecurityCode: pair (pairKey: "provideSecurityCode") {...values}
      securityCode: pair (pairKey: "securityCode") {...values}
      clear: pair (pairKey: "clear") {...values}
      useThisPayment: pair (pairKey: "useThisPayment") {...values}
      payOverTimeLearnMore: pair (pairKey: "payOverTimeMessagingLearnMore") {...values}
      privacyPolicy: pair (pairKey: "privacyPolicy") {...values}
      saveCardForFutureUse: pair (pairKey: "saveCardForFutureUse") {...values}
      noSavedPayments: pair (pairKey: "noSavedPayments") {...values}
      expressCheckout: pair (pairKey: "expressCheckout") {...values}
      checkoutButtonText: pair (pairKey: "checkoutButtonText") {...values}
      paypalButtonText: pair (pairKey: "paypalButtonText") {...values}
      applePayButtonText: pair (pairKey: "applePayButtonText") {...values}
      # formTitle: pair(pairKey: "recognizeCustomerTitle") {...values}
      email: textField(fieldKey: "email") {
        label {...values}
        error {...values}
      }
      passwordLabel: textField(fieldKey: "passwordLabel") {
        label {...values}
        error {...values}
      }
      buttonSubmit: pair (pairKey: "submit") {...values}
      buttonProcessing: pair (pairKey: "processing") {...values}
      buttonError: pair (pairKey: "error") {...values}
      buttonSuccess: pair (pairKey: "success") {...values}
      formError: pair(pairKey: "formError") {...values}
      signInButton: pair (pairKey: "userSignIn") {...values}
      keepMeSignedInCheckBox: pair (pairKey: "keepMeSignedIn") {...values}
      orText: pair (pairKey: "orText") {...values}
      forgotPassword: pair (pairKey: "forgotPassword") {...values}
      join: pair (pairKey: "join") {...values}
      continueAsGuestButton: pair (pairKey: "continueAsGuest") {...values}
      keepMeSignedInUncheckMsg: pair (pairKey: "keepMeSignedInUncheckMsg") {...values}
      dontHaveAnAccount: pair (pairKey: "dontHaveAnAccount") {...values}
      signInOrContinueAsGuestText: pair (pairKey: "signInOrContinueAsGuestText") {...values}
      passwordShow: pair (pairKey: "passwordShow") {...values}
      passwordHide: pair (pairKey: "passwordHide") {...values}
      emailInvalidFormatText: pair (pairKey: "emailInvalidFormat") {...values}
      emailInvalidText: pair (pairKey: "emailInvalid") {...values}
      passwordInvalidText: pair (pairKey: "passwordInvalid") {...values}
      errorSomethingWentWrong: pair (pairKey: "errorSomethingWentWrong") {...values}
      threeDsAnfMessage: pair (pairKey: "threeDsAnfMessage") {...values}
      processingOrder: pair (pairKey: "processingOrder") {...values}
      franchiseCountryRepudiationError: pair (pairKey: "franchiseCountryRepudiationError") {...values}
      franchiseCountryRepudiationErrorMobile: pair (pairKey: "franchiseCountryRepudiationErrorMobile") {...values}
      franchiseCountryPartnerSiteRedirectNotice: pair (pairKey: "franchiseCountryPartnerSiteRedirectNotice") {...values}
      itemsNotSavedOrTransferredAndPromotionsMayVary: pair (pairKey: "itemsNotSavedOrTransferredAndPromotionsMayVary") {...values}
      closeButton: pair (pairKey: "closeButton") {...values}
      cityStateZip: pair (pairKey: "cityStateZip") {...values}
      nearMe: pair (pairKey: "nearMe") {...values}
      or: pair (pairKey: "or") {...values}
      findPickupStore: pair (pairKey: "findPickupStore") {...values}
      seeStore: pair (pairKey: "seeStore") {...values}
      countryText: pair (pairKey: "countryText") {...values}
      change: pair (pairKey: "change") {...values}
    }
    switches {
      usingSameDayDeliveryMFE: isOn ( switchKey: "HasSameDayDeliveryMFE")
      showPayOverTime: isOn ( switchKey: "HasPayOverTimeMessagingCheckout")
      # repurposed HasGiftCardPaymentUrl store attribute for switch on/off MFE gift card section
      hasGiftCardCheckoutRecaptcha: isOn ( switchKey: "HasGiftCardCheckoutRecaptcha")
      invisibleRecaptchaSiteKey: value ( switchKey: "InvisibleRecaptchaSiteKey")
      hasGlobalReCaptcha: isOn ( switchKey: "HasGlobalReCaptcha")
      hasPaymentSectionMFE: isOn ( switchKey: "HasPaymentSectionMFE" )
    }
    config {
      isVatIncluded: bool (name: "isVatIncluded")
      showEmailLegalConsent: bool (name: "showEmailLegalConsent")
      numberOfGiftCardsAllowed: int (name: "numberOfGiftCardsAllowed")
    }
    eSpots {
      checkoutMemberPricingBelowPromotions: get(espotId: "checkout_memberpricing_belowpromotions") {...eSpotContentValues}
      cartMemberPricingBelowRewards: get(espotId: "checkout_memberpricingleft") {...eSpotContentValues}
      checkoutPopins: get(espotId: "checkout_popins") {...eSpotContentValues}
      checkoutPopinsMessage: get(espotId: "checkout_popinsmessage") {...eSpotContentValues}
      checkoutPudosMessage: get(espotId: "checkout_pudosmessage") {...eSpotContentValues}
    }
    marketingPreferences {
      headingLabel {
        key
        value
      }
      hideMarketingPreferences
      brands {
        id
        label {
          key
          value
        }
        selected
      }
    }
    userData {
      statusCode
      success
      statusMessages {
        code
        key
        message
      }
      hasSignInButton
      hasLoyaltyEnabled
      isRemembered
      isLoggedIn
      userNotifications {
        key
        value
      }
      loggedInData {
        registrationDate
        userName
        memberSince
      }
      iconImageUrl
      loyaltyInfo {
        pointBalance
        nextRewardPointsThreshold
        formattedNextRewardValue
        accountStatus
        loyaltyTierInfo {
          loyaltyTier
          actionNeededForNextTier
          formattedActionNeededForNextTier
          spendByDateForNextTier
          formattedLoyaltyTierExpirationDate
        }
        currencyUntilNextRewardValue {
          key
          value
        }
        earnPointsTMNT {
          key
          value
        }
        loyaltyEstimatedPoints
        estimatedPointsTMNT {
          key
          value
        }
      }
      displayIconImage
    }
    orderContact {
      hideContactInfo
      email
      phoneNumber
      emailLabel {
        key
        value
      }
      phoneNumberLabel {
        key
        value
      }
      headingLabel {
        key
        value
      }
      invalidEmailLabel {
        key
        value
      }
      invalidPhoneLabel {
        key
        value
      }
      smsOptInLabel {
        key
        value
      }
      smsOptInDetailedLabel {
        key
        value
      }
      smsOptInPrivacyNotice {
        key
        value
      }
      smsOptInTextTerm {
        key
        value
      }
      smsOptInAnd {
        key
        value
      }
      addressType
      smsOptIn
      privacyUrl
      textTermUrl
    }
    smsOptIn {
      isEnabled
    }
    shipOrPickup {
      title
      key
      country
      isHomeDelivery
      isExpressLane
      availableShippingMethods {
        shipModeId
        shipModeType
        externalId
      }
      countryList {
        label
        value
     }
    }
    vatDetails {
      showVatDetails
      vatIncludedText
      dutyVatDetailsText
      vatDetailsText {
        key
        value
      }
    }
    promoInfo {
      promoCompId
      promotionCode
      promotionKey
      longDesc
      isShippingPromo
      isRewardPromo
      isPromoBadging
      isImplicitPromo
      amount
      amountFmt
      orderAdjustmentId
      shortDesc
      promotionType
    }
    orderTotals {
      orderId
      subTotal
      totalItemsInBag
      cartTotalAmount
      cartTotalAmountFmt
      cartTotalAmountUSD
      cartPayableAmount
      subTotalFmt
      currency
      promoAmount
      promoAmountFmt
      shippingHandlingChargeFmt
      totalGiftBoxes
      totalGiftBoxesAmount
      displayFreeShippingText
      displayGiftReceiptText
      isGiftReceiptChecked
      estimatedTaxAmount
      estimatedTaxAmountFmt
      estimatedTaxAmountUSD
      charityRoundUpFmt
      includesConsumptionTax
      includesGst
      includesVat
      grandTotal
      grandTotalFmt
      charityTotalFmt
      subTotalUSD
      grandTotalUSD
      cartTotalAmountUSD
      totalAdjustmentUSD
      shippingCharge
      shippingChargeUSD
      shippingChargeFmt
    }
    orderSubmitLegalTerms {
      displayLegalSaleTerms
      displayReturnsAndExchanges
      displayLegalSiteUse
      displayLegalPrivacyPolicy
      displayCompanyInfo
      displayFapio
      displayLegalTermsCheckBox
      displayAgeConsentCheckBox
      displayPrivacyNoticeWithMoreInfo
      displayLegalOrderImportMessaging
      isAgeConsentOptionCheckedByDefault
      countriesConfig {
        hasImportDutyWaiver
        id
        addressFieldVisibility {
          prefecture
          province
          region
          state
          zone
        }
      }
      salesTermsLinkText {
        key
        value
      }
      returnAndExchangesLinkText {
        key
        value
      }
      siteUseText {
        key
        value
      }
      privacyPolicyText {
        key
        value
      }
      companyInfoText {
        key
        value
      }
      fapiaoText {
        key
        value
      }
      rightToCancelText {
        key
        value
      }
      moreInfoText {
        key
        value
      }
      tickConfirmAndAgreeText {
        key
        value
      }
      legalOrderImportMessageText {
        key
        value
      }
      privacyNoticeText {
        key
        value
      }
      requiredTermsConditionsText {
        key
        value
      }
      cancellationRightsContent {
        key
        value
      }
      returnsAndExchangesContent {
        key
        value
      }
      legalSubmitOrderButtonCopy {
        key
        value
      }
    }
    shipMethod {
      shipModeType
      shipModeId
      isHomeDelivery
      isTabVisible
    }
    eSpots {
      charityRoundup: get(espotId: "checkout_charityroundup") {...eSpotContentValues}
    }
    submitButton {
      label {
        key
        value
      }
      looksGoodPaypalLabel {
        key
        value
      }
      tryAgainLabel {
        key
        value
      }
      processingLabel {
        key
        value
      }
      successLabel {
        key
        value
      }
      unavailableMessage {
        key
        value
      }
      continueWithGooglePayLabel {
        key
        value
      }
      genericOrderSubmitError
    }
    giftCards {
      isAllowedGiftCards
      appliedGiftCards {
        id
        maskedNumber
        amountFmt
      }
    }
    paymentOptions {
      id
      name
      type
      code
      description {
        key
        value
      }
      message {
        key
        value
      }
      images {
        altText
        imageSrc
      }
      icon
      disabled
      savable
      default
    }
    page
    payPalExpressButton {
      hasPayPal
      hasGiftBoxOnPaypalExpress
      paypalExpressUrl
      payPalEnvironment
      hasPaypalFailoverEnabled
      payPalMerchantId
      payPalRedirectUrl
      PayPalInContextScriptSrc
      hasPayPalExpressOnPhoenixCheckout
    }
    applePayButton {
      orderConfirmationURL
      hasApplePay
      hasApplePayCheckout
      applePaySSLClientAlias
      applePayMerchantCodes
      applePayMerchantIds
    }
    pxpConfig {
      publicApiKey
      publicEncryptionKey {
        id
        rsa {
          exponent
          modulus
        }
      }
      pxpMerchantID
      pxpTokenUrl
      pxpTokenTimeout
      pxpEncryptRetries
      encryptionType
    }
  }
  fragment values on TextPair {
    key
    value
  }
  fragment eSpotContentValues on ESpotContent {
    name
    content
  }
`;

export default CHECKOUTPAGE_DATA_QUERY;
