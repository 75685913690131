/* eslint-disable */
import { RSAKey } from './rsa';
import { b64tohex, hex2b64 } from './base64';

// "private"
function _encrypt({ value, exponent, modulus, convertToHex }) {
  let response = null;
  let rsaKey = new RSAKey();

  rsaKey.setPublic(b64tohex(modulus), b64tohex(exponent));
  if (!convertToHex) {
    rsaKey.setPublic(modulus, exponent);
  }

  response = rsaKey.encrypt(value.toString());

  return response && convertToHex ? hex2b64(response) : rsaKey.encrypt(value.toString());
}

// { value: string, exponent: string, modulus: string, maxRetries: number, convertToHex: boolean }
export function encrypt({ value, exponent, modulus, maxRetries = 0, convertToHex = true }) {
  let encryptedValue = null;
  let currentRetryAttempt = 0;
  const validLength = 344;

  if (
    (value && (value !== '' || value !== undefined)) &&
    (exponent && (exponent !== '' || exponent !== undefined)) &&
    (modulus && (modulus !== '' || modulus !== undefined))
  ) {
    // We need to do this at least once, but if the length of what is returned is not equal to what we expect, lets try it again.
    do {    
      encryptedValue = _encrypt({ value, exponent, modulus, convertToHex });
      currentRetryAttempt += 1;
    } while (
      (typeof encryptedValue !== 'string' || encryptedValue.length !== validLength) &&
      (currentRetryAttempt < maxRetries)
    );
  }
  return encryptedValue;
}
