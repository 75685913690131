import { useMutation } from '@apollo/client';
import ORDER_SUBMIT_MUTATION from '../../gql/orderSubmit.gql';

const useSubmit = () => {
  const [mutate, { loading }] = useMutation(ORDER_SUBMIT_MUTATION);

  const orderSubmit = async (variables) => {
    try {
      // dipatch an event for Submit button to display processing state
      const processingEvent = new CustomEvent('mfe:order:submit', {
        detail: variables,
      });
      dispatchEvent(processingEvent);

      // run submit mutation
      const result = await mutate({ variables });

      if (result.data?.orderSubmit?.success) {
        // dipatch an event for Submit button to display success state
        // Success logic
        const successEvent = new CustomEvent('mfe:orderSubmit:success', {
          success: true,
          detail: result.data?.orderSubmit,
        });
        window.dispatchEvent(successEvent);

        // Return the successful data
        return result.data;
      }
      // Extract the error message from the response
      const errorMessage = result.data?.orderSubmit?.statusMessages?.[0]?.message ?? 'Something went wrong. Please try again.';

      // Throw an error to be caught in the calling code
      throw new Error(errorMessage);
    } catch (error) {
      // dispatch an error event for Submit button to display error state
      const errorEvent = new CustomEvent('mfe:orderSubmit:error', {
        success: false,
        detail: error,
      });
      window.dispatchEvent(errorEvent);

      // Re-throw the error to be caught in the calling code
      throw error;
    }
  };

  return { orderSubmit, loading };
};

export default useSubmit;
