import React, {
  useState, useCallback, useEffect,
} from 'react';
import {
  useMutation,
} from '@apollo/client';
import {
  Accordion, Button, ErrorMessage, DiscountCard, IconBlock, Icon,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import useLog from '../../useLog/useLog';
import decodeHTMLEntities from '../../../tools/decodeHtml';

import UserNotifications from '../UserNotiications/UserNotifications';
import { PROMOTION_UPDATE_MUTATION, PROMOTION_DELETE_MUTATION } from '../../../gql/promotion.gql';
import Loader from '../Loader/Loader';
import ModalButtonWithTMNTQuery from '../ModalButtonWithTMNTQuery/ModalButtonWithTMNTQuery';
import Tmnt from '../../Tmnt/Tmnt';
import trackAction from '../../../tools/analytics';
import $window from '../../../tools/window';

export default function RewardsPromos({
  coupons,
  estimatedPointsTMNT,
  loyaltyEstimatedPoints,
  totalCoupons,
  earnPointsTMNT,
  tmntData,
  legalTermsTmntKey,
  loyaltyStatus,
  page,
  purchaseContext,
  setPurchaseContext,
  setShowDiscount,
  showNotification,
  setShowNotification,
  notification,
}) {
  const [showError, setShowError] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const logger = useLog('shoppingBag.loyaltyRewards');
  useEffect(() => {
    setExpanded(!!(coupons && coupons?.length));
  }, [coupons]);

  const cartBrands = Array.from(new Set(purchaseContext?.bagItems?.items?.map((item) => item?.item?.productContent.brand))).join(',');
  const kicIds = Array.from(new Set(purchaseContext?.bagItems?.items?.map((item) => item?.item?.productContent.kicId))).join(',');

  const [updatePromoMutation] = useMutation(PROMOTION_UPDATE_MUTATION, {
    onCompleted: (updatePromo) => {
      logger.debug('THE PROMOTION_UPDATE_MUTATION RESULT', updatePromo.applyPromo);
      if (updatePromo?.applyPromo?.success) {
        setPurchaseContext((previousState) => {
          const newState = ({
            ...previousState,
            orderTotals: updatePromo?.applyPromo?.orderTotals,
            promoInfo: updatePromo?.applyPromo?.promoInfo,
            bagItems: updatePromo?.applyPromo?.bagItems,
            rewardsAndPromotions: updatePromo.applyPromo.rewardsAndPromotions,
            repudiationData: updatePromo?.applyPromo?.repudiationData,
            freeShippingProgressInfo: updatePromo?.applyPromo?.freeShippingProgressInfo,
            shippingSpeed: updatePromo?.applyPromo?.shippingSpeed,
          });

          if (page === 'checkout') {
            newState.charity = updatePromo?.applyPromo?.charity;
            newState.klarnaState = updatePromo?.applyPromo?.klarnaState;
            newState.giftCards = updatePromo?.applyPromo?.giftCards;
            newState.paymentOptions = updatePromo?.applyPromo?.paymentOptions;
          }

          // merge the bag or checkout response to digitalData `cart`
          $window.digitalData?.merge('cart', {
            origin: page,
            data: newState,
            action: 'loyalty_promo_add',
          });

          return newState;
        });
        setShowDiscount(true);
        setShowError(false);
        setShowNotification(true);
        setIsLoading(false);
      } else {
        // handle Error
        setPurchaseContext((previousState) => ({
          ...previousState,
          statusCode: updatePromo?.applyPromo?.statusCode,
          success: updatePromo?.applyPromo?.success,
          statusMessages: updatePromo?.applyPromo?.statusMessages,
        }));
        if (updatePromo?.applyPromo?.statusCode !== '408') {
          trackAction('universal_click', {
            data_text: 'promo redeem invalid because it has already been submitted',
            data_action: 'invalid submit',
            event_type: 'click',
          });
        }
        setShowError(true);
        setShowNotification(false);
        setIsLoading(false);
      }
      const loyaltyAppliedEvent = new CustomEvent('loyalty:update:done');
      window.dispatchEvent(loyaltyAppliedEvent);
    },
    onError: (err) => {
      logger.error(`ERR: PROMOTION_UPDATE_MUTATION: ${JSON.stringify(err)}`);
    },
  });
  const [deletePromoMutation] = useMutation(PROMOTION_DELETE_MUTATION, {
    onCompleted: (deletePromoObj) => {
      setPurchaseContext((previousState) => {
        const newState = ({
          ...previousState,
          orderTotals: deletePromoObj.removePromo.orderTotals,
          promoInfo: deletePromoObj.removePromo.promoInfo,
          bagItems: deletePromoObj.removePromo.bagItems,
          rewardsAndPromotions: deletePromoObj.removePromo.rewardsAndPromotions,
          repudiationData: deletePromoObj?.removePromo?.repudiationData,
          freeShippingProgressInfo: deletePromoObj.removePromo?.freeShippingProgressInfo,
          shippingSpeed: deletePromoObj.removePromo?.shippingSpeed,
        });

        if (page === 'checkout') {
          newState.charity = deletePromoObj?.removePromo?.charity;
          newState.klarnaState = deletePromoObj?.removePromo?.klarnaState;
          newState.giftCards = deletePromoObj?.removePromo?.giftCards;
          newState.paymentOptions = deletePromoObj?.removePromo?.paymentOptions;
        }

        // merge the bag or checkout response to digitalData `cart`
        $window.digitalData?.merge('cart', {
          origin: page,
          data: newState,
          action: 'loyalty_promo_remove',
        });

        return newState;
      });

      setShowError(false);
      setShowNotification(false);
      setIsLoading(false);
      logger.debug('bag after deletion', purchaseContext.promoInfo);
      const loyaltyRemovedEvent = new CustomEvent('loyalty:update:done');
      window.dispatchEvent(loyaltyRemovedEvent);
    },
    onError: (err) => {
      setIsLoading(false);
      logger.error(`ERR: PROMOTION_DELETE_MUTATION: ${JSON.stringify(err)}`);
    },
  });

  const handleRemovePromo = useCallback((event, code) => {
    event.preventDefault();
    setIsLoading(true);
    deletePromoMutation({
      variables: {
        promotionCode: code,
      },
    });
    trackAction('checkout_promo_remove_click', {
      data_text: 'promo removed',
      data_value: code,
      data_action: 'successful',
      message: '',
      ...purchaseContext?.orderTotals,
      cartBrands,
      kicIds,
    });
  }, [purchaseContext?.orderTotals, cartBrands, deletePromoMutation, kicIds]);

  const handleApplyPromo = useCallback((event, code) => {
    event.preventDefault();
    setIsLoading(true);
    updatePromoMutation({
      variables: {
        promotionCode: code,
      },
    });
    trackAction('checkout_promo_add_click', {
      data_text: 'promo added',
      data_value: code,
      data_action: 'successful',
      message: '',
      ...purchaseContext?.applyPromo?.orderTotals,
      cartBrands,
      kicIds,
    });
  }, [purchaseContext?.applyPromo?.orderTotals, cartBrands, kicIds, updatePromoMutation]);

  const isPromoRepudiation = purchaseContext?.repudiationData?.repudiationType === 'PROMO';
  const loyaltyRewards = coupons ? coupons?.map((codes) => {
    const discountTitle = codes?.offerHeader || '';
    const secondaryDiscountTitle = codes?.associatedPromoName || '';
    const loyaltyExp = tmntData?.loyaltyExp?.value.replace('{0}', codes?.couponExpiryDate);
    return (
      <div key={codes.couponCode}>
        {codes.couponApplied ? (
          <DiscountCard
            discountCardActions={{
              appliedIconBlock: (
                <IconBlock icon={<Icon icon="check" />}>
                  <Tmnt tmnt={tmntData?.loyaltyRedeemedTMNT} />
                </IconBlock>),
              removeLink:
            <Button
              brand="anf"
              isFullWidth
              value="promocode-submit"
              variant="borderless"
              classList="button ds-override"
              labelText={tmntData?.loyaltyUndoTMNT?.value}
              onClick={(evt) => handleRemovePromo(evt, codes.couponCode)}
            >
              <Tmnt tmnt={tmntData?.loyaltyUndoTMNT} />
            </Button>,
            }}
            discountCardContent={{
              discountDescription: loyaltyExp,
              discountDetails: (
                <ModalButtonWithTMNTQuery
                  tmntPairKey={codes?.exclusionsApplyTmntKey}
                  modalHeadingTmnt={tmntData?.loyaltyExclusionApplyTMNT}
                  buttonLabelTmnt={tmntData?.loyaltyExclusionApplyTMNT}
                />
              ),
              discountTitle: decodeHTMLEntities(discountTitle),
              // at present DS discountTitle accepts only String, hence passing associatedPromoName
              // but in future, we must pass html that would include offerHeader
              // and associatedPromoName
              discountSecondaryTitle: decodeHTMLEntities(secondaryDiscountTitle),
              graphicBadge:
            // <GraphicBadge state={codes?.tier}>{codes.formattedValue}</GraphicBadge>,
            <div className="graphic-badge" data-state={codes?.tier} aria-hidden="true">
              <div className="graphic-badge-inner">
                <span className="graphic-badge-value">{decodeHTMLEntities(codes?.formattedValue)}</span>
              </div>
            </div>,
            }}
            id={codes.couponCode}
            orientation="stacked"
            variant="badged"
          />
        ) : (
          <DiscountCard
            discountCardActions={{
              applyButton:
            <Button
              classList="redeem-button"
              isFullWidth
              labelText={tmntData?.loyaltyRedeemTMNT?.value}
              variant="secondary"
              onClick={(evt) => handleApplyPromo(evt, codes.couponCode)}
            >
              <Tmnt tmnt={tmntData?.loyaltyRedeemTMNT} />
            </Button>,
            }}
            discountCardContent={{
              discountDescription: loyaltyExp,
              discountDetails: (
                <ModalButtonWithTMNTQuery
                  tmntPairKey={codes?.exclusionsApplyTmntKey}
                  modalHeadingTmnt={tmntData?.loyaltyExclusionApplyTMNT}
                  buttonLabelTmnt={tmntData?.loyaltyExclusionApplyTMNT}
                />
              ),
              discountTitle: decodeHTMLEntities(discountTitle),
              // at present DS discountTitle accepts only String, hence passing associatedPromoName
              // but in future, we must pass html that would include offerHeader
              // and associatedPromoName
              discountSecondaryTitle: decodeHTMLEntities(secondaryDiscountTitle),
              graphicBadge:
            // <GraphicBadge state={codes?.tier}>{codes.formattedValue}</GraphicBadge>,
            <div className="graphic-badge" data-state={codes?.tier} aria-hidden="true">
              <div className="graphic-badge-inner">
                <span className="graphic-badge-value">{decodeHTMLEntities(codes?.formattedValue)}</span>
              </div>
            </div>,
            }}
            id={codes.couponCode}
            orientation="stacked"
            variant="badged"
          />
        )}
      </div>
    );
  }) : <div data-display="false" />;
  return (
    <div className="loyalty-rewards-section" data-testid="rewards-promos">
      <section className="rewards-promos">
        {
          loyaltyStatus !== 'crossborderuser'
            ? (
              <Accordion
                headingLevel={2}
                id="rewards-coupons-list"
                title={`${tmntData?.rewardsAndOffersTMNT?.value} (${totalCoupons})`}
                titleDescription={notification?.value ? <UserNotifications message={notification} className="user-notification" /> : null}
                variant="boxed"
                isExpanded={expanded}
                onClick={() => { setExpanded(!expanded); }}
              >
                <div>
                  <div className="estimated-points-section">
                    <div
                      className="earn-loyalty-details"
                      data-debug={[
                        !earnPointsTMNT?.value ? earnPointsTMNT?.key : '',
                        !estimatedPointsTMNT?.value ? estimatedPointsTMNT?.key : '',
                      ].join(',')}
                    >
                      {earnPointsTMNT?.value && (
                        <p className="earn-points-point-balance" data-property={earnPointsTMNT?.key}>
                          {earnPointsTMNT?.value}
                        </p>
                      )}
                      {loyaltyEstimatedPoints !== '0' && estimatedPointsTMNT?.value && (
                        <p className="earn-points-total-cash" data-property={estimatedPointsTMNT?.key}>
                          {' '}
                          {estimatedPointsTMNT?.value}
                          {' '}
                          <ModalButtonWithTMNTQuery
                            tmntPairKey={legalTermsTmntKey}
                            modalHeadingTmnt={tmntData?.loyaltyEstimateDetailsTMNT}
                            buttonLabelTmnt={tmntData?.loyaltyEstimateDetailsTMNT}
                          />
                        </p>
                      )}
                    </div>

                  </div>
                  <Loader variant="hazy" id="rewards" isActive={isloading} data-testid="rewards">
                    <div className="coupon-list">
                      {loyaltyRewards}
                      {showError ? purchaseContext?.statusMessages?.map((err) => (
                        <ErrorMessage key={err}>{err.message}</ErrorMessage>
                      )) : ''}
                      {(((showNotification
                        && purchaseContext?.repudiationData?.repudiationType === 'PROMOTION_REPUDIATION') || isPromoRepudiation)
                        && purchaseContext?.repudiationData?.errorMessage)
                        && (
                        <ErrorMessage>
                          {purchaseContext?.repudiationData?.errorMessage}
                        </ErrorMessage>
                        )}
                    </div>
                  </Loader>
                </div>
              </Accordion>
            )
            : (
              <div className="repudiation">
                <Tmnt tmnt={tmntData?.loyaltyCrossBorderWarning} />
              </div>
            )
        }
      </section>
    </div>
  );
}

RewardsPromos.defaultProps = {
  coupons: [],
  totalCoupons: 0,
  earnPointsTMNT: {
    key: 'GLB_EARN_POINTS_FROM_GOAL',
    value: '',
  },
  legalTermsTmntKey: '',
  estimatedPointsTMNT: {
    key: 'GLB_EARN_X_POINTS',
    value: '',
  },
  tmntData: PropTypes.instanceOf(Object),
  loyaltyStatus: '',
  page: '',
  purchaseContext: {},
  showNotification: false,
  notification: {},
};

RewardsPromos.propTypes = {
  coupons: PropTypes.instanceOf(Array),
  totalCoupons: PropTypes.number,
  earnPointsTMNT: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  legalTermsTmntKey: PropTypes.string,
  estimatedPointsTMNT: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  loyaltyEstimatedPoints: PropTypes.string.isRequired,
  tmntData: PropTypes.instanceOf(Object),
  loyaltyStatus: PropTypes.string,
  page: PropTypes.string,
  purchaseContext: PropTypes.instanceOf(Object),
  setPurchaseContext: PropTypes.func.isRequired,
  setShowDiscount: PropTypes.func.isRequired,
  showNotification: PropTypes.bool,
  setShowNotification: PropTypes.func.isRequired,
  notification: PropTypes.instanceOf(Object),
};
