import { getShippingAddress, getBillingAddress, cleanUpAddressValues } from '../../../tools/address';
import { buildCreditCard, isCreditCardInfoComplete, isSavedCardInfoComplete } from '../../../tools/creditCard';
import { encrypt } from '../../../tools/encryption/encryption';
import { getToken } from '../../../tools/pxp';

export async function prepareCreditCardData(formData, pxpConfig) {
  const cardPayments = [];
  if (formData?.payment?.cardPayments?.length) {
    const cardPayment = formData?.payment?.cardPayments[0];
    const creditCard = buildCreditCard(cardPayment);
    if (isCreditCardInfoComplete(creditCard)) {
      const encryptedCardNumber = encrypt({
        value: creditCard?.cardNumber,
        maxRetries: pxpConfig?.pxpEncryptRetries,
        convertToHex: true,
        exponent: pxpConfig?.publicEncryptionKey?.rsa?.exponent,
        modulus: pxpConfig?.publicEncryptionKey?.rsa?.modulus,
      });
      const pxpToken = await getToken(
        encryptedCardNumber,
        creditCard?.cardExpireMonth,
        creditCard?.cardExpireYear,
        pxpConfig,
      );
      cardPayments.push({
        ...creditCard,
        cardNumber: pxpToken ?? encryptedCardNumber,
        cardNumberType: pxpToken ? 'singleUseToken' : 'encryptedCardNumber',
        encryptionId: pxpConfig?.publicEncryptionKey?.id || '',
      });
    } else if (isSavedCardInfoComplete(cardPayment)) {
      cardPayments.push({
        ...cardPayment,
      });
    }
  }
  return cardPayments;
}

export async function preparePaymentData(formData, paymentType, pxpConfig) {
  let payment = formData?.payment ?? {};

  if (
    paymentType === 'credit card'
  ) {
    const cardPayments = await prepareCreditCardData(formData, pxpConfig);
    payment = { cardPayments };
  }
  return payment;
}

export default async function prepareFormData(formData, checkoutPageState = {}) {
  const {
    shippingSpeed,
    shippingAddress,
    orderContact,
    selectedPaymentType,
    pxpConfig,
  } = checkoutPageState;
  const payment = await preparePaymentData(formData, selectedPaymentType, pxpConfig);
  return {
    billingAddressSameAsShipping: formData?.billingAddressSameAsShipping ?? false,
    shippingAddressSameAsBilling: formData?.shippingAddressSameAsBilling ?? false,
    estimatedDeliveryDate: formData?.estimatedDeliveryDate ?? '',
    estimatedShipDate: formData?.estimatedShipDate ?? '',
    items: formData?.items ?? [],
    payment,
    shippingAddress: cleanUpAddressValues(
      formData?.shippingAddress ?? getShippingAddress(
        formData,
        shippingSpeed,
        shippingAddress,
        orderContact,
      ),
    ),
    billingAddress: cleanUpAddressValues(
      formData?.billingAddress ?? getBillingAddress(formData, shippingSpeed),
    ),
    shipModeId: formData?.shipModeId ?? '',
    signifydSessionId: formData?.signifydSessionId ?? '',
    smsOptIn: formData?.smsOptIn ?? false,
    valueTierFlag: formData?.valueTierFlag ?? '',
    marketingInfo: formData?.marketingInfo ?? {},
  };
}
