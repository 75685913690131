import React from 'react';
import { PaymentBlock } from 'anf-core-react';
import PropTypes from 'prop-types';
import MaskedGiftCard from '../../Common/GiftCards/MaskedGiftCard';
import decodeHTMLEntities from '../../../tools/decodeHtml';

export default function PaymentInfo({
  iconCode, paymentLabel, payPalPaymentEmail, maskedCardNumber, amountFmt, textFor,
}) {
  if (iconCode === 'gift-card') {
    return (
      <div className="non-card-payments" data-testid="non-card-payments">
        <PaymentBlock paymentIcon={(
          <span className="payment-icon" role="img" data-icon-name={iconCode} aria-label={paymentLabel} />
  )}
        >
          <div className="gift-card-details">
            <div className="gift-card-number">
              <MaskedGiftCard giftcard={{ maskedNumber: maskedCardNumber }} />
            </div>
            <div className="gift-card-amount-used">
              <p>
                {`${textFor?.giftCardAmountUsed?.value || ''} ${decodeHTMLEntities(amountFmt)}`}
              </p>
            </div>
          </div>
        </PaymentBlock>
      </div>
    );
  }
  return (
    <div className="non-card-payments" data-testid="non-card-payments">
      <PaymentBlock paymentIcon={(
        <span className="payment-icon" role="img" data-icon-name={iconCode} aria-label={paymentLabel} />
  )}
      >
        <span>{paymentLabel}</span>
        {iconCode === 'paypal' && <p className="payment-email">{payPalPaymentEmail}</p>}

      </PaymentBlock>
    </div>
  );
}

PaymentInfo.defaultProps = {
  iconCode: '',
  paymentLabel: '',
  payPalPaymentEmail: '',
  maskedCardNumber: '',
  amountFmt: '',
  textFor: {},
};

PaymentInfo.propTypes = {
  iconCode: PropTypes.string,
  paymentLabel: PropTypes.string,
  payPalPaymentEmail: PropTypes.string,
  maskedCardNumber: PropTypes.string,
  amountFmt: PropTypes.string,
  textFor: PropTypes.instanceOf(Object),
};
