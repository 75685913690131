import {
  useState, useEffect, useCallback, useRef,
} from 'react';

export default function usePaypalExpress(
  payPalMerchantId,
  payPalEnvironment,
  payPalURL,
  payPalInContextScriptSrc,
) {
  const paypalInitialized = useRef(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const isPaypalAvaliable = useCallback(() => !!(window?.paypal && window?.paypal?.checkout), []);

  const paypalSetup = useCallback(() => {
    if (isPaypalAvaliable() && !paypalInitialized.current) {
      window.paypal.checkout.setup(payPalMerchantId, {
        environment: payPalEnvironment,
      });
      paypalInitialized.current = true;
    }
  }, [isPaypalAvaliable, payPalEnvironment, payPalMerchantId]);

  const openPaypalPopupWindow = (payPalPaymentURL = '') => {
    // set up paypal with merchant id and env
    if (!paypalInitialized.current) {
      paypalSetup(payPalMerchantId, payPalEnvironment);
    }
    // open popup
    if (isPaypalAvaliable()) {
      window.paypal.checkout.initXO();
      window.paypal.checkout.startFlow(payPalPaymentURL || payPalURL);
    }
  };

  const loadPaypalJS = () => {
    const existingScripts = document.getElementById('paypal-js');
    if (!existingScripts) {
      const script = document.createElement('script');
      script.id = 'paypal-js';
      script.onload = () => { setScriptLoaded(true); };
      script.src = payPalInContextScriptSrc || 'https://www.paypalobjects.com/api/checkout.js';
      script.defer = true; // Added defer attribute
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    if (scriptLoaded && !paypalInitialized.current) {
      paypalSetup();
    }
  }, [scriptLoaded, paypalSetup]);

  return {
    isPaypalAvaliable,
    paypalSetup,
    openPaypalPopupWindow,
    loadPaypalJS,
  };
}
