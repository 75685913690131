/**
 * The purpose of this to resolve SSR issue with some code that relies on window being defined.
 */

let doc;

if (typeof document !== 'undefined') {
  doc = document;
} else {
  doc = {}; // Fallback for SSR
}

const $document = doc;

export default $document;
