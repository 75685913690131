import React from 'react';
import PropTypes from 'prop-types';

function MapMarkerImage({ alt, src, ...props }) {
  return (
    <img
      className="checkout-map-marker-img"
      data-testid="checkout-map-marker-img"
      alt={alt}
      src={src}
      width={80}
      height={80}
      {...props}
    />
  );
}
MapMarkerImage.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

function MapMarkerLabel({ children, ...props }) {
  return (
    <div
      className="checkout-map-marker-label"
      data-testid="checkout-map-marker-label"
      {...props}
    >
      {children}
    </div>
  );
}
MapMarkerLabel.propTypes = {
  children: PropTypes.string.isRequired,
};

const mapMarkerDefaultProps = {
  label: undefined,
  imgSrc: undefined,
};

const mapMarkerPropTypes = {
  label: PropTypes.string,
  imgSrc: PropTypes.string,
  onMarkerClick: PropTypes.func.isRequired,
};

export default function MapMarker({
  label,
  imgSrc,
  onMarkerClick,
}) {
  return imgSrc
    ? (
      <MapMarkerImage
        alt={label}
        src={imgSrc}
        onClick={onMarkerClick}
      />
    )
    : (
      <MapMarkerLabel
        onClick={onMarkerClick}
      >
        {label}
      </MapMarkerLabel>
    );
}
MapMarker.defaultProps = mapMarkerDefaultProps;
MapMarker.propTypes = mapMarkerPropTypes;
