import React, { useContext, useState } from 'react';
import { Accordion } from 'anf-core-react';
import OrderConfirmationContext from '../../../context/OrderConfirmationContext';
import Tmnt from '../../Tmnt/Tmnt';

function TrackYourOrder() {
  const { orderConfirmationData } = useContext(OrderConfirmationContext);
  const {
    textFor,
  } = orderConfirmationData;
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className="cod-track-your-order" data-testid="cod-track-your-order">
      <div className="track-your-order-heading" data-testid="heading">
        <h1>
          <Tmnt tmnt={textFor?.trackYourOrder} />
        </h1>
      </div>
      <div className="order-tracking-details" data-testid="order-tracking-steps">
        <div className="track-order">
          <span aria-label="{textFor?.stepOne}" className="step-circle" data-state="active" data-theme="">
            <span className="step-inner" data-text="1" />
          </span>
          <p className="track-order-explained">
            <Tmnt tmnt={textFor?.trackYourOrderExplained} isHtml />
          </p>
        </div>
        <div className="hr" />
        <div className="order-delivery">
          <span aria-label="{textFor?.stepOne}" className="step-circle">
            <span className="step-inner" data-text="1" />
          </span>
          <p>
            <Tmnt tmnt={textFor?.dayOfDeliveryStep1} isHtml />
          </p>
        </div>
        <div className="order-delivery">
          <span aria-label="{textFor?.stepTwo}" className="step-circle step-two">
            <span className="step-inner" data-text="2" />
          </span>
          <p>
            <Tmnt tmnt={textFor?.dayOfDeliveryStep2} isHtml />
          </p>
        </div>
        <div className="order-delivery">
          <span aria-label="{textFor?.stepThree}" className="step-circle">
            <span className="step-inner" data-text="3" />
          </span>
          <p>
            <Tmnt tmnt={textFor?.dayOfDeliveryStep3} isHtml />
          </p>
        </div>
        <Accordion
          headingLevel={2}
          title={textFor?.returns?.value}
          id="returns"
          variant="bordered"
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div className="returns-accordion"><Tmnt tmnt={textFor?.returnsExplained} isHtml /></div>
        </Accordion>
      </div>
    </div>
  );
}

export default TrackYourOrder;
