import React, { useContext, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Icon, IconBlock } from 'anf-core-react';
import IconHeading from '../../Common/IconHeading/IconHeading';
import OrderConfirmationContext from '../../../context/OrderConfirmationContext';
import Tmnt from '../../Tmnt/Tmnt';
import SignInToAssociatePoints from './SignInToAssociatePoints';
import AppDownloadLink from './AppDownloadLink';
import CreateAccountWrapper from '../CreateAccount/CreateAccountWrapper';

const ORDERCONFIRMATIONPAGE_DOES_USER_EXIST = gql`
  query DoesUserExist($email: String!) {
    userExists(email: $email)
  }
`;

function PointsReward() {
  const { orderConfirmationData } = useContext(OrderConfirmationContext);
  const [userExists, setUserExists] = useState(false);
  const [userCreatedAccount, setUserCreatedAccount] = useState(false);

  const {
    orderContact, pointsReward, userData,
  } = orderConfirmationData;

  useQuery(ORDERCONFIRMATIONPAGE_DOES_USER_EXIST, {
    variables: { email: orderContact?.email },
    onCompleted: (data) => {
      setUserExists(data.userExists);
    },
  });

  const { isLoggedIn = false } = userData;

  const pointsInfoState = useMemo(() => {
    if (userCreatedAccount) return 'ACCOUNT_CREATED';

    if (userExists) {
      return isLoggedIn ? 'LOGGED_IN' : 'SIGN_IN';
    }

    return 'CREATE_ACCOUNT';
  }, [isLoggedIn, userExists, userCreatedAccount]);

  return (
    <div className="ocn-points-reward" data-testid="points-to-account">
      {
        pointsReward?.displayPointsReward && (
          <div className="ocn-points-reward__header">
            <IconHeading
              image={pointsReward?.pointsRewardImage}
              variant="large"
              layout="vertical"
            >
              <h2>
                {['LOGGED_IN', 'SIGN_IN', 'CREATE_ACCOUNT'].includes(pointsInfoState) ? (
                  <Tmnt tmnt={pointsReward?.pointsRewardHeader} />
                ) : (
                  <Tmnt tmnt={pointsReward?.welcomeHeader} />
                )}
              </h2>
            </IconHeading>
          </div>
        )
      }

      {pointsInfoState === 'LOGGED_IN' && (
        <>
          {pointsReward?.displayPointsReward && (
            <div className="ocn-points-reward__description">
              <p>
                <Tmnt tmnt={pointsReward?.pointsRewardDesc} />
              </p>
            </div>
          )}
          <AppDownloadLink />
        </>
      )}

      {pointsInfoState === 'SIGN_IN' && <SignInToAssociatePoints />}

      {pointsInfoState === 'CREATE_ACCOUNT' && (
        <>
          {
            pointsReward?.displayPointsReward ? (
              <div className="ocn-points-reward__description create-account__header">
                <hr />
                <p>
                  <Tmnt
                    className="create-account__header-text"
                    tmnt={pointsReward?.createAccountDesc}
                  />
                </p>
                <hr />
              </div>
            ) : (
              <div className="ocn-points-reward__create-account h2">
                <IconBlock icon={<Icon icon={pointsReward?.pointsRewardImage} size={20} labelText={pointsReward?.createAccountDesc?.key || 'create-account'} />}>
                  <Tmnt
                    tmnt={pointsReward?.createAccountDesc}
                  />
                </IconBlock>
              </div>
            )
          }
          <CreateAccountWrapper onSuccess={() => setUserCreatedAccount(true)} />
        </>
      )}

      {pointsInfoState === 'ACCOUNT_CREATED' && (
        <>
          {pointsReward?.displayPointsReward && (
            <div className="ocn-points-reward__description create-account__header">
              <Tmnt className="create-account__header-text" tmnt={pointsReward?.appDownloadDesc} />
            </div>
          )}
          <AppDownloadLink />
        </>
      )}
    </div>
  );
}

export default PointsReward;
