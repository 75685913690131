import {
  gql,
} from '@apollo/client';

const REMOVE_BAG_ITEM_MUTATION = gql`
mutation RemoveBagItem($orderItemId: String!) {
  removeBagItem(orderItemId: $orderItemId) {
    bagItems {
      hasGiftCard
      hasOnlyGiftCard
      items {
        item {
          headers {
            badgeStatusMessage {
              key
              value
            }
            headerMessage
            estimatedShipDate
            shouldDisplayAsbadge
            attached
            fullWidth
            textAlign
            variant
          }
          footer {
            bogoMessage
          }
          image {
            altText
            imageSrc
            imageId
            classList
          }
          imageOverlay {
            productUrl
            zoomIn
          }
          secondaryContent {
            buttonGroup
          }
          productContent {
            gender
            name
            size
            brand
            shortSku
            longSku
            orderItemId
            productId
            collectionId
            freeGift
            seq
            faceOut
            faceoutImage
            color
            notWrappable
            productPrice {
              description
              originalPrice
              discountPrice
              discountText
              variant
              priceFlag
              original
              discount
              originalUSD
              discountUSD
            }
            promotions {
              shortDesc
              promotionType
            }
            deliveryDate
            toEmail
            editRemoveButtonVariant
            editRemoveButton
            hasEdit
            hasRemove
            kicId
            isFinalSale
            itemShortDesc
            quantity
          }
          socialProof {
            message
            type
          }
        }
        giftBox {
          isWrapped
          giftBoxMessage
        }
      }
    }
    orderTotals {
      totalItemsInBag
      cartTotalAmount
      cartTotalAmountFmt
      cartTotalAmountUSD
      cartPayableAmount
      subTotalFmt
      promoAmount
      promoAmountFmt
      shippingHandlingChargeFmt
      totalGiftBoxes
      totalGiftBoxesAmount
      subTotal
      orderId
      isGiftReceiptChecked
      displayFreeShippingText
      displayGiftReceiptText
      estimatedTaxAmount
      estimatedTaxAmountFmt
      estimatedTaxAmountUSD
      charityRoundUpFmt
      includesConsumptionTax
      includesGst
      includesVat
      grandTotal
      grandTotalFmt
      currency
      subTotalUSD
      grandTotalUSD
      cartTotalAmountUSD
      totalAdjustmentUSD
      shippingCharge
      shippingChargeUSD
      shippingChargeFmt
    }
    freeShippingProgressInfo {
      isVisible
      amountToFreeShipping
      amountToFreeShippingFmt
      preLabel
      postLabel
      progressValue
      maxProgress
      minProgress
    }
    promoInfo {
      promoCompId
      promotionCode
      promotionKey
      longDesc
      isShippingPromo
      isRewardPromo
      isPromoBadging
      isImplicitPromo
      amount
      amountFmt
      orderAdjustmentId
      shortDesc
      promotionType
    }
    userData {
      statusCode
      success
      statusMessages {
        code
        key
        message
      }
      hasSignInButton
      hasLoyaltyEnabled
      isRemembered
      isLoggedIn
      loggedInData {
        registrationDate
        userName
        memberSince
      }
      iconImageUrl
      loyaltyInfo {
        pointBalance
        nextRewardPointsThreshold
        formattedNextRewardValue
        accountStatus
        loyaltyTierInfo {
          loyaltyTier
          actionNeededForNextTier
          formattedActionNeededForNextTier
          spendByDateForNextTier
          formattedLoyaltyTierExpirationDate
        }
        currencyUntilNextRewardValue {
          key
          value
        }
        earnPointsTMNT {
          key
          value
        }
        loyaltyEstimatedPoints
        estimatedPointsTMNT {
          key
          value
        }
      }
      displayIconImage
      userNotifications {
        key
        value
      }
    }
    repudiationData {
      errorMessage
      itemsToRemove
      repudiationType
      checkoutUrl
      hasCheckoutButton
      continueInCurrency
      repudiationProducts {
        hasGiftCard
        items {
          item {
            headers {
              badgeStatusMessage {
                key
                value
              }
              headerMessage
              shouldDisplayAsbadge
              attached
              fullWidth
              textAlign
              variant
            }
            footer {
              bogoMessage
            }
            image {
              altText
              imageSrc
              imageId
              classList
            }
            imageOverlay {
              productUrl
              zoomIn
            }
            secondaryContent {
              buttonGroup
            }
            productContent {
              gender
              name
              size
              brand
              shortSku
              longSku
              orderItemId
              productId
              collectionId
              freeGift
              seq
              faceOut
              faceoutImage
              color
              productPrice {
                description
                originalPrice
                discountPrice
                discountText
                variant
                priceFlag
                original
                discount
              }
              promotions {
                shortDesc
                promotionType
              }
              deliveryDate
              toEmail
              editRemoveButtonVariant
              editRemoveButton
              hasEdit
              hasRemove
              isFinalSale
              itemShortDesc
              quantity
            }
          }
          giftBox {
            isWrapped
            giftBoxMessage
          }
        }
      }
    }
    statusCode
    success
    statusMessages {
      code
      key
      message
    }
  }
}`;

const MINIBAG_DATA_QUERY = gql`
  query FetchMiniBag {
    bagItems {
      items {
        item {
          headers {
            badgeStatusMessage {
              key
              value
            }
            headerMessage
            estimatedShipDate
            shouldDisplayAsbadge
            attached
            fullWidth
            textAlign
            variant
          }
          footer {
            bogoMessage
          }
          image {
            altText
            imageSrc
            imageId
            classList
          }
          imageOverlay {
            productUrl
            zoomIn
          }
          secondaryContent {
            buttonGroup
          }
          productContent {
            gender
            name
            size
            brand
            shortSku
            longSku
            orderItemId
            productId
            collectionId
            freeGift
            seq
            faceOut
            faceoutImage
            color
            productPrice {
              description
              originalPrice
              discountPrice
              discountText
              variant
              priceFlag
              original
              discount
              originalUSD
              discountUSD
            }
            deliveryDate
            toEmail
            editRemoveButtonVariant
            editRemoveButton
            hasEdit
            hasRemove
            kicId
            itemShortDesc
            quantity
          }
            socialProof {
            message
            type
          }
        }
        giftBox {
          isWrapped
          giftBoxMessage
        }
      }
    }
    userNotificationsKey
    orderTotals {
      subTotalFmt
    }
    checkoutButton {
      checkoutUrl
      variant
    }
    viewBagButton {
      bagUrl
      variant
    }
    freeShippingProgressInfo {
      isVisible
      amountToFreeShipping
      amountToFreeShippingFmt
      preLabel
      postLabel
      progressValue
      maxProgress
      minProgress
    }
    textFor {
      editButton: pair (pairKey: "editButton") {...values}
      removeButton: pair (pairKey: "removeButton") {...values}
      buyAgainButton: pair (pairKey: "buyAgainButton") {...values}
      returnRefundButton: pair (pairKey: "returnRefundButton") {...values}
      exchangeButton: pair (pairKey: "exchangeButton") {...values}
      checkoutBtnTMNTValue: pair (pairKey: "checkoutButtonText") {...values}
      freeShippingAmountTMNTValue: pair (pairKey: "freeShippingAmountText") {...values}
      freeShippingEarnedTMNTValue: pair (pairKey: "freeShippingEarnedText") {...values}
      subtotal: pair (pairKey: "subtotal") {...values}
      viewBag: pair (pairKey: "viewBag") {...values}
      shoppingBag: pair (pairKey: "shoppingBag") {...values}
      miniBagHeader: pair (pairKey: "miniBagHeader") {...values}
      itemsLabel: pair (pairKey: "itemsLabel") {...values}
      itemLabel: pair (pairKey: "item") {...values}
      emptyBagText: pair (pairKey: "emptyBagText") {...values}
      member: pair (pairKey: "member") {...values}
      clearance: pair (pairKey: "clearance") {...values}
      productPriceDescription: pair (pairKey: "productPriceDescription") {...values}
      seeMoreImages: pair (pairKey: "seeMoreImages") {...values}
      takingYouToCheckout: pair (pairKey: "takingYouToCheckout") {...values}
      sendTo: pair (pairKey: "sendTo") {...values}
      sendOn: pair (pairKey: "sendOn") {...values}
      itemTMNTValue: pair (pairKey: "item") {...values}
      closeButtonTMNTValue: pair (pairKey: "closeButton") {...values}
      itemsTMNTValue: pair (pairKey: "items") {...values}
    }
  }
  fragment values on TextPair {
    key
    value
  }
`;

export {
  REMOVE_BAG_ITEM_MUTATION,
  MINIBAG_DATA_QUERY,
};
