import React from 'react';
import SwitchTestProvider from '../../SwitchTestProvider/SwitchTestProvider';
import { MINI_BAG_SWITCHES } from '../../../tools/constants';
import MiniBagWithIcon from '../MiniBagWithIcon/MiniBagWithIcon';

export default function MiniBagWrapper() {
  return (
    <SwitchTestProvider keys={MINI_BAG_SWITCHES}>
      <MiniBagWithIcon />
    </SwitchTestProvider>
  );
}
