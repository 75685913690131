import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import trackAction from '../../../tools/analytics';
import Tmnt from '../../Tmnt/Tmnt';
import SwitchTestContext from '../../../context/SwitchTestContext';

function SignInJoinButton({ tmntData, isEmptyBag }) {
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const hasBagMfeV2 = digitalData && digitalData['chk-has-bag-mfe-v2'];
  const handleLoginClick = useCallback(() => {
    trackAction('bag_signin_click', {
      data_text: 'sign in or join',
      data_action: 'open',
      event_type: 'click',
    });
  }, []);

  const handleClick = (event) => {
    handleLoginClick(event);
    event.preventDefault();
    // Dispatch the custom event
    const customEvent = new CustomEvent('authentication-modal:open', {
      detail: { isSignIn: true },
    });
    document.dispatchEvent(customEvent);
  };

  const handleJoinClick = (event) => {
    handleLoginClick(event);
    event.preventDefault();
    // Dispatch the custom event
    const customEvent = new CustomEvent('authentication-modal:open', {
      detail: { isSignIn: false },
    });
    document.dispatchEvent(customEvent);
  };

  const getSignInText = () => {
    if (hasBagMfeV2) {
      return <Tmnt tmnt={tmntData?.emptyBagSignInButton} isHtml />;
    }

    return !isEmptyBag ? (<Tmnt tmnt={tmntData?.signIn} isHtml />
    ) : (
      <Tmnt tmnt={tmntData?.emptyBagSignInButton} isHtml />
    );
  };

  return (
    <div className="sign-in-join">
      <button
        data-testid="sign-in-trigger-button"
        className="button ds-override js-open-modal"
        onClick={handleClick}
        data-brand=""
        type="button"
        data-modal="authentication-modal"
        data-is-user-login="true"
        data-theme=""
        data-variant={hasBagMfeV2 ? 'tertiary-dark' : 'secondary-button'}
      >
        <span>
          <span className="screen-reader-text" />
          {getSignInText()}
          <span className="screen-reader-text" />
        </span>
      </button>
      {hasBagMfeV2 && (
      <button
        data-testid="join-trigger-button"
        className="button ds-override js-open-modal join-button"
        onClick={handleJoinClick}
        data-brand=""
        type="button"
        data-modal="authentication-modal"
        data-is-user-login="true"
        data-theme=""
        data-variant="tertiary-dark"
      >
        <span>
          <span className="screen-reader-text" />
          <Tmnt tmnt={tmntData?.joinButton} isHtml />
          <span className="screen-reader-text" />
        </span>
      </button>
      )}
    </div>
  );
}

SignInJoinButton.propTypes = {
  tmntData: PropTypes.instanceOf(Object),
  isEmptyBag: PropTypes.bool,
};

SignInJoinButton.defaultProps = {
  tmntData: {},
  isEmptyBag: false,
};

export default SignInJoinButton;
