import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import CheckoutSelectedStore from './CheckoutSelectedStore';

export default function CheckoutSelectedStorePortal({ id }) {
  if (typeof document !== 'undefined' && !!document.getElementById(id)) {
    return ReactDOM.createPortal(
      <CheckoutSelectedStore />,
      document.getElementById(id),
    );
  }
  return null;
}

CheckoutSelectedStorePortal.defaultProps = {
  id: '',
};

CheckoutSelectedStorePortal.propTypes = {
  id: PropTypes.string,
};
