import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import NOTIFICATION_DATA_QUERY from '../../../gql/notification.gql';
import SwitchTestContext from '../../../context/SwitchTestContext';

function UserNotifications({
  message, notificationKey, className, onNotificationCheck,
}) {
  const { digitalData } = useContext(SwitchTestContext);
  const [notification, setNotification] = useState(message?.value || null);
  const hasNotification = digitalData && digitalData['chk-has-two-x-birthday-notification'];

  const { data: notificationData } = useQuery(NOTIFICATION_DATA_QUERY, {
    variables: { notificationKey },
    skip: !!message?.value || !notificationKey,
    context: { batch: true },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (notificationData?.userData?.userNotifications?.value && notificationKey) {
      const fetchedNotification = notificationData.userData.userNotifications;
      setNotification(fetchedNotification.value);
      onNotificationCheck(fetchedNotification);
    }
  }, [notificationData, notificationKey, onNotificationCheck]);

  if (!notification || !hasNotification) {
    return null;
  }

  return (
    <div
      data-property="GLB_BIRTHDAY_NOTIFICATION_MINIBAG"
      className={`notification-banner ${className}`}
    >
      {notification}
    </div>
  );
}

UserNotifications.propTypes = {
  message: PropTypes.shape({
    value: PropTypes.string,
  }),
  notificationKey: PropTypes.string,
  className: PropTypes.string,
  onNotificationCheck: PropTypes.func,
};

UserNotifications.defaultProps = {
  message: {},
  notificationKey: null,
  className: '',
  onNotificationCheck: () => {},
};

export default UserNotifications;
