import $window from './window';

export function getBrandContext(brand = 'anf') {
  return ({
    anf: 'anf',
    kids: 'anf',
    hol: 'hol',
    gh: 'hol',
    st: 'hol',
  })[brand] || 'anf';
}

export const sha256 = (text) => new Promise((resolve, reject) => {
  // Check if the crypto API is available
  if (typeof crypto !== 'undefined' && crypto.subtle) {
    const msgBuffer = new TextEncoder().encode(text);

    crypto?.subtle?.digest('SHA-256', msgBuffer)
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
        resolve(hashHex);
      })
      .catch((err) => {
        reject(err);
      });
  } else {
    reject(new Error('Crypto API is not available.'));
  }
});

export const setHashedEmailForAnalytics = async (email) => {
  if (!$window.digitalData || !email || email.trim() === '') return;
  $window.digitalData.set('user.hashedEmail', await sha256(email));
};

export function parseJSON(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return undefined;
  }
}

export function parseJSONObject(value) {
  const object = parseJSON(value);
  if (typeof object === 'object' && object !== null) {
    return object;
  }
  return {};
}
