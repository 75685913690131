import {
  React, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import useScript from '../../../Helpers/useScript';
import { getStateForCountry } from '../../../../tools/address';

// this will prepare the data to be sent to CRS
export function processGooglePayEvent(eventDetail, countriesConfig, useStateAsCountrty) {
  const {
    transactionAccountId,
    clientSystemTransactionId,
    billingAddress,
    email,
    cryptogram,
    eciIndicator,
  } = eventDetail;
  return {
    payment: {
      googlePayPayment: {
        googleTransactionId: transactionAccountId ?? '',
        clientTransactionId: clientSystemTransactionId ?? '',
        cryptogram: cryptogram ?? '',
        eciIndicator: eciIndicator ?? '',
      },
    },
    billingAddressSameAsShipping: false,
    billingAddress: {
      address1: billingAddress?.address1 ?? '',
      address2: billingAddress?.address2 ?? '',
      city: billingAddress?.locality ?? '',
      country: billingAddress?.countryCode ?? '',
      email: email ?? '',
      firstName: billingAddress?.name.split(' ')[0] ?? '',
      lastName: billingAddress?.name.split(' ')[1] ?? '',
      phone: billingAddress?.phoneNumber ?? '',
      poBox: false,
      postalCode: billingAddress?.postalCode ?? '',
      state: useStateAsCountrty ? getStateForCountry(
        billingAddress?.countryCode,
        billingAddress?.administrativeArea,
        countriesConfig,
      ) : billingAddress?.administrativeArea ?? '',
    },
  };
}

export default function GooglePay({
  googlePayConfig, orderTotals, shippingAddress, countriesConfig, useStateAsCountrty,
  googlePayURLOverride,
}) {
  const urlSource = googlePayURLOverride?.url ? 'override' : 'config';
  // useScript will load gp.js from pxp on demand
  const [googlePayPXPScript] = useScript({
    id: 'google-pay-pxp-script',
    src: googlePayURLOverride?.url || googlePayConfig?.pxpScriptURL || '',
    attrs: {
      'data-namespace': 'google-pay-pxp-script',
    },
    hasCacheBusting: true,
  });
  const [googlepPayReady, setGooglePayReady] = useState(false);

  // this will listen to PXP event for Payment Success and Error
  useEffect(() => {
    function handleGooglePayPaymentResult(event) {
      // Merchant calls get payment account
      const cartAPIReadyData = processGooglePayEvent(
        event.detail,
        countriesConfig,
        useStateAsCountrty,
      );
      const successEvent = new CustomEvent('googlePay:success', {
        detail: cartAPIReadyData,
      });
      window.dispatchEvent(successEvent);
      // setting this to false so that we can re-initialize google pay
      setGooglePayReady(false);
    }

    function handleGooglePayPaymentError(event) {
      // trigger event for CRS to handle this error
      const errorEvent = new CustomEvent('googlePay:error', {
        detail: event.detail,
      });
      window.dispatchEvent(errorEvent);
      // setting this to false so that we can re-initialize google pay
      setGooglePayReady(false);
    }

    window.addEventListener('googlePay:handlePaymentResult', handleGooglePayPaymentResult);
    window.addEventListener('googlePay:handlePaymentError', handleGooglePayPaymentError);

    return () => {
      window.removeEventListener('googlePay:handlePaymentResult', handleGooglePayPaymentResult);
      window.removeEventListener('googlePay:handlePaymentError', handleGooglePayPaymentError);
    };
  }, [countriesConfig, useStateAsCountrty]);

  useEffect(() => {
    if (googlePayPXPScript.loaded) {
      // generate a unique clientSystemTransactionId using order id
      const clientSystemTransactionId = `${orderTotals?.orderId}-${Date.now()}`;
      const optionalParams = {
        emailRequired: true,
        billingAddressRequired: true, // Set to true to require billing address
        // You can add more optional parameters here if needed
        billingAddressParameters: {
          format: 'FULL',
          phoneNumberRequired: true,
        },
      };
      // adding this rule since this function will be a global function
      // loaded from gp.js hosted by pxp
      // eslint-disable-next-line no-undef
      runGooglePay(
        googlePayConfig?.mid,
        googlePayConfig?.sid,
        clientSystemTransactionId,
        googlePayConfig?.allowedMethods,
        googlePayConfig?.allowedCardNetworks,
        shippingAddress?.country,
        orderTotals?.currency,
        googlePayConfig?.amountStatus,
        orderTotals?.grandTotal?.toString(),
        googlePayConfig?.profile,
        googlePayConfig?.publicKeyApi,
        googlePayConfig?.useGooglePayButton,
        googlePayConfig?.merchantNo,
        googlePayConfig?.merchantName,
        optionalParams,
      );
    }
  }, [
    googlePayPXPScript?.loaded,
    googlePayConfig?.mid,
    googlePayConfig?.sid,
    googlePayConfig?.allowedMethods,
    googlePayConfig?.allowedCardNetworks,
    googlePayConfig?.amountStatus,
    googlePayConfig?.profile,
    googlePayConfig?.publicKeyApi,
    googlePayConfig?.useGooglePayButton,
    googlePayConfig?.merchantNo,
    googlePayConfig?.merchantName,
    orderTotals?.orderId,
    orderTotals?.currency,
    orderTotals?.grandTotal,
    shippingAddress?.country,
  ]);

  function handleGooglePayReady() {
    // PXP will let us know that Google Pay is ready
    setGooglePayReady(true);

    // trigger event for CRS to handle this
    const googlePayReadyEvent = new Event('mfe:googlePay:ready');
    window.dispatchEvent(googlePayReadyEvent);
  }

  window.handleGooglePayReady = handleGooglePayReady;

  // render an empty div and reflect the state of google pay
  return <div id="mfe-google-pay" data-ready={googlepPayReady} data-testid="mfe-google-pay" data-url-source={urlSource} />;
}

// props validation
GooglePay.propTypes = {
  googlePayConfig: PropTypes.shape({
    mid: PropTypes.string,
    sid: PropTypes.string,
    allowedMethods: PropTypes.arrayOf(PropTypes.string),
    allowedCardNetworks: PropTypes.arrayOf(PropTypes.string),
    amountStatus: PropTypes.string,
    profile: PropTypes.string,
    publicKeyApi: PropTypes.string,
    useGooglePayButton: PropTypes.bool,
    merchantNo: PropTypes.string,
    merchantName: PropTypes.string,
    pxpScriptURL: PropTypes.string,
  }).isRequired,
  orderTotals: PropTypes.shape({
    orderId: PropTypes.string,
    grandTotal: PropTypes.string,
    currency: PropTypes.string,
  }).isRequired,
  shippingAddress: PropTypes.shape({
    country: PropTypes.string,
  }).isRequired,
  // countries config is array of objects
  countriesConfig: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      addressFieldVisibility: PropTypes.shape({
        prefecture: PropTypes.bool,
        province: PropTypes.bool,
        region: PropTypes.bool,
        state: PropTypes.bool,
        zone: PropTypes.bool,
      }),
    }),
  ).isRequired,
  useStateAsCountrty: PropTypes.bool.isRequired,
  googlePayURLOverride: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};
