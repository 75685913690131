import React, {
  useState, useCallback, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Disclosure,
  Dialog,
} from 'anf-core-react';
import BagList from '../../Common/BagList/BagList';
import OrderTotals from '../../Common/OrderTotals/OrderTotals';
import { STICKY_SUMMARY_HEADER_HEIGHT, STICKY_SUMMARY_CONTAINER_CLASS } from '../../../tools/constants';
import decodeHTMLEntities from '../../../tools/decodeHtml';
import $window from '../../../tools/window';

function StickyBagSummary({
  bagItems, tmntData, orderTotals, config, shipMethod, giftCards, vatDetails, isAppWebView,
}) {
  const [openModal, setOpenModal] = useState(false);

  const stickyBagSummaryContainerRef = useRef(null);
  const stickyBagSummaryIdentityRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const stickyContainer = stickyBagSummaryContainerRef.current;
      const stickyIdentity = stickyBagSummaryIdentityRef.current;
      // 55px is the default height of the header
      const headerHeight = document.querySelector(STICKY_SUMMARY_CONTAINER_CLASS)
        ? document.querySelector(STICKY_SUMMARY_CONTAINER_CLASS)?.offsetHeight
        : STICKY_SUMMARY_HEADER_HEIGHT;
      if (stickyContainer && stickyIdentity) {
        const identityTop = stickyIdentity.getBoundingClientRect().top;
        if (identityTop <= headerHeight && !isSticky) {
          setIsSticky(true);
        } else if ((identityTop > headerHeight)) {
          setIsSticky(false);
        }
      }
    };
    handleScroll();

    // attaching window scroll event listener to handle stickiness
    $window.addEventListener('scroll', handleScroll);
    return () => {
      // de-attaching window scroll event listener to handle stickiness
      $window.removeEventListener('scroll', handleScroll);
    };
  }, [isSticky]);

  return (
    <>
      <div ref={stickyBagSummaryIdentityRef} data-testid="sticky-bag-summary-identity" />
      <div className={`sticky-bag-summary ${isSticky && !isAppWebView ? 'sb-sticky' : ''}`} ref={stickyBagSummaryContainerRef} data-testid="sticky-bag-summary">
        {orderTotals && orderTotals?.totalItemsInBag && (
          <>
            <Disclosure
              id="checkout-sticky-bag-summary-disclosure"
              headingPrefix={`${tmntData?.yourBag?.value} ( ${orderTotals?.totalItemsInBag} Items )`}
              label={decodeHTMLEntities(orderTotals?.grandTotalFmt)}
              onClick={() => setOpenModal(!openModal)}
              isExpanded={openModal}
            >
              <span> </span>
            </Disclosure>
            <Dialog
              id="checkout-sticky-bag-summary-modal"
              isOpen={openModal}
              onClose={handleCloseModal}
              heading={`${tmntData?.yourBag?.value}  ( ${orderTotals?.totalItemsInBag} Items )`}
              closeButtonLabel="Close"
              classList="sticky-bag-summary-modal"
            >
              <div className="sticky-bag-content">
                <div data-testid="sticky-nav-baglist" className="sticky-bag-summary-baglist">
                  <BagList
                    variant="rightRail"
                    bagItems={bagItems?.items}
                    tmntData={tmntData}
                  />
                </div>

                <OrderTotals
                  cartTotalAmountFmt={orderTotals?.cartTotalAmountFmt}
                  cartTotalAmount={orderTotals?.cartTotalAmount}
                  tmntData={tmntData}
                  promoAmountFmt={orderTotals?.promoAmountFmt}
                  cartPayableAmount={orderTotals?.grandTotalFmt}
                  includesConsumptionTax={
                  orderTotals?.includesConsumptionTax
                }
                  charityRoundUpFmt={orderTotals?.charityTotalFmt}
                  includesGst={orderTotals?.includesGst}
                  includesVat={orderTotals?.includesVat}
                  showDisclosure={!!config?.showDisclosure}
                  isVatIncluded={config?.isVatIncluded}
                  totalItemsInBag={orderTotals?.totalItemsInBag}
                  shippingHandlingChargeFmt={orderTotals?.shippingHandlingChargeFmt}
                  estimatedTaxAmountFmt={orderTotals?.estimatedTaxAmountFmt}
                  isCheckoutPage
                  shippingModeType={shipMethod?.shipModeType}
                  totalGiftBoxesAmount={orderTotals?.totalGiftBoxesAmount}
                  totalGiftBoxes={orderTotals?.totalGiftBoxes}
                  giftCards={giftCards?.appliedGiftCards}
                  displayFreeShippingText={orderTotals?.displayFreeShippingText}
                  displayGiftReceiptText={orderTotals?.displayGiftReceiptText}
                  vatDetails={vatDetails}
                />
              </div>
            </Dialog>
          </>
        )}
      </div>
    </>

  );
}

export default StickyBagSummary;
StickyBagSummary.defaultProps = {
  bagItems: {},
  orderTotals: {},
  tmntData: {},
  config: {},
  shipMethod: {},
  giftCards: {},
  vatDetails: {},
  isAppWebView: false,
};
StickyBagSummary.propTypes = {
  bagItems: PropTypes.instanceOf(Object),
  orderTotals: PropTypes.instanceOf(Object),
  tmntData: PropTypes.instanceOf(Object),
  config: PropTypes.instanceOf(Object),
  shipMethod: PropTypes.instanceOf(Object),
  giftCards: PropTypes.instanceOf(Object),
  vatDetails: PropTypes.instanceOf(Object),
  isAppWebView: PropTypes.bool,
};
