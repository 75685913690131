import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LoggedOutView from './loggedOutView/LoggedOutView';
import LoggedInView from './loggedInView/LoggedInView';
import LoggedInViewV2 from './loggedInView/LoggedInViewV2';
import SwitchTestContext from '../../../context/SwitchTestContext';

export default function UserState({
  userData, tmntData, loyaltyEstimatedPoints, isEmptyBag, totalCoupons,
}) {
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const hasBagMfeV2 = digitalData && digitalData['chk-has-bag-mfe-v2'];

  const loggedInView = () => (hasBagMfeV2 ? (
    <LoggedInViewV2
      iconImageUrl={userData.iconImageUrl}
      loggedInData={userData.loggedInData}
      tmntData={tmntData}
      loyaltyInfo={userData.loyaltyInfo}
      totalCoupons={totalCoupons}
    />
  ) : (
    <LoggedInView
      iconImageUrl={userData.iconImageUrl}
      loggedInData={userData.loggedInData}
      tmntData={tmntData}
      loyaltyInfo={userData.loyaltyInfo}
    />
  ));

  return (
    <div className="loyalty-section__wrapper">
      <div data-module-loading="false">
        {(userData?.hasSignInButton && userData?.hasLoyaltyEnabled) && (
          <div className="loyalty-member-welcome">
            {userData?.isLoggedIn ? (
              loggedInView()
            ) : (
              <LoggedOutView
                iconImageUrl={userData.iconImageUrl}
                tmntData={tmntData}
                estimatedPoints={loyaltyEstimatedPoints}
                displayIconImage={userData.displayIconImage}
                isEmptyBag={isEmptyBag}
              />
            )}
          </div>
        )}
        {userData?.statusCode === '408' && (
          <div className="loyalty-member-error">
            {userData?.statusMessages?.map((err) => (
              err?.message && <p key={err}>{err.message}</p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

UserState.defaultProps = {
  tmntData: {},
  loyaltyEstimatedPoints: '',
  isEmptyBag: false,
  totalCoupons: 0,
  userData: {
    statusCode: '',
    statusMessages: [],
    hasSignInButton: false,
    hasLoyaltyEnabled: false,
    isLoggedIn: false,
    isRemembered: false,
    loggedInData: {
      userName: '',
      registrationDate: '',
    },
    loyaltyInfo: {
      loyaltyTierInfo: {
        loyaltyTier: '',
        actionNeededForNextTier: '',
        formattedActionNeededForNextTier: '',
        spendByDateForNextTier: '',
        formattedLoyaltyTierExpirationDate: '',
      },
    },
    iconImageUrl: '',
    displayIconImage: false,
  },

};

UserState.propTypes = {
  tmntData: PropTypes.instanceOf(Object),
  loyaltyEstimatedPoints: PropTypes.string,
  isEmptyBag: PropTypes.bool,

  totalCoupons: PropTypes.number,
  userData: PropTypes.shape({
    statusCode: PropTypes.string,
    statusMessages: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    hasSignInButton: PropTypes.bool,
    hasLoyaltyEnabled: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isRemembered: PropTypes.bool,
    brand: PropTypes.string,
    loggedInData: PropTypes.shape({
      userName: PropTypes.string,
      registrationDate: PropTypes.string,
    }),
    loyaltyInfo: PropTypes.shape({
      loyaltyTierInfo: PropTypes.shape({
        loyaltyTier: PropTypes.string,
        actionNeededForNextTier: PropTypes.string,
        formattedActionNeededForNextTier: PropTypes.string,
        spendByDateForNextTier: PropTypes.string,
        formattedLoyaltyTierExpirationDate: PropTypes.string,
      }),
    }),
    iconImageUrl: PropTypes.string,
    displayIconImage: PropTypes.bool,
  }),

};
