import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import useLog from '../../useLog/useLog';
import CreateAccount from './CreateAccount';
import isValidPassword from '../../../tools/isValidPassword';
import OrderConfirmationContext from '../../../context/OrderConfirmationContext';
import {
  CREATE_ACCOUNT_MUTATION,
} from '../../../gql/orderConfirmationPage.gql';

export default function CreateAccountWrapper({ onSuccess }) {
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isTermsInvalid, setIsTermsInvalid] = useState(false);
  const [displayFormError, setDisplayFormError] = useState(false);
  const [isProcessingForm, setIsProcessingForm] = useState(false);
  const [submitButtonLabel, setSubmitButtonLabel] = useState('');
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const logger = useLog('checkout.createAccount');

  const updateSubmitButtonState = (labelReference, disabled = false) => {
    setSubmitButtonLabel(labelReference);
    setSubmitButtonDisabled(disabled);
  };

  const [createAccountMutation] = useMutation(CREATE_ACCOUNT_MUTATION, {
    onCompleted: ({ createAccount }) => {
      try {
        if (createAccount?.success) {
          window.digitalData?.trigger('analytics.login_success');
          onSuccess();
        } else {
          logger.error('OCP.createAccountMutation ERROR', createAccount);
          setDisplayFormError(true);
        }
      } finally {
        setIsProcessingForm(false);
      }
    },
    onError: (error) => {
      logger.error('OCP.createAccountMutation ERROR', error);
      setDisplayFormError(true);
    },
  });
  const { orderConfirmationData } = useContext(OrderConfirmationContext);
  const {
    textFor, orderContact, shippingAddress, config,
  } = orderConfirmationData;

  const {
    buttonSubmit,
    buttonProcessing,
    buttonError,
  } = textFor;

  const formSubmission = (evt, passwordValue, termsChecked) => {
    evt.preventDefault();

    if (submitButtonDisabled) return;
    updateSubmitButtonState(buttonProcessing, true);
    setIsProcessingForm(true);
    setDisplayFormError(false);

    const passwordIsValid = isValidPassword(passwordValue);
    const termsAreInvalid = config?.isWDRegion ? false : !termsChecked;

    setIsPasswordInvalid(!passwordIsValid);
    setIsTermsInvalid(termsAreInvalid);

    if (!passwordIsValid || termsAreInvalid) {
      updateSubmitButtonState(buttonError, true);
      setTimeout(() => {
        updateSubmitButtonState(buttonSubmit);
        setIsProcessingForm(false);
      }, 1000);
      return;
    }

    createAccountMutation({
      variables: {
        email: orderContact.email,
        primaryPhone: orderContact.phoneNumber,
        firstName: shippingAddress.firstName,
        lastName: shippingAddress.lastName,
        password: passwordValue,
        legalAccept: termsChecked,
      },
    }).then(() => {
      updateSubmitButtonState(buttonSubmit);
    });
  };

  return (
    <CreateAccount
      email={orderContact.email}
      phoneNumber={orderContact.phoneNumber}
      firstName={shippingAddress.firstName}
      lastName={shippingAddress.lastName}
      onFormSubmit={formSubmission}
      buttonSubmitLabel={submitButtonLabel || buttonSubmit}
      showCheckbox={!config?.isConsentOptional}
      displayFormError={displayFormError}
      isDisabledSubmit={submitButtonDisabled}
      isProcessingForm={isProcessingForm}
      isPasswordInvalid={isPasswordInvalid}
      isTermsInvalid={isTermsInvalid}
    />
  );
}

CreateAccountWrapper.propTypes = {
  onSuccess: PropTypes.func,
};

CreateAccountWrapper.defaultProps = {
  onSuccess: () => {},
};
