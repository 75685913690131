import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { RecommendationSliders } from '@xp-utilities/web';
import ShoppingBagRecommendationsContextProvider from '../../context/Recommendations/ShoppingBagRecommendationsContextProvider';
import handleOnProductClick from './helpers';

const ShoppingBagRecommendationsPropTypes = {
  id: PropTypes.string.isRequired,
  placements: PropTypes.arrayOf(PropTypes.string),
  slidesToShow: PropTypes.number,
  bagItems: PropTypes.arrayOf(PropTypes.shape({})),
  hasItemsInBag: PropTypes.bool.isRequired,
};

const ShoppingBagRecommendationsDefaultProps = {
  placements: [],
  slidesToShow: undefined,
  bagItems: [],
};

export default function ShoppingBagRecommendations({
  id,
  placements,
  slidesToShow,
  bagItems,
  hasItemsInBag,
}) {
  const sliderRefs = useRef(null);

  const renderContainer = () => (
    <div
      id={id}
      data-testid={id}
      ref={sliderRefs}
    >
      <RecommendationSliders
        placements={placements}
        slidesToShow={slidesToShow}
        onProductClick={handleOnProductClick}
      />
    </div>
  );

  return (
    <ShoppingBagRecommendationsContextProvider
      id={id}
      bagItems={bagItems}
      hasItemsInBag={hasItemsInBag}
    >
      { renderContainer() }
    </ShoppingBagRecommendationsContextProvider>
  );
}

ShoppingBagRecommendations.propTypes = ShoppingBagRecommendationsPropTypes;
ShoppingBagRecommendations.defaultProps = ShoppingBagRecommendationsDefaultProps;
