import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Dialog } from 'anf-core-react';
import Loader from '../../../Common/Loader/Loader';
import PAYMENT_STATUS_QUERY from '../../../../gql/paymentStatus.gql';
import {
  localStorageSetValue, localStorageGetValue, localStorageRemoveValue,
} from '../../../../tools/localStorage';
import usePaypalExpress from '../../../../tools/paypal';
import Tmnt from '../../../Tmnt/Tmnt';
import $window from '../../../../tools/window';
import useLog from '../../../useLog/useLog';

export default function PayPal({
  payPalEnvironment,
  payPalMerchantId,
  PayPalInContextScriptSrc,
  tmntData,
}) {
  const {
    loadPaypalJS,
    paypalSetup,
    openPaypalPopupWindow,
    isPaypalAvaliable,
  } = usePaypalExpress(
    payPalMerchantId,
    payPalEnvironment,
    '',
    PayPalInContextScriptSrc,
  );
  const logger = useLog('checkout.PayPal');

  useEffect(() => {
    if (!($window?.paypal?.checkout)) {
      loadPaypalJS();
    }
  }, [loadPaypalJS, paypalSetup, payPalEnvironment, payPalMerchantId]);

  useEffect(() => {
    function handlePayPalLoad(event) {
      const {
        paymentURL,
        paymentStatusURL,
      } = event?.detail;
      localStorageSetValue('paymentStatusURL', paymentStatusURL);
      // for PayPal open PayPal pop-up to complete payment
      openPaypalPopupWindow(paymentURL);
    }
    $window.addEventListener('mfe:paypal:openSheet', handlePayPalLoad);
    return () => {
      $window.removeEventListener('mfe:paypal:openSheet', handlePayPalLoad);
    };
  }, [openPaypalPopupWindow]);

  const [getPaymentStatus, { loading }] = useLazyQuery(PAYMENT_STATUS_QUERY, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ssr: false,
    onCompleted: (res) => {
      if (res?.paymentStatus?.success) {
        logger.debug('PAYMENT_STATUS_QUERY RESULT', res);
        localStorageRemoveValue('paymentStatusURL');
        const event = new CustomEvent('mfe:submit:successful');
        $window.dispatchEvent(event);
      } else {
        logger.error(`ERR: PAYMENT_STATUS_QUERY: ${JSON.stringify(res)}`);
        const event = new Event('mfe:applepay:error');
        event.detail = res?.paymentStatus?.statusMessages?.[0]?.message ?? 'Something went wrong. Please try again.';
        $window.dispatchEvent(event);
      }
    },
    onError: (err) => {
      logger.error(`ERR: PAYMENT_STATUS_QUERY: ${JSON.stringify(err)}`);
      const event = new Event('mfe:applepay:error');
      event.detail = err?.message ?? 'Something went wrong. Please try again.';
      $window.dispatchEvent(event);
    },
  });

  useEffect(() => {
    const params = new URLSearchParams($window.location.search);
    const orderId = params.get('orderId');
    const payPalPayerId = params.get('PayPalPayerId');
    const payPalToken = params.get('PayPalToken');

    if (orderId && payPalPayerId && payPalToken) {
      const paymentStatusURL = localStorageGetValue('paymentStatusURL');
      if (paymentStatusURL) {
        getPaymentStatus({
          variables: {
            paymentStatusURL,
            orderId,
            paymentType: 'paypal',
          },
        });
      } else {
        logger.error(`ERR: paymentStatusURL: ${paymentStatusURL}}`);
      }
    }
  }, [getPaymentStatus, logger]);

  return (
    <div id="mfe-paypal-pay" data-ready={isPaypalAvaliable()} data-testid="mfe-paypal-pay">
      <Dialog
        isOpen={loading}
        id="process-paypal-order-loader"
        closeButtonLabel=""
      >
        <div className="process-paypal-order-loader__container">
          <div>
            <Loader />
          </div>
          <Tmnt tmnt={tmntData?.processingOrder} />
        </div>
      </Dialog>
    </div>
  );
}

PayPal.defaultProps = {
  payPalMerchantId: '',
  payPalEnvironment: '',
  PayPalInContextScriptSrc: '',
  tmntData: {},
};

PayPal.propTypes = {
  payPalMerchantId: PropTypes.string,
  payPalEnvironment: PropTypes.string,
  PayPalInContextScriptSrc: PropTypes.string,
  tmntData: PropTypes.instanceOf(Object),
};
