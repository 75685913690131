export const PXP_ORIGINS = [
  'https://api-integration.pxp-solutions.net',
  'https://api-eu1.pxp-solutions.net',
  'https://api-us1.pxp-solutions.net',
];

export const PAYMENT_SUCCESS_STATUS_TYPES = [
  'SUCCESS',
  'AUTHORIZED',
];

export function isCreditCardInfoComplete(cardObject) {
  return !!(
    cardObject?.cardNumber
    && cardObject?.paymentCode
    && cardObject?.cardExpireMonth
    && cardObject?.cardExpireYear
    && cardObject?.cvv
  );
}

export function isSavedCardInfoComplete(cardObject) {
  return !!(
    cardObject?.savedPaymentId
    && cardObject?.paymentCode
    && cardObject?.cvv
  );
}

export function splitExpiryDateIntoMonthAndYear(cardExpiryDate) {
  let monthAndYear;
  if (cardExpiryDate && (cardExpiryDate.indexOf('/') === -1 && cardExpiryDate.length === 6)) {
    const inputValueArr = cardExpiryDate?.split('');
    const mon = inputValueArr?.slice(0, 2);
    const year = inputValueArr?.splice(-2);
    const mmyy = mon?.concat(year);
    mmyy?.splice(2, 0, '/');
    const str = mmyy?.join('');
    const splitExpiry = str?.split('/');
    if (splitExpiry?.length === 2) {
      monthAndYear = {
        month: splitExpiry[0]?.trim(),
        year: `20${splitExpiry[1]?.trim()}`,
      };
    }
  } else if (cardExpiryDate) {
    const splitExpiry = cardExpiryDate.split('/');
    if (splitExpiry?.length === 2) {
      monthAndYear = {
        month: splitExpiry[0]?.trim(),
        year: `20${splitExpiry[1]?.trim()}`,
      };
    }
  }
  return monthAndYear;
}

export function buildCreditCard(creditCard) {
  let card;

  // At minimum, the creditCard needs to have a paymentCode and cardNumber
  if (
    creditCard
    && creditCard?.paymentCode
    && creditCard?.cardNumber
    && creditCard?.cardExpiryFmt
  ) {
    const {
      paymentCode,
      cardNumber: cardNumberFmt,
      cardExpiryFmt,
      cvv,
      savePayment,
    } = creditCard;
    const cardNumber = cardNumberFmt?.split(' ').join('');
    const cardFirstSix = cardNumber?.substring(6, 0);
    const cardLastFour = cardNumber?.substring(cardNumber.length - 4);

    const expiryMonthAndYear = splitExpiryDateIntoMonthAndYear(cardExpiryFmt);
    if (expiryMonthAndYear) {
      const cardExpireMonth = expiryMonthAndYear?.month;
      const cardExpireYear = expiryMonthAndYear?.year;

      card = {
        paymentCode,
        cardFirstSix,
        cardLastFour,
        cardExpireMonth,
        cardExpireYear,
        cardNumber,
        cvv,
        savePayment: !!savePayment,
      };
    }
  }
  return card;
}

export function isValidPXPOrigin(origin) {
  return (PXP_ORIGINS.indexOf(origin) > -1);
}

export function isPaymentDeclined(payment) {
  return (PAYMENT_SUCCESS_STATUS_TYPES.indexOf(payment?.status?.toUpperCase()) === -1);
}
