import {
  React, useEffect, useState, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import useScript from '../../../Helpers/useScript';
import useUpdateShippingSpeed from '../../../Helpers/useUpdateShippingSpeed';
import useSubmit from '../../../Helpers/useSubmit';

import {
  getAddressFromContact,
  routeApplePaySession,
} from '../../../../tools/applePay';

export default function ApplePay({
  applePayConfig,
  orderTotals,
  isExpressFlow,
  pageState = {},
  updatePageState,
  hasApplePayPXP,
  countriesConfig,
}) {
  const [applePayReady, setApplePayReady] = useState(false);
  const [currentFormData, setCurrentFormData] = useState(null);
  const [updateShippingSpeedMutation] = useUpdateShippingSpeed((data) => {
    updatePageState(data);
  });
  const { orderSubmit } = useSubmit();

  // useScript will load apple-pay.js from pxp on demand
  const [applePayPXPScript] = useScript({
    id: 'apple-pay-pxp-script',
    src: applePayConfig?.pxpScriptURL ?? '',
    attrs: {
      'data-namespace': 'apple-pay-pxp-script',
    },
    hasCacheBusting: true,
  });

  // set a unique clientSystemTransactionId for each order and persist it
  const clientSystemTransactionId = useRef(null);
  const attemptNumber = useRef(0);
  useEffect(() => {
    if (!clientSystemTransactionId.current && orderTotals?.orderId) {
      clientSystemTransactionId.current = `${orderTotals.orderId}-${Date.now()}`;
      attemptNumber.current = 0; // Reset attempt number when orderId changes
    }
  }, [orderTotals?.orderId]);

  // create apple pay settings object that will be used to initialize the apple pay session
  const applePaySettings = useMemo(() => ({
    domainName: window.location.hostname,
    useDigitalWalletButton: false,
    buttonOptions: null,
    mid: applePayConfig?.mid,
    sid: applePayConfig?.sid,
    publicKeyApi: applePayConfig?.publicKeyApi,
    isExpressFlow,
    updateShippingSpeedMutation,
    orderSubmit,
    tokenVaultURL: applePayConfig?.tokenVaultURL,
  }), [
    applePayConfig.mid,
    applePayConfig.sid,
    applePayConfig.publicKeyApi,
    applePayConfig.tokenVaultURL,
    isExpressFlow,
    updateShippingSpeedMutation,
    orderSubmit,
  ]);

  // initilize apple pay session as soon as it's ready
  // because PXP needs to load more scripts after they added check
  // for which apple pay version to support
  useEffect(() => {
    if (applePayPXPScript?.loaded && hasApplePayPXP && attemptNumber.current === 0) {
      // default initialization with Express Flow
      const updatedApplePaySettings = {
        ...applePaySettings,
        clientSystemTransactionId: `${clientSystemTransactionId.current}-${attemptNumber.current}`,
        isExpressFlow: true,
        countriesConfig,
      };
      // Increment the attempt number
      attemptNumber.current += 1;

      // route and start the apple pay session based on the PXP switch
      // set fields based on the apple pay flow selected
      routeApplePaySession(
        applePayConfig?.applePayRequest,
        updatedApplePaySettings,
        hasApplePayPXP,
      );
    }
  }, [
    applePayPXPScript?.loaded,
    applePaySettings,
    hasApplePayPXP,
    applePayConfig?.applePayRequest,
    countriesConfig,
  ]);

  // initialize the apple pay session based on the PXP switch
  useEffect(() => {
    function openApplePaySheet(event) {
      const { formData, isApplePayExpress } = event.detail;
      const updatedApplePaySettings = {
        ...applePaySettings,
        clientSystemTransactionId: `${clientSystemTransactionId.current}-${attemptNumber.current}`,
        isExpressFlow: isApplePayExpress,
        countriesConfig,
        currentFormData: formData,
        pageState,
      };
      // Increment the attempt number
      attemptNumber.current += 1;

      // update form data from CRS if available
      setCurrentFormData(formData);

      // trigger this event to save in submit button state of isApplePayExpress
      const openApplePaySheetEvent = new CustomEvent('mfe:checkout:applePayOpen', {
        detail: { isApplePayExpress },
      });
      window.dispatchEvent(openApplePaySheetEvent);

      // route and start the apple pay session based on the PXP switch
      // set fields based on the apple pay flow selected
      routeApplePaySession(
        applePayConfig?.applePayRequest,
        updatedApplePaySettings,
        hasApplePayPXP,
      );

      // open Apple Pay sheet using PXP function
      /* eslint-disable-next-line no-undef */
      if (onApplePayButtonClicked && typeof onApplePayButtonClicked === 'function') {
        /* eslint-disable-next-line no-undef */
        onApplePayButtonClicked();
      }
    }
    // add a listener for the Apple Pay open sheet event
    window.addEventListener('crs:applePay:openSheet', openApplePaySheet);
    return () => {
      window.removeEventListener('crs:applePay:openSheet', openApplePaySheet);
    };
  }, [
    applePayConfig?.applePayRequest,
    applePaySettings,
    hasApplePayPXP,
    setCurrentFormData,
    countriesConfig,
    pageState,
  ]);

  useEffect(() => {
    function handleApplePayPaymentResult(event) {
      const paymentResult = event.detail;

      // when we use apple pay 3.0 script that will mean that payment is completed
      if (paymentResult?.message === 'Payment completed.' || paymentResult?.errorMessage === 'Payment failed') {
        return;
      }

      // this is needed in case we switch to the older version of apple pay script
      const { applePayRequest } = applePayConfig;
      const orderSubmitInput = currentFormData ?? {};

      orderSubmitInput.payment = {
        applePayPayment: {
          appleTransactionId:
            paymentResult?.transactionAccountId ?? paymentResult?.clientSystemTransactionId,
          clientTransactionId: paymentResult?.clientSystemTransactionId,
          cryptogram: paymentResult?.cryptogram ?? '',
          eciIndicator: paymentResult?.eciIndicator ?? '',
        },
        paymentCode: 'applePayPayment',
      };

      orderSubmitInput.shippingAddress = paymentResult?.shippingContact
        ? getAddressFromContact(paymentResult.shippingContact, countriesConfig) : null;
      orderSubmitInput.billingAddress = paymentResult?.billingContact
        ? getAddressFromContact(paymentResult.billingContact, countriesConfig) : null;
      orderSubmitInput.shipModeId = applePayRequest?.shippingMethods?.[0]?.identifier;
      orderSubmitInput.billingAddressSameAsShipping = !orderSubmitInput.billingAddress;

      // remove some unnecessary fields
      delete orderSubmitInput.orderSubmitLegalTerms;
      delete orderSubmitInput.orderSubmitPaymentMethod;

      // trigger event for submit mutation
      const newEvent = new Event('mfe:checkout:submit');
      newEvent.detail = { success: true, errorMessage: '', formData: orderSubmitInput };

      window.dispatchEvent(newEvent);
    }

    function handleApplePayPaymentError(event) {
      const paymentResult = event.detail;

      // when we use apple pay 3.0 script that will mean that payment is completed
      if (paymentResult?.message === 'Payment completed.' || paymentResult?.errorMessage === 'Payment failed') {
        return;
      }
      // trigger event to display submit error
      const newEvent = new Event('mfe:checkout:submit');
      newEvent.detail = {
        success: false,
        errorMessage: event.detail.error,
        formData: currentFormData,
      };
      window.dispatchEvent(newEvent);
    }

    window.addEventListener('applePay:handlePaymentResult', handleApplePayPaymentResult);
    window.addEventListener('applePay:handlePaymentError', handleApplePayPaymentError);

    return () => {
      window.removeEventListener('applePay:handlePaymentResult', handleApplePayPaymentResult);
      window.removeEventListener('applePay:handlePaymentError', handleApplePayPaymentError);
    };
  }, [currentFormData, applePayConfig, countriesConfig]);

  // set the apple pay ready state when PXP lets us know that Apple Pay is ready
  useEffect(() => {
    window.handleApplePayReady = function handleApplePayReady() {
      if (applePayReady) return;

      // PXP will let us know that Apple Pay is ready
      setApplePayReady(true);
    };

    return () => {
      delete window.handleApplePayReady;
    };
  }, [applePayReady]);

  // render an empty div and reflect the state of apple pay
  return <div id="mfe-apple-pay" data-ready={applePayReady} data-testid="mfe-apple-pay" />;
}

// props validation
ApplePay.propTypes = {
  applePayConfig: PropTypes.shape({
    mid: PropTypes.string,
    sid: PropTypes.string,
    publicKeyApi: PropTypes.string,
    pxpScriptURL: PropTypes.string,
    tokenVaultURL: PropTypes.string,
    applePayRequest: PropTypes.shape({
      supportedNetworks: PropTypes.arrayOf(PropTypes.string),
      countryCode: PropTypes.string,
      currencyCode: PropTypes.string,
      lineItems: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        amount: PropTypes.string,
      })),
      shippingMethods: PropTypes.arrayOf(PropTypes.shape({
        identifier: PropTypes.string,
        detail: PropTypes.string,
        amount: PropTypes.string,
      })),
      total: PropTypes.shape({
        label: PropTypes.string,
        amount: PropTypes.string,
      }),
    }),
  }).isRequired,
  orderTotals: PropTypes.shape({
    orderId: PropTypes.string,
    grandTotal: PropTypes.number,
    currency: PropTypes.string,
  }).isRequired,
  isExpressFlow: PropTypes.bool.isRequired,
  pageState: PropTypes.shape({
    shippingSpeed: PropTypes.arrayOf(PropTypes.shape({
      isSelected: PropTypes.bool,
      shipModeType: PropTypes.string,
    })),
  }),
  updatePageState: PropTypes.func.isRequired,
  hasApplePayPXP: PropTypes.bool.isRequired,
  countriesConfig: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      addressFieldVisibility: PropTypes.shape({
        prefecture: PropTypes.bool,
        province: PropTypes.bool,
        region: PropTypes.bool,
        state: PropTypes.bool,
        zone: PropTypes.bool,
      }),
    }),
  ).isRequired,
};

ApplePay.defaultProps = {
  pageState: {},
};
