import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import Loader from '../Loader/Loader';
import useLog from '../../useLog/useLog';
import ESPOTS_QUERY from '../../../gql/eSpots.gql';

export default function MarketingSpot({
  espotId,
  classes,
  espotData,
}) {
  const [eSpot, setESpot] = useState();
  const logger = useLog('MarketingSpot.root');
  const [loadEspot, { loading, data }] = useLazyQuery(ESPOTS_QUERY, {
    variables: {
      espotId,
    },
    ssr: false,
    onError: (err) => {
      logger.debug('MarketingSpot', 'loadEspot', 'failed to load', err);
    },
  });

  useEffect(() => {
    if (espotData !== null) {
      setESpot(espotData);
    } else {
      loadEspot();
    }
  }, [espotData, setESpot, loadEspot]);

  if (loading) return <Loader classList="loader" />;

  if (data && data?.eSpots?.eSpot) {
    if (!eSpot) {
      setESpot(data.eSpots?.eSpot);
    }
  }

  const classNames = ['genericESpot'];
  if (!eSpot || !eSpot?.content) {
    classNames.push('genericESpot--empty');
  }
  if (classes) {
    classNames.push(classes);
  }

  return (
    <div
      data-espotname={espotId}
      data-testid={espotId}
      className={classNames.join(' ')}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={
        { __html: eSpot?.content }
      }
    />
  );
}

MarketingSpot.defaultProps = {
  classes: '',
  espotData: null,
};

MarketingSpot.propTypes = {
  espotId: PropTypes.string.isRequired,
  classes: PropTypes.string,
  espotData: PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string,
  }),
};
