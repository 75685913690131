import React, { useState } from 'react';
import {
  CountrySelector as ANFCountrySelector, DropDown, FlagBlock, Link,
} from 'anf-core-react';
import PropTypes from 'prop-types';

const textProp = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const propTypes = {
  text: PropTypes.shape({
    seeStore: textProp,
    change: textProp,
    countryText: textProp,
  }),
  countryList: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  country: PropTypes.string,
  onUpdateCountry: PropTypes.func,
};
const defaultProps = {
  text: undefined,
  countryList: undefined,
  country: undefined,
  onUpdateCountry: undefined,
};
export default function CheckoutCountrySelector({
  countryList, country, text, onUpdateCountry,
}) {
  const [selectedCountry, setSelectedCountry] = useState({
    value: country,
    label: country,
  });
  const [showCountryDropDown, setShowCountryDropDown] = useState(false);

  const onchange = () => {
    setShowCountryDropDown(true);
  };

  const onSelectCountry = (e) => {
    setShowCountryDropDown(false);
    setSelectedCountry({
      value: e.currentTarget.value,
      label: e.currentTarget.value,
    });

    if (onUpdateCountry) {
      onUpdateCountry(e.currentTarget.value);
    }
  };

  if (!selectedCountry.value) {
    return null;
  }

  return (
    <div className="country-section">
      {!showCountryDropDown && (
      <ANFCountrySelector
        flagBlock={(
          <FlagBlock countryCode={selectedCountry.value} countryName={`${selectedCountry?.label} ${text?.seeStore?.value}`} />
          )}
        link={(
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link onClick={onchange}>
            {text?.change?.value}
          </Link>
        )}
      />
      )}
      {showCountryDropDown && (
      <DropDown
        id="country-drop-down"
        label={text?.countryText?.value}
        onChange={onSelectCountry}
        options={countryList}
        value={selectedCountry.value}
        name="addresses.popins.country"
      />
      )}
      <input id="shipping-country" data-testid="shipping-country" name="addresses.popins.country" type="hidden" value={selectedCountry.label} />
    </div>
  );
}

CheckoutCountrySelector.defaultProps = defaultProps;
CheckoutCountrySelector.propTypes = propTypes;
