import {
  gql,
} from '@apollo/client';

const GIFT_BOX_UPDATE_MUTATION = gql`
mutation UpdateGiftBox($giftBoxList: [GiftBoxList]) {
  updateGiftBox(giftBoxList: $giftBoxList) {
    bagItems {
      hasGiftCard
      hasOnlyGiftCard
      items {
        item {
          headers {
            badgeStatusMessage {
              key
              value
            }
            headerMessage
            estimatedShipDate
            shouldDisplayAsbadge
            attached
            fullWidth
            textAlign
            variant
          }
          footer {
            bogoMessage
          }
          image {
            altText
            imageSrc
            imageId
            classList
          }
          imageOverlay {
            productUrl
            zoomIn
          }
          secondaryContent {
            buttonGroup
          }
          productContent {
            gender
            name
            size
            brand
            shortSku
            longSku
            orderItemId
            productId
            collectionId
            notWrappable
            freeGift
            seq
            faceOut
            faceoutImage
            color
            productPrice {
              description
              originalPrice
              discountPrice
              discountText
              variant
              priceFlag
              original
              discount
              originalUSD
              discountUSD
            }
            promotions {
              shortDesc
              promotionType
            }
            deliveryDate
            toEmail
            editRemoveButtonVariant
            editRemoveButton
            hasEdit
            hasRemove
            isFinalSale
            itemShortDesc
            quantity
          }
        }
        giftBox {
          isWrapped
          giftBoxMessage
        }
      }
    }
    orderTotals {
      totalItemsInBag
      cartTotalAmount
      cartTotalAmountFmt
      cartTotalAmountUSD
      cartPayableAmount
      subTotalFmt
      promoAmount
      promoAmountFmt
      shippingHandlingChargeFmt
      totalGiftBoxes
      subTotal
      totalGiftBoxesAmount
      isGiftReceiptChecked
      displayFreeShippingText
      displayGiftReceiptText
      estimatedTaxAmount
      estimatedTaxAmountFmt
      estimatedTaxAmountUSD
      charityRoundUpFmt
      orderId
      includesConsumptionTax
      includesGst
      includesVat
      grandTotal
      grandTotalFmt
      currency
      subTotalUSD
      grandTotalUSD
      cartTotalAmountUSD
      totalAdjustmentUSD
      shippingCharge
      shippingChargeUSD
      shippingChargeFmt
    }
    statusCode
    statusMessages {
      code
      key
      message
    }
    success
  }
}`;

export default GIFT_BOX_UPDATE_MUTATION;
