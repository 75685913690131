import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useContext,
  useCallback,
} from 'react';
import {
  useQuery,
  useMutation,
} from '@apollo/client';
import $window from '../../tools/window';
import CharityPortal from './Charity/CharityPortal';
import ShippingSpeed from './ShippingSpeed/ShippingSpeed';
import CHECKOUTPAGE_DATA_QUERY from '../../gql/checkoutPage.gql';
import useLog from '../useLog/useLog';
import CheckoutPageContext from '../../context/CheckoutPageContext';
import SameDayDelivery from './SameDayDelivery/SameDayDelivery';
import AddressVerification from './AddressVerification/AddressVerification';
import SwitchTestContext from '../../context/SwitchTestContext';
import Loader from '../Common/Loader/Loader';
import CheckoutRightRailPortal from './CheckoutRightRailPortal';
import GooglePay from './PaymentManager/GooglePay/GooglePay';
import ApplePay from './PaymentManager/ApplePay/ApplePay';
import Klarna from './PaymentManager/Klarna/Klarna';
import CheckoutLeftRail from './CheckoutLeftRail';
import DeliveryTabs from './DeliveryOptions/DeliveryTabs/DeliveryTabs';
import ShipOrPickup from './ShipOrPickup/ShipOrPickup';
import PaymentManagerPortal from './PaymentManager/PaymentManagerPortal';
import Repudiation from '../Common/Repudiation/Repudiation';
import HandlePXPPayment from '../Common/PXP/HandlePXPPayment';
import { REMOVE_BAG_ITEM_MUTATION } from '../../gql/bagItem.gql';
import CheckoutTopRailPortal from './CheckoutTopRailPortal';
import CheckoutSelectedStorePortal from './CheckoutSelectedStorePortal';
import PopinsSection from './PopinsSection/PopinsSection';
import PudosSection from './PudosSection/PudosSection';
import ShippingHandlingContainer from './ShippingHandling/ShippingHandlingContainer';
import PayPal from './PaymentManager/PayPal/PayPal';
import { FRANCHISE_COUNTRY_REPUDIATE } from '../../tools/constants';

export default function CheckoutPage() {
  const [checkoutPageState, setCheckoutPageState] = useState({
    shippingSpeed: null,
    textFor: null,
    switches: null,
    charity: null,
    paymentConfig: null,
    bagItems: null,
    shippingAddress: null,
    cartState: null,
    cartPayment: null,
    orderContact: null,
    orderTotals: null,
    userData: null,
    klarnaSession: null,
    klarnaState: null,
    selectedPaymentType: '',
    klarnaReady: false,
    giftCards: null,
    paymentOptions: [],
    page: 'checkout',
    googleApiKey: null,
    orderSubmit: null,
  });
  const retryDelay = 500;
  const maxRetries = 3;
  const retryCount = useRef(0);
  const isResetFranchiseCountryEventTriggered = useRef(false);
  const hasCharity = !!checkoutPageState?.charity;
  const [showDiscount, setShowDiscount] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [googlePayInitialized, setGooglePayInitialized] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);

  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const isPudosEnabled = digitalData
    && digitalData['ful-chk-mfe-delivery-section']
    && checkoutPageState?.shipMethod
    && checkoutPageState.shippingAddress?.addressType === 'CP';

  const isPopinsEnabled = digitalData
    && digitalData['ful-chk-mfe-delivery-section']
    && checkoutPageState?.shipMethod?.shipModeType === 'Popins'
    && checkoutPageState?.shippingAddress?.addressType === 'P';

  const isShippingHandlingModal = digitalData && digitalData['ful-chk-mfe-delivery-section'];
  const isAddressVerifyEnabled = digitalData && digitalData['chk-addr-verify-enabled'];
  const hasApplePayPXP = digitalData && digitalData['chk-has-apple-pay-pxp'];
  const hasApplePay = hasApplePayPXP && !!window.ApplePaySession;
  const useStateAsCountrty = digitalData && digitalData['chk-use-state-as-country-googlepay'];
  const googlePayScriptURLOverride = digitalData && digitalData['google-pay-script-url-override'];
  const hasPayPalSubmitButtonInMfe = digitalData && digitalData['chk-has-mfe-submit-button-for-paypal-payment'];
  const hasBagMfeV2 = digitalData && digitalData['chk-has-bag-mfe-v2'];
  const logger = useLog('checkout.checkoutPage');

  const displayDeliveryOptions = !checkoutPageState?.bagItems?.hasOnlyEGiftCard;

  const updateCheckoutPageState = useCallback((newData) => {
    setCheckoutPageState((prev) => ({ ...prev, ...newData }));
  }, []);

  let refetchOnErrror = () => { };

  const refetchAndSetState = (refetchQuery, mounted) => {
    refetchQuery().then((response) => {
      if (mounted && response?.data?.shippingSpeed) {
        updateCheckoutPageState(response?.data);
        setIsUpdating(false);
      }
    }).catch(() => {
      refetchOnErrror(refetchQuery, mounted);
    });
  };

  refetchOnErrror = (refetchQuery, mounted) => {
    setIsUpdating(false);
    retryCount.current += 1;
    if (retryCount.current < maxRetries) {
      setTimeout(() => refetchAndSetState(refetchQuery, mounted), retryDelay);
    }
  };

  const {
    loading, error, refetch,
  } = useQuery(CHECKOUTPAGE_DATA_QUERY, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ssr: false,
    onCompleted: (res) => {
      logger.debug('CHECKOUTPAGE_DATA_QUERY RESULT', res);
      if (!isResetFranchiseCountryEventTriggered.current) {
        // Trigger the event below once on checkout query loads.
        // This is to reset the viewed status of the franchise
        // portal modal, which will show the modal again when
        // the user navigates to the checkout page if the required
        // conditions to show the franchise modal are met.

        // The useEffect hook is not used to trigger the event because
        // the catalog MFE, which listens to this event, may not be
        // loaded and ready when the event is triggered during the
        // initial mount of the checkout page. Therefore, the event
        // is triggered after the initial checkout page query has completed loading.
        const resetFranchiseCountryEvent = new CustomEvent('mfe:resetFranchiseCountry');
        window.dispatchEvent(resetFranchiseCountryEvent);
        isResetFranchiseCountryEventTriggered.current = true;
      }
      if (Object.keys(res?.promoInfo).length !== 0) {
        setShowDiscount(true);
      }
      updateCheckoutPageState({ ...res });
      setIsUpdating(false);
      retryCount.current = 0;

      // merge the bag state to digitalData `cart`
      $window.digitalData?.merge('cart', {
        origin: 'checkout',
        data: res,
        action: 'load',
      });
    },
    onError: (err) => {
      logger.error(`ERR: CHECKOUTPAGE_DATA_QUERY: ${JSON.stringify(err)}`);
      refetchOnErrror(refetch, true);
      setIsUpdating(false);
    },
  });

  useEffect(() => {
    function handleCartUpdateEvent() {
      setIsUpdating(true);
      refetch().then((res) => {
        logger.debug('CHECKOUTPAGE_DATA_QUERY RESULT', res);
        updateCheckoutPageState({ ...res.data });
        setIsUpdating(false);
      });
    }
    window.addEventListener('cartModel:updated', handleCartUpdateEvent);
    return () => {
      window.removeEventListener('cartModel:updated', handleCartUpdateEvent);
    };
  }, [logger, refetch, updateCheckoutPageState]);

  // load google pay script once event triggered
  useEffect(() => {
    function handleGooglePayLoad() {
      setGooglePayInitialized(true);
    }
    window.addEventListener('load:googlepay', handleGooglePayLoad);
    return () => {
      window.removeEventListener('load:googlepay', handleGooglePayLoad);
    };
  }, [setGooglePayInitialized]);

  const sameDayDeliveryService = useMemo(() => (
    checkoutPageState?.switches?.usingSameDayDeliveryMFE && <div data-testid="sdd-mfe"><SameDayDelivery /></div>
  ), [checkoutPageState?.switches?.usingSameDayDeliveryMFE]);

  // load google pay script once GooglePay is selected
  // from payment options in MFE
  useEffect(() => {
    if (checkoutPageState?.selectedPaymentType === 'googlepay') {
      setGooglePayInitialized(true);
    }
  }, [checkoutPageState?.selectedPaymentType]);

  useEffect(() => {
    const handleSameDayDeliveryAvailability = (event) => {
      const { usingSameDayDeliveryMFE } = event.detail;
      if (typeof usingSameDayDeliveryMFE !== 'undefined') {
        setCheckoutPageState((prevState) => ({
          ...prevState,
          switches: {
            ...(prevState.switches || {}),
            usingSameDayDeliveryMFE,
          },
        }));
      }
    };

    window.addEventListener('sameDayDelivery:availability', handleSameDayDeliveryAvailability);
    return () => {
      window.removeEventListener('sameDayDelivery:availability', handleSameDayDeliveryAvailability);
    };
  }, [setCheckoutPageState, checkoutPageState]);

  // listen for the payment selector payment method change event
  // and if it's klarna, load klarna iframe
  useEffect(() => {
    const handlePaymentMethodChangeEvent = (event) => {
      const { paymentType } = event.detail;
      updateCheckoutPageState({ selectedPaymentType: paymentType });
    };

    window.addEventListener('paymentSection:paymentUpdated', handlePaymentMethodChangeEvent);

    return () => {
      window.removeEventListener('paymentSection:paymentUpdated', handlePaymentMethodChangeEvent);
    };
  }, [updateCheckoutPageState]);

  const [removeCheckoutItemMutation] = useMutation(REMOVE_BAG_ITEM_MUTATION, {
    onCompleted: (removeCheckoutItem) => {
      logger.debug('removeCheckoutItemMutation RESULT', removeCheckoutItem);
      if (removeCheckoutItem?.removeBagItem?.success) {
        if (Object.keys(removeCheckoutItem?.removeBagItem?.bagItems?.items)?.length === 0) {
          window.location.reload();
        } else {
          setCheckoutPageState((previousState) => {
            const newState = ({
              ...previousState,
              orderTotals: removeCheckoutItem?.removeBagItem?.orderTotals,
              bagItems: removeCheckoutItem?.removeBagItem?.bagItems,
              promoInfo: removeCheckoutItem?.removeBagItem?.promoInfo,
              repudiationData: removeCheckoutItem?.removeBagItem?.repudiationData,
            });
            return newState;
          });
        }
      }
    },
  });

  const hasRepudiation = useMemo(
    () => checkoutPageState?.repudiationData?.repudiationProducts?.items?.length > 0,
    [checkoutPageState?.repudiationData?.repudiationProducts?.items],
  );

  const { repudiationData: { repudiationType = '' } = {} } = checkoutPageState || {};
  const hasFranchiseCountryRepudiation = repudiationType === FRANCHISE_COUNTRY_REPUDIATE;

  const hasInventoryRepudiation = (checkoutPageState?.repudiationData?.repudiationType === 'SOLDOUT_ITEM'
    || checkoutPageState?.repudiationData?.repudiationType === 'SFS_SOLDOUT_ITEM'
    || checkoutPageState?.repudiationData?.repudiationType === 'ZIPCODE_REPUDIATE')
    && checkoutPageState?.repudiationData?.errorMessage !== '';
  return (
    <CheckoutPageContext.Provider
      value={{
        checkoutPageState,
        setCheckoutPageState,
        updateCheckoutPageState,
        refetch,
        loading,
        setIsUpdating,
        isUpdating,
        error,
        setShowDiscount,
        showDiscount,
        setShowNotification,
        showNotification,
        selectedStore,
        setSelectedStore,
      }}
    >
      <CheckoutTopRailPortal
        bagItems={checkoutPageState?.bagItems}
        tmntData={checkoutPageState?.textFor}
        orderTotals={checkoutPageState?.orderTotals}
        config={checkoutPageState?.config}
        shipMethod={checkoutPageState?.shipMethod}
        giftCards={checkoutPageState?.giftCards}
        vatDetails={checkoutPageState?.vatDetails}
        id="checkout-top-rail-mfe-portal"
      />

      {sameDayDeliveryService}

      {/*
        Address verification can render. It is idle until it receives events
        we can still put a switch around it though. It acts as a core service.
        TODO: @vivek  wrap this around a LD swtich for good measure
      */}

      <div data-testid="address-verification">
        {isAddressVerifyEnabled && <AddressVerification />}
      </div>
      <Loader variant="hazy" isActive={isUpdating} id="signIn-loader">
        <div id="checkout-left-rail-mfe" className={hasBagMfeV2 ? 'bag-mfe-V2' : ''}>
          <CheckoutLeftRail />
        </div>
      </Loader>
      {/* When hasFranchiseCountryRepudiation is true, don't show bag repudiation,
       as we need to show the repudation message above checkout page header */}
      {hasRepudiation && !hasFranchiseCountryRepudiation ? (
        <div className="bag-repudiation">
          <Repudiation
            productList={checkoutPageState?.repudiationData?.repudiationProducts?.items}
            errorMessage={checkoutPageState?.repudiationData?.errorMessage}
            itemsToRemove={checkoutPageState?.repudiationData?.itemsToRemove}
            tmntData={checkoutPageState?.textFor}
            continueInCurrency={checkoutPageState?.repudiationData?.continueInCurrency}
            hasCheckoutButton={checkoutPageState?.repudiationData?.hasCheckoutButton}
            checkoutUrl={checkoutPageState?.repudiationData?.checkoutUrl}
            onRemoveItems={removeCheckoutItemMutation}
            showRemoveItemsButton={!hasInventoryRepudiation}
          />
        </div>
      ) : null}
      {displayDeliveryOptions && (
        <div data-testid="checkout-delivery-tabs">
          <Loader variant="hazy" isActive={isUpdating} id="delivery-tabs-loader">
            <ShipOrPickup
              textFor={checkoutPageState?.textFor}
              shipOrPickup={checkoutPageState?.shipOrPickup}
            />
            <DeliveryTabs textFor={checkoutPageState?.textFor} />
          </Loader>
        </div>
      )}
      <section data-testid="checkout-page-wrapper">
        {displayDeliveryOptions && (
          <>
            <Loader variant="hazy" isActive={isUpdating} id="shippingspeed-loader">
              <ShippingSpeed />
            </Loader>
            {isShippingHandlingModal && <ShippingHandlingContainer />}
          </>
        )}
        <CharityPortal
          id="charity-portal"
          hasCharity={hasCharity}
        />
        <div className="delivery-options-container">
          {isPudosEnabled && checkoutPageState.googleApiKey && checkoutPageState?.shipMethod?.shipModeType === 'Pudos' && (
            <div className="pudos-section">
              <PudosSection tmntData={checkoutPageState?.textFor} />
            </div>
          )}

          {isPopinsEnabled && checkoutPageState.googleApiKey && checkoutPageState?.shipMethod?.shipModeType === 'Popins' && (
            <div className="popins-section">
              <PopinsSection tmntData={checkoutPageState?.textFor} />
            </div>
          )}
        </div>
        {
          // only load Google Pay when it is selected in payment selector
          googlePayInitialized
          && (
            <GooglePay
              googlePayConfig={checkoutPageState?.paymentConfig?.googlePay}
              shippingAddress={checkoutPageState?.shippingAddress}
              orderTotals={checkoutPageState?.orderTotals}
              countriesConfig={checkoutPageState?.orderSubmitLegalTerms?.countriesConfig}
              useStateAsCountrty={useStateAsCountrty}
              googlePayURLOverride={googlePayScriptURLOverride}
            />
          )
        }
        {
          hasApplePay && checkoutPageState?.paymentConfig?.applePay
          && (
            <ApplePay
              applePayConfig={checkoutPageState?.paymentConfig?.applePay ?? {}}
              orderTotals={checkoutPageState?.orderTotals ?? {}}
              isExpressFlow={false}
              pageState={checkoutPageState}
              updatePageState={updateCheckoutPageState}
              hasApplePayPXP={hasApplePayPXP}
              countriesConfig={checkoutPageState?.orderSubmitLegalTerms?.countriesConfig}
            />
          )
        }
        {checkoutPageState?.klarnaState?.isEnabled && <Klarna />}
        {checkoutPageState?.paymentConfig?.payPal?.hasPayPal
        && hasPayPalSubmitButtonInMfe
        && (
          <PayPal
            payPalEnvironment={checkoutPageState?.paymentConfig?.payPal?.payPalEnvironment}
            payPalMerchantId={checkoutPageState?.paymentConfig?.payPal?.payPalMerchantId}
            PayPalInContextScriptSrc={
              checkoutPageState?.paymentConfig?.payPal?.PayPalInContextScriptSrc
            }
            tmntData={checkoutPageState?.textFor}
          />
        )}
        <PaymentManagerPortal id="checkout-payment-mfe-portal" />
        <CheckoutRightRailPortal id="checkout-right-rail-mfe-portal" />
        <CheckoutSelectedStorePortal id="selected-store-mfe-portal" />
      </section>
      <HandlePXPPayment />
    </CheckoutPageContext.Provider>
  );
}

CheckoutPage.defaultProps = {};

CheckoutPage.propTypes = {};
