import React, { useContext } from 'react';
import { Button } from 'anf-core-react';
import OrderConfirmationContext from '../../../context/OrderConfirmationContext';
import Tmnt from '../../Tmnt/Tmnt';

export default function AppDownLoadLink() {
  const { orderConfirmationData } = useContext(OrderConfirmationContext);
  const { appDownload = {} } = orderConfirmationData;
  const {
    androidDownloadLink: googlePlayUrl,
    iOsDownloadLink: appStoreUrl,
    androidImageLink: googlePlayImage,
    iOsImageLink: appStoreImage,
    seeMyAccountURL: href,
    iOsDownloadLabel: appStoreUrlAriaLabel,
    androidDownloadLabel: googlePlayUrlAriaLabel,
    seeMyAccountText,
    checkoutAppDownloadHeading,
  } = appDownload;

  return (
    <div className="app-download-link__container">
      <div className="app-download-link__wrapper">
        <h3 className="h3" data-testid="app-download-link-title">
          <Tmnt tmnt={checkoutAppDownloadHeading} />
        </h3>
        <div className="app-download-link__images">
          <a target="_blank" data-testid="app-download-link-app-store" aria-label={appStoreUrlAriaLabel?.value ?? ''} data-property={appStoreUrlAriaLabel?.key ?? ''} href={appStoreUrl} rel="noreferrer">
            <img
              width="130"
              className="app-download-link__image app-download-link"
              src={appStoreImage}
              alt="App Store"
            />
          </a>
          <a target="_blank" data-testid="app-download-link-google-play" aria-label={googlePlayUrlAriaLabel?.value ?? ''} data-property={googlePlayUrlAriaLabel?.key ?? ''} href={googlePlayUrl} rel="noreferrer">
            <img
              width="130"
              className="app-download-link__image app-download-link"
              src={googlePlayImage}
              alt="Google Play"
            />
          </a>
        </div>
      </div>
      <Button classList="go-to-account" variant="secondary" isFullWidth href={href}>
        <Tmnt tmnt={seeMyAccountText} />
      </Button>
    </div>
  );
}
