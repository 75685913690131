import React, {
  useState, useEffect, useCallback, useMemo, useContext,
} from 'react';
import {
  Checkbox, ErrorMessage, LegalText, Modal,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import Tmnt from '../../Tmnt/Tmnt';
import SwitchTestContext from '../../../context/SwitchTestContext';

function OrderSubmitLegalTerms({ orderSubmitLegalTermsData, shippingCountry, hasOnlyEGiftCard }) {
  const [legalTermsChecked, setLegalTermsChecked] = useState(false);
  const [legalTermsInvalid, setLegalTermsInvalid] = useState(false);
  const [legalTermsAgeChecked, setLegalTermsAgeChecked] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const hasAgreeSubmitTerms = digitalData && digitalData['chk-legal-submit-order-copy'];
  const hasMFELegalModalsEnabled = digitalData && digitalData['has-mfe-legal-modals-enabled'];

  useEffect(() => {
    const {
      displayLegalTermsCheckBox,
      displayAgeConsentCheckBox,
      isAgeConsentOptionCheckedByDefault,
    } = orderSubmitLegalTermsData;
    if (displayAgeConsentCheckBox) {
      setLegalTermsAgeChecked(isAgeConsentOptionCheckedByDefault);
    }

    if (displayLegalTermsCheckBox) {
      setLegalTermsChecked(isAgeConsentOptionCheckedByDefault);
    }
  }, [orderSubmitLegalTermsData]);

  const {
    displayLegalSaleTerms,
    displayReturnsAndExchanges,
    displayLegalSiteUse,
    displayLegalPrivacyPolicy,
    displayCompanyInfo,
    displayFapio,
    displayLegalTermsCheckBox,
    displayAgeConsentCheckBox,
    displayPrivacyNoticeWithMoreInfo,
    displayLegalOrderImportMessaging,
    salesTermsLinkText,
    returnAndExchangesLinkText,
    siteUseText,
    privacyPolicyText,
    companyInfoText,
    fapiaoText,
    rightToCancelText,
    moreInfoText,
    tickConfirmAndAgreeText,
    legalOrderImportMessageText,
    privacyNoticeText,
    requiredTermsConditionsText,
    cancellationRightsContent,
    returnsAndExchangesContent,
    legalSubmitOrderButtonCopy,
    countriesConfig,
  } = orderSubmitLegalTermsData;
  const checkBoxVisible = (!!displayLegalTermsCheckBox || !!displayAgeConsentCheckBox);

  // Note: we using useEffect to attach event trigger instead of onClick on button
  // because in EU region, the buttons are coming from TMNT
  useEffect(() => {
    if (!hasMFELegalModalsEnabled) return () => {};

    const legalButtons = document.querySelectorAll('.js-legal-policy-modal');

    const handleClick = (event) => {
      const tmntKey = event.target.getAttribute('data-textkey');

      const openLegalModalEvent = new CustomEvent('legal-modal:open', {
        detail: { tmntKey },
      });
      document.dispatchEvent(openLegalModalEvent);
    };

    legalButtons.forEach((button) => button.addEventListener('click', handleClick));

    return () => {
      legalButtons.forEach((button) => button.removeEventListener('click', handleClick));
    };
  }, [hasMFELegalModalsEnabled]);

  const legalLinkButton = (title, textKey, tmntText) => (

    <button
      className="button ds-override js-legal-policy-modal"
      type="button"
      data-title={title}
      data-textkey={textKey}
      data-variant="button-as-link"
    >
      <span>
        <span className="screen-reader-text" />
        <Tmnt tmnt={tmntText} />
        <span className="screen-reader-text" />
      </span>
    </button>

  );

  const handleLegalTermsChange = (event) => {
    setLegalTermsChecked(event.target.checked);
  };

  const handleLegalAgeTermsChange = (event) => {
    setLegalTermsAgeChecked(event.target.checked);
  };

  const hasImportDutyWaiver = useMemo(() => {
    const selectedCountry = countriesConfig.find((c) => c.id === shippingCountry);
    return selectedCountry ? selectedCountry.hasImportDutyWaiver : hasOnlyEGiftCard;
  }, [countriesConfig, shippingCountry, hasOnlyEGiftCard]);

  const validateLegalTerms = useCallback(() => {
    const isValid = (legalTermsChecked || legalTermsAgeChecked);
    setLegalTermsInvalid(!isValid);
    return isValid;
  }, [legalTermsChecked, legalTermsAgeChecked]);

  useEffect(() => {
    function handleCartValidation() {
      if (checkBoxVisible) { validateLegalTerms(); }
    }
    window.addEventListener('cartModel:validationFailed', handleCartValidation);

    return () => {
      window.removeEventListener('cartModel:validationFailed', handleCartValidation);
    };
  }, [validateLegalTerms, checkBoxVisible]);

  return (
    <div className="mfe-order-submit-legal">
      <div className="pipe-delimited-links legal-links" data-legallinks="true">
        <ul>
          {displayLegalSaleTerms && (
          /* Note: We are not using the ds-react-button as we are not able to add data-textkey &
              data-title attributes. This is something we need to work on for future integration. */
          <li className="pipe-delimited-link legal-links" data-legallinks>
            {legalLinkButton('Updated Sales Terms', 'LEGAL_SALES_TERMS', salesTermsLinkText)}
          </li>
          )}

          {displayReturnsAndExchanges && (
          <li className="pipe-delimited-link legal-links" data-legallinks>
            <button
              className="button returns-button"
              type="button"
              data-variant="button-as-link"
              onClick={() => setOpenModal(true)}
            >
              <span>
                <span className="screen-reader-text" />
                <Tmnt tmnt={returnAndExchangesLinkText} isHtml />
                <span className="screen-reader-text" />
              </span>
            </button>
            <Modal
              isOpen={openModal}
              onClose={() => setOpenModal(false)}
              id="ds-modal-returns-and-exhanges"
              heading={(<h2><Tmnt tmnt={returnAndExchangesLinkText} isHtml /></h2>)}
              closeButtonLabel="Close"
            >
              <Tmnt tmnt={returnsAndExchangesContent} isHtml />
            </Modal>
          </li>
          )}

          {displayLegalSiteUse && (
            <li className="pipe-delimited-link legal-links" data-legallinks>
              {legalLinkButton('Website Terms of Use', 'LEGAL_SITE_USE', siteUseText)}
            </li>
          )}

          {displayLegalPrivacyPolicy && (
            <li className="pipe-delimited-link legal-links" data-legallinks>
              {legalLinkButton('Privacy Policy', 'LEGAL_PRIVACY_POLICY', privacyPolicyText)}
            </li>
          )}

          {displayCompanyInfo && (
            <li className="pipe-delimited-link legal-links" data-legallinks>
              {legalLinkButton('Company Info', 'GLB_COMPANY_INFO_TXT', companyInfoText)}
            </li>
          )}

          {displayFapio && (
            <li className="pipe-delimited-link legal-links" data-legallinks>
              {legalLinkButton('FAPIAO', 'GLB_FAPIAO_TXT', fapiaoText)}
            </li>
          )}
        </ul>
      </div>
      <div className="order-submit-checkbox-wrapper">
        {displayPrivacyNoticeWithMoreInfo
        && (
        <>
          <hr />
          <div className="more-information-wrapper">
            <p
              className="privacy-notice"
              data-property="ACT_PRIVACY_NOTICE_DS"
            >
              <Tmnt tmnt={privacyNoticeText} isHtml />
            </p>
            <span data-property="CHK_RIGHT_TO_CANCEL"><Tmnt tmnt={rightToCancelText} isHtml /></span>
            <button
              className="button more-info-button"
              type="button"
              data-variant="button-as-link"
              onClick={() => setOpenModal(true)}
            >
              <span>
                <span className="screen-reader-text" />
                <Tmnt tmnt={moreInfoText} isHtml />
                <span className="screen-reader-text" />
              </span>
            </button>
            <Modal
              isOpen={openModal}
              onClose={() => setOpenModal(false)}
              id="ds-modal-right-to-cancel"
              heading={(<h2><Tmnt tmnt={moreInfoText} isHtml /></h2>)}
              closeButtonLabel="Close"
            >
              <Tmnt tmnt={cancellationRightsContent} isHtml />
            </Modal>
          </div>
        </>
        )}
        { !!displayLegalTermsCheckBox
        && (
          <>
            {displayPrivacyNoticeWithMoreInfo ? '' : <hr /> }
            <Checkbox
              id="legal-consent"
              name="orderSubmitLegalTerms"
              isRequired
              value="orderSubmitLegalTerms"
              isChecked={legalTermsChecked}
              onChange={handleLegalTermsChange}
              description={<Tmnt tmnt={tickConfirmAndAgreeText} isHtml />}
              isInvalid={legalTermsInvalid}
            />
          </>
        )}
        { !!displayAgeConsentCheckBox
        && (
          <>
            <hr />
            <Checkbox
              id="legal-consent"
              name="orderSubmitAgeConsent"
              isRequired
              value="orderSubmitAgeConsent"
              isChecked={legalTermsAgeChecked}
              onChange={handleLegalAgeTermsChange}
              isInvalid={legalTermsInvalid}
              description={<Tmnt tmnt={tickConfirmAndAgreeText} isHtml />}
            />

          </>
        )}
        { checkBoxVisible && legalTermsInvalid && (
        <ErrorMessage id="legal-consent-error-message legal-terms-invalid-error">
          <Tmnt tmnt={requiredTermsConditionsText} isHtml />
        </ErrorMessage>
        )}

        {displayLegalOrderImportMessaging && !hasImportDutyWaiver && (
        <LegalText>
          <hr />
          <p className="checkbox-label import-duty-waiver" data-testid="import-duty-waiver" htmlFor="legal-consent"><Tmnt tmnt={legalOrderImportMessageText} isHtml /></p>
        </LegalText>
        )}
      </div>
      {hasAgreeSubmitTerms && (
        <div className="legal-submit-order-button-copy">
          <Tmnt tmnt={legalSubmitOrderButtonCopy} isHtml />
        </div>
      )}
    </div>
  );
}

OrderSubmitLegalTerms.defaultProps = {
  orderSubmitLegalTermsData: {
    displayLegalSaleTerms: false,
    displayReturnsAndExchanges: false,
    displayLegalSiteUse: false,
    displayLegalPrivacyPolicy: false,
    displayCompanyInfo: false,
    displayFapio: false,
    displayLegalTermsCheckBox: false,
    displayAgeConsentCheckBox: false,
    displayPrivacyNoticeWithMoreInfo: false,
    displayLegalOrderImportMessaging: false,
    isAgeConsentOptionCheckedByDefault: false,
    countriesConfig: [],
    salesTermsLinkText: {
      key: '',
      value: 'Updated Sales Terms',
    },
    returnAndExchangesLinkText: {
      key: '',
      value: 'Returns & Exchanges',
    },
    siteUseText: {
      key: '',
      value: 'Website Terms of Use',
    },
    privacyPolicyText: {
      key: '',
      value: 'Privacy Notice',
    },
    companyInfoText: {
      key: '',
      value: 'Notation Page',
    },
    fapiaoText: {
      key: '',
      value: 'Fapiao',
    },
    rightToCancelText: {
      key: '',
      value: '',
    },
    moreInfoText: {
      key: '',
      value: 'More Information',
    },
    tickConfirmAndAgreeText: {
      key: '',
      value: '',
    },
    legalOrderImportMessageText: {
      key: '',
      value: '',
    },
    privacyNoticeText: {
      key: '',
      value: '',
    },
    requiredTermsConditionsText: {
      key: '',
      value: '',
    },
    cancellationRightsContent: {
      key: '',
      value: '',
    },
    returnsAndExchangesContent: {
      key: '',
      value: '',
    },
    legalSubmitOrderButtonCopy: {
      key: '',
      value: 'By submitting my order, I agree to be bound by the terms and policies linked above.',
    },
  },
  shippingCountry: 'US',
  hasOnlyEGiftCard: false,
};

OrderSubmitLegalTerms.propTypes = {
  shippingCountry: PropTypes.string,
  hasOnlyEGiftCard: PropTypes.bool,
  orderSubmitLegalTermsData: PropTypes.shape({
    displayLegalSaleTerms: PropTypes.bool,
    displayReturnsAndExchanges: PropTypes.bool,
    displayLegalSiteUse: PropTypes.bool,
    displayLegalPrivacyPolicy: PropTypes.bool,
    displayCompanyInfo: PropTypes.bool,
    displayFapio: PropTypes.bool,
    displayLegalTermsCheckBox: PropTypes.bool,
    displayAgeConsentCheckBox: PropTypes.bool,
    displayPrivacyNoticeWithMoreInfo: PropTypes.bool,
    displayLegalOrderImportMessaging: PropTypes.bool,
    isAgeConsentOptionCheckedByDefault: PropTypes.bool,
    countriesConfig: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      hasImportDutyWaiver: PropTypes.bool,
    })),
    salesTermsLinkText: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    returnAndExchangesLinkText: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    siteUseText: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    privacyPolicyText: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    companyInfoText: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    fapiaoText: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    rightToCancelText: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    moreInfoText: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    tickConfirmAndAgreeText: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    legalOrderImportMessageText: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    privacyNoticeText: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    requiredTermsConditionsText: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    cancellationRightsContent: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    returnsAndExchangesContent: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    legalSubmitOrderButtonCopy: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
};

export default OrderSubmitLegalTerms;
