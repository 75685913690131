import { gql } from '@apollo/client';

export const shippingHandlingInfoQuery = gql`
  query FetchShippingHandlingInfo {
    shippingHandlingInfo {
      btnShippingHandling
      txtDeliveryDate
      txtInfoFooter
      txtInfoLeftTab
      txtNoShipping
    }
  }
`;

export default {
  shippingHandlingInfoQuery,
};
