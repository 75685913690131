import React from 'react';
import PropTypes from 'prop-types';
import Tmnt from '../../../Tmnt/Tmnt';
import { useIsL } from '../../../../hooks/useBreakPoint';

export default function LoggedInViewV2({
  iconImageUrl,
  loggedInData,
  tmntData,
  loyaltyInfo,
  totalCoupons,
}) {
  const isLBreakPoint = useIsL();

  const renderCurrencyUntilNextRewardValue = () => (
    <span
      className="icon-block-text currency_until"
      data-testid="loggedInCurrencyUntilNextRewardKey"
      data-aui="cashToNextReward"
      data-debug={!loyaltyInfo?.currencyUntilNextRewardValue?.value
        ? loyaltyInfo?.currencyUntilNextRewardValue?.key : ''}
    >
      <Tmnt tmnt={loyaltyInfo?.currencyUntilNextRewardValue} isHtml />
    </span>
  );

  const goToRewardOfferSection = () => {
    const element = document.getElementById('right-rail-reward-offer');
    const headerOffset = 140; // Height of the sticky header
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <div className="logged-in">
      <div className="icon-block legacy-icon-block " data-icon-size="20">
        <span className="shopping-bag-text">
          <span className="shopping-bag-text__info">
            <h2 className="logged-in-user-heading" data-testid="loggedInHeyUser" data-property={tmntData?.heyUser.key}>
              {loggedInData?.userName}
            </h2>
            <span className="logged-in-pipeline" data-testid="loggedInPipeline">
              |
            </span>
            <span
              className="icon-block-text member_since"
              data-testid="loggedInDateText"
              data-property={tmntData?.dateText?.key}
              data-aui="memberSince"
            >
              {`${loggedInData?.memberSince} ${loggedInData?.registrationDate}`}
            </span>
            {isLBreakPoint ? (
              <button
                className="button returns-button"
                type="button"
                data-variant="button-as-link"
                onClick={() => goToRewardOfferSection()}
              >
                <span>
                  {`${totalCoupons} ${tmntData?.rewardAvailable?.value} `}
                </span>
              </button>
            ) : renderCurrencyUntilNextRewardValue()}
          </span>
        </span>
        {iconImageUrl && (
          <span className="icon-block-icon" data-icon={tmntData?.loyaltyLogoLabel?.value} aria-label={tmntData?.loyaltyLogoLabel?.value}>
            <img className="icon-block-icon-image" src={iconImageUrl} alt={tmntData?.account?.value} />
          </span>
        )}
      </div>
    </div>
  );
}

LoggedInViewV2.defaultProps = {
  tmntData: {},
  loggedInData: {
    userName: '',
    registrationDate: '',
    memberSince: '',
  },
  loyaltyInfo: {
    loyaltyTierInfo: {
      loyaltyTier: '',
      actionNeededForNextTier: '',
      formattedActionNeededForNextTier: '',
      spendByDateForNextTier: '',
      formattedLoyaltyTierExpirationDate: '',
    },
    pointBalance: '',
    nextRewardPointsThreshold: 0,
    formattedNextRewardValue: '',
    accountStatus: '',
    currencyUntilNextRewardValue: {
      key: 'LOYALTY_NEXT_REWARD_CASH',
      value: '',
    },
  },
  iconImageUrl: '',
  totalCoupons: 0,

};

LoggedInViewV2.propTypes = {
  tmntData: PropTypes.instanceOf(Object),
  loggedInData: PropTypes.shape({
    userName: PropTypes.string,
    registrationDate: PropTypes.string,
    memberSince: PropTypes.string,
  }),
  loyaltyInfo: PropTypes.shape({
    loyaltyTierInfo: PropTypes.shape({
      loyaltyTier: PropTypes.string,
      actionNeededForNextTier: PropTypes.string,
      formattedActionNeededForNextTier: PropTypes.string,
      spendByDateForNextTier: PropTypes.string,
      formattedLoyaltyTierExpirationDate: PropTypes.string,
    }),
    pointBalance: PropTypes.string,
    nextRewardPointsThreshold: PropTypes.number,
    formattedNextRewardValue: PropTypes.string,
    accountStatus: PropTypes.string,
    currencyUntilNextRewardValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  iconImageUrl: PropTypes.string,
  totalCoupons: PropTypes.number,
};
