import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useQuery } from '@apollo/client';
import CheckoutPageContext from '../../context/CheckoutPageContext';
import SwitchTestContext from '../../context/SwitchTestContext';
import SelectedStore from '../Common/SelectedStore/SelectedStore';
import $window from '../../tools/window';
import { selectedStoreEdd, selectedStoreTextQuery } from '../../gql/selectedStore.gql';
import PopinsInfoModal from '../Common/PopinsInfoModal/PopinsInfoModal';

export default function CheckoutSelectedStore({
  parentClass,
}) {
  const [mounted, setMounted] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [storeEdd, setStoreEdd] = useState(false);
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const isMfeDeliverySectionEnabled = digitalData && digitalData['ful-chk-mfe-delivery-section'];
  const {
    loading: textLoading,
    error: textError,
    data: textKeys,
  } = useQuery(selectedStoreTextQuery);
  const [getSelectedStoreEdd] = useLazyQuery(selectedStoreEdd, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.selectedStoreEdd) {
        setStoreEdd(data.selectedStoreEdd);
      }
    },
  });

  const {
    selectedStore,
    checkoutPageState,
  } = useContext(CheckoutPageContext);

  useEffect(() => {
    if (selectedStore) {
      getSelectedStoreEdd();
    }
  }, [selectedStore, getSelectedStoreEdd]);

  const selectedShippingSpeed = useMemo(() => checkoutPageState?.shippingSpeed?.find(
    (shippingSpeed) => shippingSpeed.isSelected,
  ), [checkoutPageState.shippingSpeed]);

  const isPopins = useMemo(() => selectedShippingSpeed?.shipModeType === 'Popins', [selectedShippingSpeed]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const onChangeStore = () => {
    if (!selectedShippingSpeed) return;
    const modalType = selectedShippingSpeed.shipModeType === 'Popins' ? 'popins-modal' : 'pudos-modal';
    $window.dispatchEvent(new CustomEvent(`${modalType}:open`, {
      detail: {
        selectedStore,
      },
    }));
  };

  const toggleInfoModal = () => {
    setIsInfoModalOpen((isOpen) => !isOpen);
  };

  const estimaatedPickupDateFmt = useMemo(() => {
    if (!selectedStore) return '';

    if (storeEdd && storeEdd.edd) {
      const date = new Date(storeEdd.edd);
      return date.toLocaleDateString('en-US', {
        weekday: 'short', // "Fri"
        month: '2-digit', // "03"
        day: '2-digit', // "17"
      });
    }

    if (selectedStore.estimatedPickupDateFmt) return selectedStore.estimatedPickupDateFmt;

    const estimateDate = new Date(storeEdd.edd);
    estimateDate.setDate(estimateDate.getDate() + 7);
    return estimateDate.toLocaleDateString('en-US', {
      weekday: 'short', // "Fri"
      month: '2-digit', // "03"
      day: '2-digit', // "17"
    });
  }, [selectedStore, storeEdd]);

  if (!mounted
    || !textKeys
    || textLoading
    || textError
    || !selectedStore) {
    return null;
  }

  if (selectedShippingSpeed?.shipModeType === 'Popins' && !isMfeDeliverySectionEnabled) return null;

  if (selectedShippingSpeed?.shipModeType !== 'Popins' && !isMfeDeliverySectionEnabled) return null;

  return (
    <div className={`checkout-mfe-web-service ${parentClass}`}>
      <SelectedStore
        storeName={selectedStore.name}
        storeBrand={selectedStore.storeBrand}
        address={selectedStore.address}
        providerType={selectedStore.providerType}
        tmntData={{
          yourPickupLocationTmnt: isPopins
            ? textKeys?.textFor?.pickUpMyOrder
            : textKeys?.textFor?.checkYourPickUpText,
          estimatedPickupTmnt: textKeys?.textFor?.estimatedPickupText,
          storeHoursTmnt: textKeys?.textFor?.storeHoursText,
          today: textKeys?.textFor?.today,
          monday: textKeys?.textFor?.monday,
          tuesday: textKeys?.textFor?.tuesday,
          wednesday: textKeys?.textFor?.wednesday,
          thursday: textKeys?.textFor?.thursday,
          friday: textKeys?.textFor?.friday,
          saturday: textKeys?.textFor?.saturday,
          sunday: textKeys?.textFor?.sunday,
          pickUpOrderInfoTmnt: textKeys?.textFor?.pickUpOrderInfoTmnt,
        }}
        displayOperatingHours
        storeOperatingHours={selectedStore.operatingHours}
        estimatedPickupDate={estimaatedPickupDateFmt}
        mapAndHoursUrl={selectedStore.mapAndHoursUrl}
        staticMapImageUrl={selectedStore.staticMapUrl}
        isCheckoutPage
        onChangeStore={onChangeStore}
        allowChangeStore
        isStore={isPopins}
        onClickInfo={toggleInfoModal}
      />
      <PopinsInfoModal
        isOpen={isInfoModalOpen}
        onClose={toggleInfoModal}
        title={textKeys?.textFor?.buyOnlinePickUpInStore?.value}
        espotData={checkoutPageState?.eSpots?.checkoutPopins}
      />
    </div>
  );
}

CheckoutSelectedStore.defaultProps = {
  parentClass: 'pickup-mfe',
};

CheckoutSelectedStore.propTypes = {
  parentClass: PropTypes.string,
};
