import React, {
  useState, useMemo, useContext, useEffect,
} from 'react';
import { useQuery } from '@apollo/client';
import { useCookies } from 'react-cookie';
import ORDERCONFIRMATIONPAGE_DATA_QUERY from '../../gql/orderConfirmationPage.gql';
import useLog from '../useLog/useLog';
import OrderConfirmationContext from '../../context/OrderConfirmationContext';
import Survey from './Survey/Survey';
import SmsOptIn from './SmsOptIn/SmsOptIn';
import OrderInfo from './OrderInfo/OrderInfo';
import Footer from './Footer/Footer';
import RightRail from './RightRail/RightRail';
import {
  ERROR_MESSAGE as errorMessage,
} from '../Common/Messages/Messages';
import trackAction from '../../tools/analytics';
import $window from '../../tools/window';
import MarketingSubscription from './MarketingSubscription/MarketingSubscription';
import OrderReview from './OrderReview/OrderReview';
import PointsReward from './PointsReward/PointsReward';
import OrderConfirmationRecommendations from '../Recommendations/OrderConfirmationRecommendations';
import Loader from '../Common/Loader/Loader';
import SwitchTestContext from '../../context/SwitchTestContext';
import { setHashedEmailForAnalytics } from '../../tools/utils';

export default function OrderConfirmationPage() {
  const { digitalData } = useContext(SwitchTestContext);
  const isOrderConfirmationRecommendationsEnabled = digitalData && digitalData['has-order-confirmation-recommendations-enabled'];
  const [orderConfirmationData, setOrderConfirmationData] = useState(null);
  const logger = useLog('orderConfirmationPage.root');
  const [cookies] = useCookies();
  const ocnProviderValues = useMemo(() => ({
    orderConfirmationData,
  }), [orderConfirmationData]);
  const { loading, error, data: queryData } = useQuery(ORDERCONFIRMATIONPAGE_DATA_QUERY, {
    context: { batch: true },
    fetchPolicy: 'no-cache',
    ssr: false,
    onCompleted: (data) => {
      const resOCN = { ...data };
      setOrderConfirmationData(resOCN);
      $window.digitalData?.merge('cart', {
        origin: 'orderConfirmation',
        data,
        action: 'load',
      });
      // Triggering MiniBag update item count to 0
      const updateItemCount = new CustomEvent('miniBag:updateItemCount:done', {
        detail: {
          items: [], // Sending manual empty array to match the format for listening event.
        },
      });
      $window.dispatchEvent(updateItemCount);
      const event = new CustomEvent('orderConfirmationPage:orderConfirmationData', { detail: data });
      $window.dispatchEvent(event);
    },
    onError: (err) => {
      logger.error(`ERR: ORDERCONFIRMATIONPAGE_DATA_QUERY: ${JSON.stringify(err)}`);
    },
  });

  useEffect(() => {
    const handleOrderConfirmationData = async (event) => {
      const data = event.detail;
      const email = data?.orderContact?.email;
      await setHashedEmailForAnalytics(email);
      setTimeout(() => {
        trackAction('order_confirmation_loaded', {
          ...data?.orderTotals,
          grandTotalBeforeDiscount: data?.orderTotals?.grandTotal - data?.orderTotals?.promoAmount,
          totalItems: parseInt(data?.orderTotals?.totalItemsInBag, 10),
          couponCode: data?.promoInfo?.map((promoItem) => promoItem?.promotionCode).join(',') || '',
          sms_flag: data?.smsOptIn?.isSelected,
          promotion_flag_loyalty: data?.promoInfo?.some(
            (promoItem) => promoItem?.isRewardPromo,
          ),
          user_marketing_flag: !!Object.values(cookies?.subscribe || {})?.find((marketingEmails) => marketingEmails === 'Y'),
        });
      }, 100);
    };

    // Add the event listener for 'orderConfirmationPage:orderConfirmationData'
    $window.addEventListener('orderConfirmationPage:orderConfirmationData', handleOrderConfirmationData);

    // Clean up the event listener when the component unmounts
    return () => {
      $window.removeEventListener('orderConfirmationPage:orderConfirmationData', handleOrderConfirmationData);
    };
  }, [queryData, cookies?.subscribe]);

  const loadCompleteMFEOrderConfirmationPage = queryData
    ?.switches?.loadCompleteMFEOrderConfirmationPage || false;
  if (loading) {
    return (loadCompleteMFEOrderConfirmationPage
      ? (
        <section className="loader-area">
          <Loader variant="shimmer" contentRows={2} id="ocn-loader" />
        </section>
      )
      : <Loader variant="dots" id="ocn-loader" />);
  }
  if (error) return errorMessage;
  if (!queryData) return null;

  const isGuestUser = queryData?.userData?.isLoggedIn === false;

  const fullOrderConfirmationPage = () => (
    <div className="order-confirm-wrapper" data-testid="order-confirmation-page-wrapper-full">
      <div className="ocn-left-rail">
        <div className="core-container">
          {isGuestUser && <MarketingSubscription />}
          <OrderInfo />
          {isOrderConfirmationRecommendationsEnabled
        && <OrderConfirmationRecommendations orderData={orderConfirmationData} />}
          <SmsOptIn />
          <PointsReward />
          <OrderReview />
          <Survey />
          <Footer />
        </div>
      </div>
      <div className="ocn-right-rail" data-testid="right-rail">
        <div className="right-rail-wrapper">
          <RightRail />
        </div>
      </div>
    </div>
  );

  const partialOrderConfirmationPage = () => (
    <section className="order-confirm-wrapper-partial" data-testid="order-confirmation-page-wrapper-partial">
      {isGuestUser && <MarketingSubscription />}
      {isOrderConfirmationRecommendationsEnabled
      && <OrderConfirmationRecommendations orderData={orderConfirmationData} />}
      <SmsOptIn />
      <Survey />
    </section>
  );

  return (
    orderConfirmationData && (
      <OrderConfirmationContext.Provider value={ocnProviderValues}>
        {loadCompleteMFEOrderConfirmationPage
          ? fullOrderConfirmationPage() : partialOrderConfirmationPage()}
      </OrderConfirmationContext.Provider>
    ));
}
