import React, { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'anf-core-react';
import MarketingSpot from '../../Common/MarketingSpot/MarketingSpot';
import BagContext from '../../../context/BagContext';
import VatModal from '../../Common/VatModal/VatModal';
import SwitchTestContext from '../../../context/SwitchTestContext';
import trackAction from '../../../tools/analytics';

export default function NeedHelp({
  tmntData,
  shippingModelRef,
}) {
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const hasBagMfeV2 = digitalData && digitalData['chk-has-bag-mfe-v2'];
  const [openModal, setOpenModal] = useState(false);
  const shippingHandlingTextTMNTValue = tmntData?.shippingHandlingTextTMNTValue?.value;
  const shippingAriaLabelTMNTValue = tmntData?.shippingAriaLabelTMNTValue?.value;
  const { bag } = useContext(BagContext);
  const { vatDetails } = bag;
  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleShippingAndHandlingClick = () => {
    trackAction('universal_click', {
      data_text: 'shipping & handling',
      data_action: 'open',
      event_type: 'click',
    });
  };

  const needHelp = () => (
    <div className="links" data-testid="need-help">
      <ul>
        {vatDetails?.showVatDetails && (
          <li className="vat-details">
            <Button
              type="button"
              variant="borderless"
              data-property="GLB_SUBTOTALINCLVAT"
              onClick={handleOpenModal}
              classList="vat-details-button"
            >
              {tmntData?.subTotalIncludesVatTMNTValue?.value}
            </Button>
            <VatModal openModal={openModal} closeModal={handleCloseModal} vatDetails={vatDetails} />
          </li>
        )}
        <li className="ship-and-hand">
          {!hasBagMfeV2
          && (
          <MarketingSpot
            espotId="bag_freeshipping_belowsubtotal"
            espotData={bag?.eSpots?.cartFreeShippingPromo}
          />
          )}
          <p>
            <Button
              type="button"
              variant="borderless"
              classList={shippingModelRef}
              onClick={handleShippingAndHandlingClick}
            >
              <span aria-label={shippingAriaLabelTMNTValue} />
              <span data-state="initial" data-icon="shipping" data-hidden="false" aria-hidden="true" />
              <span data-state="initial" data-hidden="false" data-property="GLB_SHIPPINGANDHANDLING" data-testid="shippingHandling-text">
                {shippingHandlingTextTMNTValue}
              </span>
            </Button>
          </p>
        </li>
      </ul>
    </div>
  );

  return (
    <div className="needHelp-content" data-testid="needHelp-info">
      {needHelp()}
    </div>
  );
}

NeedHelp.defaultProps = {
  shippingModelRef: 'button ds-override button-as-link js-shipping-and-handling-modal-open',
  tmntData: {},
};

NeedHelp.propTypes = {
  shippingModelRef: PropTypes.string,
  tmntData: PropTypes.instanceOf(Object),
};
