import {
  gql,
} from '@apollo/client';

export const GET_MINIBAG_ICON_DATA = gql`
query FetchMiniBagIconData {
  miniBagIcon {
    itemsInBag
    source
  }
}
`;

export const GET_MINIBAG_ESSENTIALS = gql`
query FetchMiniBagEssentials {
  storeId
  switches {
    hasMFEMobileNavToaster: isOn ( switchKey: "HasMFEMobileNavToaster")
    hasMfeMySavesEnabled: isOn ( switchKey: "HasMfeMySavesEnabled")
    hasUnifiedCatalogHeaderEnabled: isOn ( switchKey: "HasUnifiedCatalogHeaderEnabled")
    hasMFELargeScreenFlyoutNav: isOn ( switchKey: "HasMFELargeScreenFlyoutNav")
    hasCatalogMfeFooter: isOn ( switchKey: "HasCatalogMfeFooter")
  }
  viewBagButton {
    bagUrl
  }
  textFor {
    shoppingBag: pair (pairKey: "shoppingBag") {...values}
    itemsInBagTMNT: pair (pairKey: "itemsInBagTMNT") {...values}
  }
}
fragment values on TextPair {
  key
  value
}
`;
