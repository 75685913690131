import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import PopinsModalContainer from '../PopinsModal/PopinsModalContainer';
import PickupSearch from '../PickupSearch/PickupSearch';

/**
 * PopinsSection (Pick-Up Point in Store) component manages the store pickup search and modal.
 * It provides functionality for searching pick-up locations through text input or geolocation.
 *
 * @component
 * @returns {React.ReactElement} The rendered component with search interface and modal
 */
function PopinsSection({ tmntData }) {
  const [isInputSearching, setIsInputSearching] = useState(false);
  const [isNearMeSearching, setIsNearMeSearching] = useState(false);
  const [isGeolocationBlocked] = useState(false);

  const handleInputSearch = useCallback((value) => {
    setIsInputSearching(true);
    window.dispatchEvent(new CustomEvent('popins-modal:open', {
      detail: { value, comingFromInputSearch: true },
    }));
    setIsInputSearching(false);
  }, []);

  const handleNearMeSearch = useCallback(() => {
    setIsNearMeSearching(true);
    window.dispatchEvent(new CustomEvent('popins-modal:open', {
      detail: { nearme: true },
    }));
    setIsNearMeSearching(false);
  }, []);

  return (
    <div data-testid="popins-modal">
      <PickupSearch
        defaultSearchValue={undefined}
        isInputSearching={isInputSearching}
        isNearMeSearching={isNearMeSearching}
        isGeolocationBlocked={isGeolocationBlocked}
        onInputSearch={handleInputSearch}
        onNearMeSearch={handleNearMeSearch}
        text={tmntData}
      />
      <PopinsModalContainer />
    </div>
  );
}

PopinsSection.defaultProps = {
  tmntData: {},
};

PopinsSection.propTypes = {
  tmntData: PropTypes.instanceOf(Object),
};
export default PopinsSection;
