/* eslint-disable */
import { BigInteger, parseBigInt } from "./jsbn";
import { SecureRandom } from "./rng";

/**
 * Depends on jsbn.js and rng.js
 * Version 1.1: support utf-8 encoding in pkcs1pad2
 */

// PKCS#1 (type 2, random) pad input string s to n bytes, and return a bigint
// pkcs1pad2(s:string, n:number)
function pkcs1pad2(s, n) {
  if (n < s.length + 11) { // TODO: fix for utf-8
    return null;
  }

  const ba = [];
  let i = s.length - 1;

  while (i >= 0 && n > 0) {
    const c = s.charCodeAt(i--);
    if (c < 128) { // encode using utf-8
      ba[--n] = c;
    } else if ((c > 127) && (c < 2048)) {
      ba[--n] = (c & 63) | 128;
      ba[--n] = (c >> 6) | 192;
    } else {
      ba[--n] = (c & 63) | 128;
      ba[--n] = ((c >> 6) & 63) | 128;
      ba[--n] = (c >> 12) | 224;
    }
  }
  ba[--n] = 0;
  const rng = new SecureRandom();
  const x = [];
  while (n > 2) { // random non-zero pad
    x[0] = 0;
    while (x[0] == 0) {
      rng.nextBytes(x);
    }
    ba[--n] = x[0];
  }
  ba[--n] = 2;
  ba[--n] = 0;
  return new BigInteger(ba);
}

// RSA key constructor
export class RSAKey {
  constructor() {
    this.n = null;
    this.e = 0;
    this.d = null;
    this.p = null;
    this.q = null;
    this.dmp1 = null;
    this.dmq1 = null;
    this.coeff = null;
  }

  // Perform raw public operation on "x": return x^e (mod n)
  // doPublic(x:BigInteger)
  doPublic(x) {
    return x.modPowInt(this.e, this.n);
  }

  // Perform raw private operation on "x": return x^d (mod n)
  // doPrivate(x:BigInteger)
  doPrivate(x) {
    if (this.p == null || this.q == null) {
      return x.modPow(this.d, this.n);
    }

    // TODO: re-calculate any missing CRT params
    let xp = x.mod(this.p).modPow(this.dmp1, this.p);
    const xq = x.mod(this.q).modPow(this.dmq1, this.q);

    while (xp.compareTo(xq) < 0) {
      xp = xp.add(this.p);
    }
    return xp.subtract(xq).multiply(this.coeff).mod(this.p).multiply(this.q).add(xq);
  }

  // Set the public key fields N and e from hex strings
  // setPublic(N:string, E:string)
  setPublic(N, E) {
    if (N != null && E != null && N.length > 0 && E.length > 0) {
      this.n = parseBigInt(N, 16);
      this.e = parseInt(E, 16);
    }
  }

  // Return the PKCS#1 RSA encryption of "text" as an even-length hex string
  // encrypt(text: string)
  encrypt(text) {
    const maxLength = (this.n.bitLength() + 7) >> 3;
    const m = pkcs1pad2(text, maxLength);

    if (m == null) {
      return null;
    }
    const c = this.doPublic(m);
    if (c == null) {
      return null;
    }

    let h = c.toString(16);
    let length = h.length;

    // fix zero before result
    for (let i = 0; i < maxLength * 2 - length; i++) {
      h = "0" + h;
    }

    return h
  }
}
