function formatStateDistrictName(str) {
  if (!str) {
    return ''; // Return an empty string if the input is invalid
  }
  return str
    .replace(/^[A-Z]_/, '') // Removing the leading letter and underscore (e.g., 'S_' or 'D_')
    .toLowerCase() // Converting to lowercase
    .replace(/_/g, ' ') // Replacing underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalizing each word
}

module.exports = formatStateDistrictName;
