import {
  gql,
} from '@apollo/client';

const GIFT_RECEIPT_UPDATE_MUTATION = gql`
  mutation UpdateGiftReceipt {
    updateGiftReceipt {
      orderTotals {
        totalItemsInBag
        cartTotalAmount
        cartTotalAmountFmt
        cartTotalAmountUSD
        subTotalFmt
        promoAmount
        promoAmountFmt
        subTotal
        orderId
        shippingHandlingChargeFmt
        totalGiftBoxes
        totalGiftBoxesAmount
        isGiftReceiptChecked
        displayFreeShippingText
        displayGiftReceiptText
        estimatedTaxAmount
        estimatedTaxAmountFmt
        estimatedTaxAmountUSD
        charityRoundUpFmt
        includesConsumptionTax
        includesGst
        includesVat
        grandTotal
        grandTotalFmt
        currency
        charityTotalFmt
        subTotalUSD
        grandTotalUSD
        cartTotalAmountUSD
        totalAdjustmentUSD
        shippingCharge
        shippingChargeUSD
        shippingChargeFmt
      }
      success
    }
  }
`;

export const GIFT_RECEIPT_DELETE_MUTATION = gql`
mutation DeleteGiftReceipt {
  deleteGiftReceipt {
    orderTotals {
      totalItemsInBag
      cartTotalAmount
      cartTotalAmountFmt
      cartTotalAmountUSD
      subTotalFmt
      promoAmount
      promoAmountFmt
      subTotal
      orderId
      shippingHandlingChargeFmt
      totalGiftBoxes
      totalGiftBoxesAmount
      isGiftReceiptChecked
      displayFreeShippingText
      displayGiftReceiptText
      estimatedTaxAmount
      estimatedTaxAmountFmt
      estimatedTaxAmountUSD
      charityRoundUpFmt
      includesConsumptionTax
      includesGst
      includesVat
      grandTotal
      grandTotalFmt
      currency
      charityTotalFmt
      subTotalUSD
      grandTotalUSD
      cartTotalAmountUSD
      totalAdjustmentUSD
      shippingCharge
      shippingChargeUSD
      shippingChargeFmt
    }
    success
  }
}
`;

export default GIFT_RECEIPT_UPDATE_MUTATION;
