import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'anf-core-react';
import MarketingSpot from '../MarketingSpot/MarketingSpot';

export default function PopinsInfoModal(props) {
  const {
    isOpen,
    title,
    onClose,
    espotData,
  } = props;

  return (
    <Dialog
      id="popins-info-modal"
      isOpen={isOpen}
      heading={(
        <h2 className="h2" data-testid="popins-modal-header" data-property="GLB_BUYONLINE_PICKUPINSTORE">
          {title}
        </h2>
      )}
      onClose={onClose}
    >
      {espotData && (
      <MarketingSpot
        espotId="checkout_popins"
        espotData={espotData}
      />
      )}
    </Dialog>
  );
}

PopinsInfoModal.defaultProps = {
  isOpen: false,
  title: '',
  onClose: () => { },
  espotData: null,
};

PopinsInfoModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  espotData: PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string,
  }),
};
