import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
} from 'anf-core-react';

const defaultProps = {
  txtInfoNoShipping: undefined,
  txtInfoFooter: undefined,
};

const propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  txtInfoLeftTab: PropTypes.string.isRequired,
  txtInfoFooter: PropTypes.string,
  txtHeading: PropTypes.string.isRequired,
  txtInfoNoShipping: PropTypes.string,
};

export default function ShippingHandlingInfoModal(props) {
  const {
    onClose,
    isOpen,
    txtInfoLeftTab,
    txtHeading,
    txtInfoFooter,
    txtInfoNoShipping,
  } = props;

  return (
    <Modal
      id="shipping-handling-modal"
      isOpen={isOpen}
      heading={(
        <h2 className="h2" data-testid="shipping-handling-modal-header">
          {txtHeading}
        </h2>
      )}
      onClose={onClose}
    >
      <div className="shipping-and-handling-modal-wrapper">
        <div
          className="shippingAndHandling-body"
        >
          <div
            className="shippingAndHandling__rate-table-tab"
            dangerouslySetInnerHTML={{ __html: txtInfoLeftTab }}
          />
        </div>
        <div className="shippingAndHandling-footer">
          <div
            className="module-section__footer-content"
            dangerouslySetInnerHTML={{ __html: txtInfoFooter }}
          />
          {txtInfoNoShipping && (
            <div
              className="noShippingUSContent"
              data-testid="shipping-handling-modal-noShippingUSContent"
              dangerouslySetInnerHTML={{ __html: txtInfoNoShipping }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
}
ShippingHandlingInfoModal.propTypes = propTypes;
ShippingHandlingInfoModal.defaultProps = defaultProps;
